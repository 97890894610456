import { Injectable, NgZone } from '@angular/core';
import { appConfig } from '../app.config';
import { StorePrintersService } from '../database/store-printers.service';
import { OrdersService } from '../database/orders.service';
import { OrderDetailService } from '../database/order-detail.service';
import { DataServicesService } from './data-services.service';
import { DecodeHtmlEntities } from 'decode-html-entities';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { registerPlugin } from '@capacitor/core';
import { encode, decode } from 'js-base64';
import { Invoice } from '@axenda/zatca';
import * as process from 'process';
(window as any).process = process;
(window as any)['global'] = window;
global.Buffer = global.Buffer || require('buffer').Buffer;
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Network } from '@capacitor/network';
import { InternetCheckService } from './internet-check.service';


export interface EscPosPlugin {
  escpos(options: { templateType: string, printType: string, dataJsonObject: object, titlesJsonObject: object, printerSettingsJsonObject: object }): Promise<{ templateType: string, printType: string, dataJsonObject: object, titlesJsonObject: object, printerSettingsJsonObject: object }>;
}
export const EscPos = registerPlugin<EscPosPlugin>('EscPos');
// export default EscPos;


export interface SunmiPlugin {
  sunmi(options: { templateType: string, printType: string, dataJsonObject: object, titlesJsonObject: object, printerSettingsJsonObject: object }): Promise<{ templateType: string, printType: string, dataJsonObject: object, titlesJsonObject: object, printerSettingsJsonObject: object }>;
}

// const Sunmi = registerPlugin<SunmiPlugin>('Sunmi');
export const Sunmi = registerPlugin<SunmiPlugin>('Sunmi');

export interface EpsonPlugin {
  epson(options: { templateType: string, printType: string, dataJsonObject: object, titlesJsonObject: object, printerSettingsJsonObject: object }): Promise<{ templateType: string, printType: string, dataJsonObject: object, titlesJsonObject: object, printerSettingsJsonObject: object }>;
}
export const Epson = registerPlugin<EpsonPlugin>('Epson');

// const Epson = registerPlugin<EpsonPlugin>('Epson');



@Injectable({
  providedIn: 'root'
})
export class PrintService {

  orderData: any = {};
  tax_excluded_grand_total: any = 0;
  taxList: any = []
  store_detail: any = {};
  store_id: any;
  storePosBehavior: any;
  terminal_id: any;
  deliveryPartnerOrderDetails: any = {}
  private queue: (() => Promise<any>)[] = [];
  private isProcessing: boolean = false;
  receiptNativeObject: any = {};
  drawerNativeObject: any = {};
  kotNativeObject: any = {};
  receiptHtml: any;
  safeHTML: SafeHtml;
  kotreceiptNativeObject: any = {};
  internetStatus: any = 'Online';

  constructor(
    public storePrintersServiceDb: StorePrintersService,
    public ordersServiceDb: OrdersService,
    // public orderDetailServiceDb: OrderDetailService,
    public _dataService: DataServicesService,
    public decodeHtmlEntities: DecodeHtmlEntities,
    private translate: TranslateService,
    private _datePipe: DatePipe,
    private _decimalPipe: DecimalPipe,
    private toastr: ToastrService,
    public http: HttpClient,
    // private sanitizer: DomSanitizer,
    private zone: NgZone,
    public internetCheckService: InternetCheckService
  ) {
    this.internetCheckService.getInternetStatus().subscribe((isConnected) => {
      this.zone.run(() => {
        this.internetStatus = isConnected ? 'Online' : 'Offline';
      });
    });
  }

  async enqueue(fn: () => Promise<any>) {
    this.queue.push(fn);
    if (!this.isProcessing) {
      await this.processQueue();
    }
  }

  private async processQueue() {
    this.isProcessing = true;
    while (this.queue.length > 0) {
      const fn = this.queue[0];
      try {
        const result = await fn();
      } catch (error) {
        console.error(error); // Handle the error as needed
      }
      this.queue.shift();
    }
    this.isProcessing = false;
  }

  async goToPrint(order, needToPrintKot = 0, order_details_with_print_kot = [], useGroupId = 0, fromPage = 0) {
    console.log('needToPrintKot---', needToPrintKot);
    //needToPrintKot : 0 = only recipt , 1 = both recipt & kot , 2 = only kot
    try {

      this.orderData = order
      const storeData = this.storeBrand(this.orderData.order_detail)
      await this.orderData.order_detail.map(async (x) => {
        x.selectedAddOns = x.order_detail_add_add_ons
        const getCalPrice = await this._dataService.getCalPriceFunction(x)
        x.cal_price = getCalPrice.cal_price
        x.addOnprice = getCalPrice.addOnprice
        return x;
      })

      // if (this.orderData.delivery_partner_id == 7 || this.orderData.delivery_partner_id == 8) {
      //   let delivery_partner_order_details = JSON.parse(this.orderData.delivery_partner_order_details)
      //   this.deliveryPartnerOrderDetails = delivery_partner_order_details
      // }
      this.storePosBehavior = JSON.parse(localStorage.getItem('storePosBehavior'))
      this.store_detail = JSON.parse(localStorage.getItem('store_detail'));
      this.store_id = localStorage.getItem('store_id');
      this.terminal_id = localStorage.getItem('terminal_id');

      this.tax_excluded_grand_total = this.orderData.grand_total;
      let list = [];
      this.taxList = [];
      if (this.orderData.order_detail.length > 0) {
        await this.orderData.order_detail.forEach(order_detail => {
          let taxes = order_detail.order_detail_tax;
          if (taxes) {
            taxes.forEach((value, key) => {
              if (value?.id) {
                this.taxList.push(value);
              }
            });
          }
        });
      }
      if (this.taxList?.length > 0) {
        const grouped = this.groupBy(this.taxList, tax => tax.name);
        grouped.forEach((value_raw, key) => {
          let cal = 0;
          let name;
          let tax_value;
          value_raw.forEach((value, key) => {
            let tax_list = value;
            name = tax_list.name;
            cal += Number(tax_list.tax_amount);
            if (tax_list.tax_type == "1") {
              tax_value = tax_list.tax_value + " %"
            } else {
              tax_value = tax_list.tax_value + " ₹"
            }
          })
          let mycal = {
            name: name,
            amt: cal.toFixed(2),
            tax_value: tax_value
          }
          list.push(mycal)
        });
      }
      this.taxList = list;

      if (this.taxList.length > 0) {
        this.taxList.forEach(element => {
          this.tax_excluded_grand_total = this.tax_excluded_grand_total - element.amt;
        });
        this.tax_excluded_grand_total = this.tax_excluded_grand_total.toFixed(2)
      }
      // console.log('needToPrintKot', needToPrintKot);
      if (needToPrintKot == 2) {
        // if only KOT
        // console.log('if only KOT', this.orderData,);
        await this.kotPrint(this.orderData, order_details_with_print_kot, useGroupId, fromPage);
      } else {
        // if only recipt  OR Both
        // console.log('needToPrintKot---111-->', needToPrintKot);
        if (needToPrintKot == 1 && appConfig.buildCreateFor == 'web') {
          // this code is for some store of santusti which need KOT print before recipt print in print popup
          let reciptPrinterData = await this.storePrintersServiceDb.use('GA_DATA', { filter: { "type": "1", "status": 1 }, skip: 0, limit: 1 })
          if (!reciptPrinterData || reciptPrinterData.length == 0) {
            needToPrintKot = 0
            await this.kotPrint(this.orderData, [], useGroupId);
            await this.delay(2000);
          }
        }
        await this.enqueue(async () => {
          let is_print_done = 0;
          let customPrinterData = await this.storePrintersServiceDb.use('GA_DATA', { filter: { "type": "1", "status": 1 }, skip: 0, limit: 1 })
          if (customPrinterData && customPrinterData.length > 0) {

            if (appConfig.buildCreateFor != 'web') {
              is_print_done = 1;
              let customPrinterRowData = customPrinterData[0];
              let port = customPrinterRowData.port;
              let IP = customPrinterRowData.ip_address;
              let Port = Number(port);
              let printerType = customPrinterRowData.printer_type;

              if (customPrinterRowData.printer_type == 'Other Printer' || customPrinterRowData.printer_type == 0) {
                customPrinterRowData.printer_type = 0
              } else if (customPrinterRowData.printer_type == 'Sunmi Printer' || customPrinterRowData.printer_type == 1) {
                customPrinterRowData.printer_type = 1
              } else {
                customPrinterRowData.printer_type = 2
              }
              //forNativeReceipt

              this.receiptNativeObject.receipt_template = "0"
              let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
              if (permissions?.length > 0) {
                let check = permissions.find(x => x.key_ == 'receipt_template_layout');
                if (check) {
                  if (check.status == 1 && check.value_ == '1') {
                    this.receiptNativeObject.receipt_template = "1";
                  } else if (check.status == 1 && check.value_ == '2') {
                    this.receiptNativeObject.receipt_template = "2";
                  }
                }
              }

              // 1 - Default template
              // 2 - Featured template

              let templatePrinterType = "1"
              if (permissions?.length > 0) {
                let check = permissions.find(x => x.key_ == 'receipt_template_layout_format');
                if (check) {
                  if (check.status == 1 && check.value_ == '2') {
                    templatePrinterType = "2"
                  }
                }
              }

              this.receiptNativeObject.invoice_store_logo_permission = this.checkStoreTemplatePermission('invoice_store_logo');
              this.receiptNativeObject.invoice_store_logo_base_64 = storeData.store_logo;
              this.receiptNativeObject.invoice_store_name_permission = this.checkStoreTemplatePermission('invoice_store_name');
              this.receiptNativeObject.invoice_store_name = storeData.store_name;
              this.receiptNativeObject.invoice_store_name_locale = storeData.store_name;
              this.receiptNativeObject.invoice_store_address_permission = this.checkStoreTemplatePermission('invoice_store_address');
              this.receiptNativeObject.invoice_store_address = storeData.store_address;
              this.receiptNativeObject.invoice_store_address_locale = storeData.store_address;

              this.receiptNativeObject.invoice_additional_info_header_note_permission = (this.checkStoreTemplatePermissionGetValue('invoice_additional_info_header_note').value_ != '') ? true : false;
              this.receiptNativeObject.invoice_additional_info_header_note = this.stripHtml(this.htmlconverter(this.checkStoreTemplatePermissionGetValue('invoice_additional_info_header_note')));
              this.receiptNativeObject.invoice_additional_info_header_note_locale = this.stripHtml(this.htmlconverter(this.checkStoreTemplatePermissionGetValue('invoice_additional_info_header_note')));
              this.receiptNativeObject.local_transaction_no = this.orderData.local_transaction_no;
              this.receiptNativeObject.store_order_sequence_id = this.orderData.store_order_sequence_id;
              if (this.orderData?.delivery_partner_order_id == '' || this.orderData?.delivery_partner_order_id == null) {
                if (this.orderData?.store_order_id != "" && this.orderData?.store_order_id != null && this.orderData?.store_order_id != "null" && this.orderData?.store_order_id != undefined && this.orderData?.store_order_id != "undefined") {
                  this.receiptNativeObject.live_ref_number = this.orderData.store_order_id;
                }
              } else {
                this.receiptNativeObject.live_ref_number = this.orderData?.external_order_id
              }
              this.receiptNativeObject.store_id = this.store_id;
              this.receiptNativeObject.terminal_id = this.terminal_id;
              this.receiptNativeObject.clerk_id = this.orderData.created_by;
              this.receiptNativeObject.dt = this.orderData.created_at_formated
              this.receiptNativeObject.invoice_customer_name_permission = this.checkStoreTemplatePermission('invoice_customer_name');
              if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
                this.receiptNativeObject.invoice_customer_name = "N/A";
              } else {
                this.receiptNativeObject.invoice_customer_name = this.orderData.customer_name;
              }
              this.receiptNativeObject.invoice_customer_phone_permission = this.checkStoreTemplatePermission('invoice_customer_phone');
              if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
                this.receiptNativeObject.invoice_customer_phone = "N/A";
              } else {
                this.receiptNativeObject.invoice_customer_phone = this.orderData.customer_phone;
              }
              if (this.orderData.licence_plate != null && this.orderData.licence_plate != 'null' && this.orderData.licence_plate != ' ' && this.orderData.licence_plate != '') {
                this.receiptNativeObject.licence_plate = this.orderData?.licence_plate;
              } else {
                delete this.receiptNativeObject.licence_plate;
              }
              this.receiptNativeObject.tender_profile_name = this.orderData.tender_profile_name;
              this.receiptNativeObject.order_type = this.orderData.order_type;
              let tender_type_name_list = []
              if (this.orderData?.order_type.length > 0) {
                this.orderData?.order_type.forEach(async type => {
                  if (parseInt(type.order_price) > 0) {
                    tender_type_name_list.push(type.order_type_name + " (" + type.order_price + ")");
                  }
                });
              }
              this.receiptNativeObject.payment = tender_type_name_list.toString();
              this.receiptNativeObject.delivery_type = "";
              if (this.orderData.delivery_partner_id == 7 || this.orderData.delivery_partner_id == 8) {
                if (this.orderData.delivery_type == 0) {
                  this.receiptNativeObject.delivery_type = 'Eat-in';
                } else if (this.orderData.delivery_type == 1) {
                  this.receiptNativeObject.delivery_type = 'Pickup';
                } else if (this.orderData.delivery_type == 2) {
                  this.receiptNativeObject.delivery_type = 'Delivery';
                }
              }

              if (this.orderData?.table_id != 0) {
                this.receiptNativeObject.table = this.orderData.table_name;
                this.receiptNativeObject.table_name = this.orderData.table_name;
              } else {
                delete this.receiptNativeObject.table;
                delete this.receiptNativeObject.table_name;
              }
              this.receiptNativeObject.customer_address = ''
              if (this.orderData.customer_address_display && this.orderData.delivery_type == 2) {
                this.receiptNativeObject.customer_address = this.orderData.customer_address_display;
              } else {
                delete this.receiptNativeObject.customer_address;
              }
              this.receiptNativeObject.staff_note = '';
              if (this.orderData.staff_note != undefined && this.orderData.staff_note != 'null' && this.orderData.staff_note != '0.00' && this.orderData.staff_note != ' ' && this.orderData.staff_note != '') {
                this.receiptNativeObject.staff_note = this.orderData.staff_note
                this.receiptNativeObject.staff_note_locale = this.orderData.staff_note
              } else {
                delete this.receiptNativeObject.staff_note;
                delete this.receiptNativeObject.staff_note_locale;
              }
              this.receiptNativeObject.order_details = this.orderData.order_detail;
              this.receiptNativeObject.sub_total = this._decimalPipe.transform(this.orderData.total_price, '1.2-2');
              this.receiptNativeObject.round_off = this._decimalPipe.transform(this.orderData.round_off, '1.2-2');
              this.receiptNativeObject.delivery_partner_order_id = this.orderData.delivery_partner_order_id;
              this.receiptNativeObject.taxList = [];
              this.receiptNativeObject.applicable_tax = '';
              this.receiptNativeObject.tax_excluded_grand_total = '';
              this.receiptNativeObject.tax_include = this.store_detail.tax_include;
              if (this.orderData.coupon_id != 0) {
                this.receiptNativeObject.coupon = this.getNameFromCoupon(this.orderData?.coupon);
                this.receiptNativeObject.coupon_discount = this._decimalPipe.transform(this.orderData?.coupon_discount, '1.2-2')
              } else {
                delete this.receiptNativeObject.coupon;
                delete this.receiptNativeObject.coupon_discount;
              }
              if (this.orderData.delivery_charges > 0) {
                this.receiptNativeObject.delivery_charges = this._decimalPipe.transform(this.orderData.delivery_charges, '1.2-2');
                this.receiptNativeObject.total_amount = this._decimalPipe.transform(this.orderData?.grand_total + this.orderData?.delivery_charge, '1.2-2')
                this.receiptNativeObject.gross_total = this._decimalPipe.transform(this.orderData?.grand_total + this.orderData?.delivery_charge, '1.2-2')
              } else {
                this.receiptNativeObject.total_amount = this._decimalPipe.transform(this.orderData?.grand_total, '1.2-2')
                this.receiptNativeObject.gross_total = this._decimalPipe.transform(this.orderData?.grand_total, '1.2-2')
              }
              if (this.taxList.length > 0) {
                if (this.orderData?.delivery_partner_order_id == '' || this.orderData?.delivery_partner_order_id == null) {
                  this.receiptNativeObject.taxList = this.taxList;
                  this.receiptNativeObject.tax_excluded_grand_total = this.tax_excluded_grand_total;
                } else {
                  this.receiptNativeObject.applicable_tax = this._decimalPipe.transform(this.orderData.total_tax, '1.2-2')
                }
              }
              if (this.orderData.cash_change > 0) {
                this.receiptNativeObject.cash_change = this.orderData.cash_change;
              } else {
                delete this.receiptNativeObject.cash_change
              }
              this.receiptNativeObject.tax_number_permission = this.checkStoreTemplatePermission('invoice_additional_info_tax_id');
              this.receiptNativeObject.tax_number = this.store_detail.gst_number;
              this.receiptNativeObject.invoice_store_phone_permission = this.checkStoreTemplatePermission('invoice_store_phone');
              this.receiptNativeObject.invoice_store_phone = this.store_detail.phone;
              this.receiptNativeObject.invoice_additional_info_footer_note_permission = this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_note').value_ != ''
              this.receiptNativeObject.invoice_additional_info_footer_note = this.stripHtml(storeData.store_footer);
              this.receiptNativeObject.invoice_additional_info_footer_note_locale = this.stripHtml(storeData.store_footer);
              this.receiptNativeObject.invoice_additional_info_footer_image_permission = this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_image').images;
              this.receiptNativeObject.invoice_additional_info_footer_image = this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_image').images;
              this.receiptNativeObject.country_short_name = this.store_detail.country_short_name;
              if (this.store_detail.country_short_name == "SAU") {
                const image_data = await this.generteQrForOrderdetails();
                this.receiptNativeObject.qr = image_data;
                let current_date = new Date();
                let timestamp = current_date.getTime();
                const date = timestamp
                let grand_total: any = 0;
                if (this.orderData?.delivery_charge > 0) {
                  grand_total = this._decimalPipe.transform(this.orderData?.grand_total + this.orderData?.delivery_charge, '1.2-2')
                } else {
                  grand_total = this._decimalPipe.transform(this.orderData?.grand_total, '1.2-2')
                }
                let total_tax: any = 0
                this.taxList.forEach(tax => {
                  total_tax = (parseFloat(total_tax) + parseFloat(tax.amt));
                });
                const qrcontent = {
                  "sellerName": storeData.store_name,
                  "vatRegistrationNumber": this.store_detail.gst_number,
                  "invoiceTimestamp": date,
                  "invoiceTotal": grand_total,
                  "invoiceVatTotal": total_tax.toString(),
                }
                this.receiptNativeObject.qr_content = qrcontent;
              }
              const printerSettingsJsonObject = {
                "printerType": customPrinterRowData.connectivity_type,
                "ip_address": customPrinterRowData.ip_address,
                "port": Number(customPrinterRowData.port),
                "printer_name": customPrinterRowData.name,
                "printer_brand": customPrinterRowData.printer_type
              }
              // Epson.epson({ templateType: this.receiptNativeObject.receipt_template, printType: templatePrinterType, dataJsonObject: this.receiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
              // console.log('epson1', { templateType: this.receiptNativeObject.receipt_template, printType: templatePrinterType, dataJsonObject: this.receiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
              if (appConfig.buildCreateFor == "ios") {
                // const EscPosPrinter = registerPlugin<EscPosPlugin>('EscPos');
                EscPos.escpos({ templateType: this.receiptNativeObject.receipt_template, printType: templatePrinterType, dataJsonObject: this.receiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                console.log('escpos', { templateType: this.receiptNativeObject.receipt_template, printType: templatePrinterType, dataJsonObject: this.receiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject });

              } else {
                if (printerType == 0) {
                  // const EscPosOtherPrinter = registerPlugin<EscPosPlugin>('EscPos');
                  EscPos.escpos({ templateType: this.receiptNativeObject.receipt_template, printType: templatePrinterType, dataJsonObject: this.receiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                  console.log('escpos', { templateType: this.receiptNativeObject.receipt_template, printType: templatePrinterType, dataJsonObject: this.receiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject });
                  // console.log('EscPos - receipt',);
                } else if (printerType == 1) {
                  // const SunmiPrinter = registerPlugin<SunmiPlugin>('Sunmi');
                  Sunmi.sunmi({ templateType: this.receiptNativeObject.receipt_template, printType: templatePrinterType, dataJsonObject: this.receiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                  console.log('sunmi', { templateType: this.receiptNativeObject.receipt_template, printType: templatePrinterType, dataJsonObject: this.receiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject });
                } else {
                  // const EpsonPrinter = registerPlugin<EpsonPlugin>('Epson');
                  // EpsonPrinter.initializePrinter();
                  Epson.epson({ templateType: this.receiptNativeObject.receipt_template, printType: templatePrinterType, dataJsonObject: this.receiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                  console.log('epson', { templateType: this.receiptNativeObject.receipt_template, printType: templatePrinterType, dataJsonObject: this.receiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                  // EpsonPrinter.getDataAndPrint();
                }
              }
            } else {
              let customPrinterRowData = customPrinterData[0];
              let body = new FormData();
              body.append('htmlBase64', encode(this.addExtraHtml(await this.recipthtml(), customPrinterRowData.width)));
              body.append('printerName', customPrinterRowData.name);
              this.http.post(customPrinterRowData.local_computer_host + 'printFromHTML', body).subscribe(res => {
                let printData: any
                printData = res
                if (printData.status == true) {
                  is_print_done = 1;
                } else {
                  this.toastr.warning(printData.message)
                }
              }, (error) => {
                if (error.status === 404) {
                  this.toastr.warning(this.translate.instant('Please check printer configuration'))
                } else {
                  this.toastr.warning(this.translate.instant('Please check printer configuration'))
                }
              });
            }
          } else {
            if (appConfig.buildCreateFor == 'web') {
              this.zone.runOutsideAngular(async () => {
                (window as any).process = process;
                (window as any)['global'] = window;
                let printContents, popupWin;
                printContents = await this.recipthtml();
                popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
                popupWin.document.open();
                popupWin.document.write(`
                      <html>
                        <head>
                          <title>Print tab</title>
                          <link rel="stylesheet" type="text/css" href="assets/styles/print.css">
                          <style>
                          //........Customized style.......
                          </style>
                        </head>
                    <body onload="window.print();window.close()">${printContents}</body>
                      </html>`
                );
                popupWin.document.close();
              });
            }
          }
          if (needToPrintKot == 1) {
            if (appConfig.buildCreateFor == "web") {
              await this.delay(2000);
              await this.kotPrint(this.orderData, [], useGroupId);
            } else {
              await this.delay(3000);
              await this.kotPrint(this.orderData, [], useGroupId);
            }
          }
          return 1;
        });

      }

    } catch (error) {
      console.error(error); // Handle the error as needed
    }
  }

  storeBrand(orderDetails) {

    let store_detail = JSON.parse(localStorage.getItem('store_detail'));
    let store_name = store_detail.name
    let store_logo = store_detail.logo_base_64
    let store_address = store_detail.address
    let store_header = this.htmlconverter(this.checkStoreTemplatePermissionGetValue('invoice_additional_info_header_note'))
    let store_footer = this.htmlconverter(this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_note'))
    if (orderDetails != undefined) {
      if (orderDetails.length > 0) {
        if (store_detail.store_brands.length > 0) {
          let selectedStoreBrand = store_detail.store_brands.find(x => x.id == orderDetails[0].store_brands_id)
          if (selectedStoreBrand) {
            store_name = selectedStoreBrand.name,
              store_logo = selectedStoreBrand.image_base64,
              store_header = this.htmlconverter(selectedStoreBrand.receipt_header),
              store_footer = this.htmlconverter(selectedStoreBrand.receipt_footer),
              store_address = selectedStoreBrand.description
          }
        }
      }
    }
    const store_data = {
      "store_name": store_name,
      "store_logo": store_logo,
      "store_header": store_header,
      "store_footer": store_footer,
      "store_address": store_address
    }
    return store_data
  }

  async recipthtml() {
    const storeData = this.storeBrand(this.orderData.order_details)

    let html = ''

    const powered_by = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; padding-top:15px; color:#000000; line-height:20px;">
    `+ 'Powered by Limerr' + `</td></tr>`

    // 0 = Primary Language
    // 1 = Secondary Language
    // 2 = Dual Language

    let receipt_template = 0
    let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
    if (permissions?.length > 0) {
      let check = permissions.find(x => x.key_ == 'receipt_template_layout');
      if (check) {
        if (check.status == 1 && check.value_ == '1') {
          receipt_template = 1;
        } else if (check.status == 1 && check.value_ == '2') {
          receipt_template = 2;
        }
      }
    }

    let templatePrinterType = 1
    if (permissions?.length > 0) {
      let check = permissions.find(x => x.key_ == 'receipt_template_layout_format');
      if (check) {
        if (check.status == 1 && check.value_ == '2') {
          templatePrinterType = 2
        }
      }
    }

    if (templatePrinterType == 1) { // 0 = Default template, 1 = Featured template 
      let line = `<tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td style="border: 0.5px solid #000;"></td>
            </tr>
          </table>
        </td>
      </tr>`

      let logo = ''
      if (this.checkStoreTemplatePermission('invoice_store_logo') == true) {
        logo = `<tr><td align="center" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td align="center" valign="top"><img src="`+ storeData.store_logo + `" style="display:block;margin:auto;width:auto; height:60px;"
                width="auto" height="60px"></td>
          </tr>
        </table>
      </td></tr>`
      }
      let store = ''
      let store_name = ''
      let store_address = ''
      if (this.checkStoreTemplatePermission('invoice_store_name') == true || this.checkStoreTemplatePermission('invoice_store_address') == true) {

        if (this.checkStoreTemplatePermission('invoice_store_name') == true) {
          store_name = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:14px; font-weight:bold; color:#000000; line-height:20px;">
                  `+ storeData.store_name + `</td></tr>`
        }


        if (this.checkStoreTemplatePermission('invoice_store_address') == true) {
          store_address = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:normal; color:#000000; line-height:16px;">
        `+ storeData.store_address + `</td></tr>`
        }
        store = `<tr>
        <td align="center" valign="top">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
              `+ store_name + `
              `+ store_address + `
          </table>
        </td>
      </tr>`
      }


      let header = ''
      if (this.checkStoreTemplatePermissionGetValue('invoice_additional_info_header_note').value_ != '') {
        header = `<tr><td align="left" valign="top" style="padding:10px 0px;">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
            <td align="center" valign="top"
            style="font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:normal; color:#000000; line-height:18px;">
            <div>
            `+ storeData.store_header + `
            </div>
          </td>
            </tr>
          </table>
        </td></tr>`
      }

      let transaction_number_text = ''
      let live_ref_number_text = ''
      let local_transaction_no_text = ''
      let created_at_formated_text = ''
      let created_by_text = ''
      let invoice_customer_name_text = ''
      let invoice_customer_phone_text = ''
      let profile_text = ''
      let payment_text = ''
      let store_id_text = ''
      let terminal_text = ''
      var transaction_number = ''
      let order_type_text = ''
      let channel_order_display_id = ''
      let address_text = ''
      let note_text = ''
      let delivery_note_text = ''
      let table_text = ''
      let qty_text = ''
      let item_text = ''
      let price_text = ''

      if (receipt_template == 2) {
        transaction_number_text = `<span style="display: inline-block;">` + this.translate.instant('Invoice No') + `. / ` + this.translate.instant('رقم الفاتورة') + `</span>` + ` : `;
        live_ref_number_text = `<span style="display: inline-block;">` + this.translate.instant('Live Ref. No.') + ` / ` + this.translate.instant('المرجع المباشر. لا.') + `</span>` + ` : `;
        local_transaction_no_text = `<span style="display: inline-block;">` + this.translate.instant('Tran No') + ` / ` + this.translate.instant('تراننو') + `</span>` + `: #`;
        created_at_formated_text = `<span style="display: inline-block;">` + this.translate.instant('Dt') + ` / ` + this.translate.instant('د.ت') + `</span>` + `: `;
        created_by_text = `<span style="display: inline-block;">` + this.translate.instant('Clerk') + ` / ` + this.translate.instant('موظف') + `</span>` + `: #`;
        invoice_customer_name_text = `<span style="display: inline-block;">` + this.translate.instant('Name') + ` / ` + this.translate.instant('اسم') + `</span>`;
        invoice_customer_phone_text = `<span style="display: inline-block;">` + this.translate.instant('Phone') + ` / ` + this.translate.instant('هاتف') + `</span>`;
        profile_text = `<span style="display: inline-block;">` + this.translate.instant('Profile') + ` / ` + this.translate.instant('حساب تعريفي') + `</span>`;
        payment_text = `<span style="display: inline-block;">` + this.translate.instant('Payment') + ` / ` + this.translate.instant('قسط') + `</span>`;
        store_id_text = `<span style="display: inline-block;">` + this.translate.instant('Store Id') + ` / ` + this.translate.instant('معرف المتجر') + `</span>`;
        terminal_text = `<span style="display: inline-block;">` + this.translate.instant('Terminal') + ` / ` + this.translate.instant('صالة') + `</span>`;
        order_type_text = `<span style="display: inline-block;">` + this.translate.instant('Order Type') + ` / ` + this.translate.instant('نوع الطلب') + `</span>`;
        channel_order_display_id = `<span style="display: inline-block;">` + this.translate.instant('Channel Order Display Id') + ` / ` + this.translate.instant('معرف عرض ترتيب القناة') + `</span>`;
        address_text = `<span style="display: inline-block;">` + this.translate.instant('Address') + ` / ` + this.translate.instant('عنوان') + `</span>`;
        note_text = `<span style="display: inline-block;">` + this.translate.instant('Note') + ` / ` + this.translate.instant('ملحوظة') + `</span>`;
        delivery_note_text = `<span style="display: inline-block;">` + this.translate.instant('Delivery Note') + ` / ` + this.translate.instant('مذكرة تسليم') + `</span>`;
        table_text = `<span style="display: inline-block;">` + this.translate.instant('Table') + ` / ` + this.translate.instant('طاولة') + `</span>`;
        qty_text = `<span style="display: inline-block;">` + this.translate.instant('Qty') + ` / ` + this.translate.instant('الكمية') + `</span>`;
        item_text = `<span style="display: inline-block;">` + this.translate.instant('Item') + ` / ` + this.translate.instant('غرض') + `</span>`;
        price_text = `<span style="display: inline-block;">` + this.translate.instant('Price') + ` / ` + this.translate.instant('سعر') + `</span>`;
      } else if (receipt_template == 0) {
        transaction_number_text = this.translate.instant('Invoice No') + `. : `;
        live_ref_number_text = this.translate.instant('Live Ref. No.') + ` : `;
        local_transaction_no_text = this.translate.instant('Tran No') + `: #`;
        created_at_formated_text = this.translate.instant('Dt') + `: `;
        created_by_text = this.translate.instant('Clerk') + `: #`;
        invoice_customer_name_text = this.translate.instant('Name');
        invoice_customer_phone_text = this.translate.instant('Phone');
        profile_text = this.translate.instant('Profile');
        payment_text = this.translate.instant('Payment');
        store_id_text = this.translate.instant('Store Id');
        terminal_text = this.translate.instant('Terminal');
        order_type_text = this.translate.instant('Order Type');
        channel_order_display_id = this.translate.instant('Channel Order Display Id');
        address_text = this.translate.instant('Address');
        note_text = this.translate.instant('Note');
        delivery_note_text = this.translate.instant('Delivery Note');
        table_text = this.translate.instant('Table');
        qty_text = this.translate.instant('Qty');
        item_text = this.translate.instant('Item');
        price_text = this.translate.instant('Price');
      } else if (receipt_template == 1) {
        transaction_number_text = this.translate.instant('رقم الفاتورة') + ` : `;
        live_ref_number_text = this.translate.instant('المرجع المباشر. لا.') + ` : `;
        local_transaction_no_text = this.translate.instant('تراننو') + `: #`;
        created_at_formated_text = this.translate.instant('د.ت') + `: `;
        created_by_text = this.translate.instant('موظف') + `: #`;
        invoice_customer_name_text = this.translate.instant('اسم');
        invoice_customer_phone_text = this.translate.instant('هاتف');
        profile_text = this.translate.instant('حساب تعريفي');
        payment_text = this.translate.instant('قسط');
        store_id_text = this.translate.instant('معرف المتجر');
        terminal_text = this.translate.instant('صالة');
        order_type_text = this.translate.instant('نوع الطلب');
        channel_order_display_id = this.translate.instant('معرف عرض ترتيب القناة');
        address_text = this.translate.instant('عنوان');
        note_text = this.translate.instant('ملحوظة');
        delivery_note_text = this.translate.instant('مذكرة تسليم');
        table_text = this.translate.instant('طاولة');
        qty_text = this.translate.instant('الكمية');
        item_text = this.translate.instant('غرض');
        price_text = this.translate.instant('سعر');
      }


      var transaction_number = ''
      transaction_number = `<tr>
        <td align="center" valign="top"
          style="font-family:Arial, Helvetica, sans-serif; font-size:10px;  text-transform:uppercase; font-weight:bold; color:#000000; line-height:20px;">`
        + local_transaction_no_text + this.orderData?.local_transaction_no + ` | ` + transaction_number_text + this.orderData?.store_order_sequence_id + `
        </td>
        </tr>`

      let live_ref_number = '';
      if (this.orderData?.store_order_id != "" && this.orderData?.store_order_id != null && this.orderData?.store_order_id != "null" && this.orderData?.store_order_id != undefined && this.orderData?.store_order_id != "undefined") {
        live_ref_number = `<tr>
        <td align="center" valign="top"
          style="font-family:Arial, Helvetica, sans-serif; font-size:10px; padding-bottom:20px; text-transform:uppercase; font-weight:bold; color:#000000; line-height:20px;">
           `+ live_ref_number_text + this.orderData?.store_order_id + `
        </td>
      </tr>`
      }

      const printOrderData = [];

      printOrderData.push(store_id_text + `: # ` + this.store_id);
      printOrderData.push(terminal_text + `: # ` + this.terminal_id);
      printOrderData.push(created_by_text + this.orderData?.created_by);
      printOrderData.push(created_at_formated_text + this.transformDate(new Date(this.orderData.created_at_formated)));
      if (this.checkStoreTemplatePermission('invoice_customer_name') == true) {
        if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
          printOrderData.push(invoice_customer_name_text + `: N/A`);
        } else {
          printOrderData.push(invoice_customer_name_text + `: ` + this.orderData.customer_name);
        }
      }

      if (this.checkStoreTemplatePermission('invoice_customer_phone') == true) {
        if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
          printOrderData.push(invoice_customer_phone_text + `: N/A`);
        } else {
          printOrderData.push(invoice_customer_phone_text + `: ` + this.orderData.customer_phone);
        }
      }
      printOrderData.push(profile_text + `: ` + this.orderData.tender_profile_name);


      if (this.orderData?.delivery_partner_order_id == "") {
        if (this.orderData?.tender_type_name != '' && this.orderData.tender_profile_type != '1') {
          let tender_type_name_list = []

          if (this.orderData?.order_type.length > 0) {
            if (this.orderData?.delivery_charge > 0) {
              this.orderData?.order_type.forEach(async type => {
                if (parseInt(type.order_price) > 0) {
                  const total = (parseFloat(type.order_price) + parseFloat(this.orderData?.delivery_charge)).toFixed(2);
                  tender_type_name_list.push(type.order_type_name + " (" + total + ")");
                }
              });
            } else {
              this.orderData?.order_type.forEach(async type => {
                if (parseInt(type.order_price) > 0) {
                  tender_type_name_list.push(type.order_type_name + " (" + type.order_price + ")");
                }
              });
            }
          }
          printOrderData.push(payment_text + `: ` + tender_type_name_list.toString());
        }
      } else {
        if (this.orderData?.tender_type_name != '') {
          let tender_type_name_list = []

          if (this.orderData?.order_type.length > 0) {
            this.orderData?.order_type.forEach(async type => {
              if (parseInt(type.order_price) > 0) {
                tender_type_name_list.push(type.order_type_name + " (" + type.order_price + ")");
              }
            });
          }
          printOrderData.push(payment_text + `: ` + tender_type_name_list.toString());
        }
      }



      if (this.orderData?.delivery_partner_id == 7 || this.orderData?.delivery_partner_id == 8) {
        //printOrderData.push(this.translate.instant('Pickup Time') + `: ` + this._datePipe.transform(this.deliveryPartnerOrderDetails?.pickupTime, "M/d/yy, h:mm a"));
        if (this.orderData.delivery_type == 0) {
          printOrderData.push(order_type_text + `: Eat-in`);
        } else if (this.orderData.delivery_type == 1) {
          printOrderData.push(order_type_text + `: Pickup`);
        } else if (this.orderData.delivery_type == 2) {
          printOrderData.push(order_type_text + `: Delivery`);
        }
        // printOrderData.push(this.translate.instant('Channel') + `: ` + this.deliveryPartnerOrderDetails?.channelOrderId);
        printOrderData.push(channel_order_display_id + `: ` + this.orderData.external_order_id);
      }

      if (this.orderData?.table_id != 0) {
        printOrderData.push(table_text + `: ` + this.orderData?.table_name);
      }
      if (this.orderData.customer_address_display && this.orderData?.delivery_type == 2) {
        printOrderData.push(address_text + `: ` + this.orderData.customer_address_display);
      }

      if (this.orderData?.staff_note && this.orderData?.staff_note != 'null' && this.orderData?.staff_note != '0.00' && this.orderData?.staff_note != ' ') {
        printOrderData.push(note_text + `: ` + this.orderData?.staff_note);
      }

      if ((this.orderData?.delivery_partner_id == 7 || this.orderData?.delivery_partner_id == 8) && this.deliveryPartnerOrderDetails?.customer?.note != '' && this.deliveryPartnerOrderDetails?.customer?.note != null) {
        printOrderData.push(delivery_note_text + `: ` + this.deliveryPartnerOrderDetails?.customer?.note);
      }

      let printOrderDatatext = '';
      if (printOrderData.length > 0) {
        let isLeft = 1;
        printOrderDatatext += `<tr>
      <td align="left" valign="top">
        <table class="table-transaction-info" width="100%" border="0" cellspacing="0" cellpadding="0">`;
        printOrderData.forEach(element => {

          if (isLeft == 1) {
            printOrderDatatext += `<tr>
          <td align="left"
            style="font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
            `+ element + `</td>`
            isLeft = 0
          } else {
            printOrderDatatext += `<td align="right"
              style="font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
              `+ element + `</td>
          </tr>`
            isLeft = 1
          }
        });
        if (isLeft == 0) {
          printOrderDatatext += `</tr>`;
        }
        printOrderDatatext += `</table>
      </td>
    </tr>`;
      }

      let orderItems = '';
      if (this.orderData?.order_detail.length > 0) {
        orderItems += `<tr>
      <td align="left" valign="top">
        <table class="table-items-list" width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <th valign="middle"
              style="width:30px; text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:10px; font-weight:bold; color:#000000; line-height:18px;  border-top:1px solid #222222; border-bottom:1px solid #000000;">
              `+ qty_text + `</th>
            <th valign="middle"
              style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:10px; font-weight:bold; color:#000000; line-height:18px;  border-top:1px solid #222222; border-bottom:1px solid #000000;">
              `+ item_text + `</th>
            <th valign="middle"
              style="text-align:right; width:100px; font-family:Arial, Helvetica, sans-serif; font-size:10px; font-weight:bold; color:#000000; line-height:18px;  border-top:1px solid #222222; border-bottom:1px solid #000000;">
              `+ price_text + `</th>
          </tr>`
        this.orderData?.order_detail.forEach(item => {
          let add_addons_detail = ''
          if (item?.order_detail_add_add_ons) {
            add_addons_detail += `<span>`
            item?.order_detail_add_add_ons.forEach(addon => {
              let addon_name_text = ''

              if (addon.other_lang_name != undefined && addon.other_lang_name != null && addon.other_lang_name != "") {
                addon.other_lang_name
              } else {
                addon.other_lang_name = "";
              }

              if (receipt_template == 2) {
                addon_name_text = addon.name + ` / ` + addon.other_lang_name
              } else if (receipt_template == 0) {
                addon_name_text = addon.name
              } else if (receipt_template == 1) {
                addon_name_text = addon.other_lang_name
              }
              add_addons_detail += `<span style="display: block; font-size: 11px;"> <font style="font-size:16px; font-weight: normal;">+</font>&nbsp;
            `+ addon_name_text + ` (` + addon.price + `)</span>`;
            });
            add_addons_detail += `</span>`
          }
          let remove_addons_detail = ''
          if (item?.order_detail_remove_add_ons) {
            remove_addons_detail += `<span>`
            item?.order_detail_remove_add_ons.forEach(remove => {
              let remove_addons_name_text = ''
              if (remove.other_lang_name != undefined && remove.other_lang_name != null && remove.other_lang_name != "") {
                remove.other_lang_name
              } else {
                remove.other_lang_name = ""
              }
              if (receipt_template == 2) {
                remove_addons_name_text = remove.name + ` / ` + remove.other_lang_name
              } else if (receipt_template == 0) {
                remove_addons_name_text = remove.name
              } else if (receipt_template == 1) {
                remove_addons_name_text = remove.other_lang_name
              }

              remove_addons_detail += `<span style="display: block; font-size: 11px;"> <font style="font-size: 16px; font-weight: normal;">-</font>&nbsp;
            `+ remove_addons_name_text + `</span>`
            });
            remove_addons_detail += `</span>`
          }


          let pricelookup_name_text = ''
          let item_note_text = ''
          if (receipt_template == 2) {
            pricelookup_name_text = item.pricelookup_name + ` / ` + item.display_name_language2
            item_note_text = `<span style="display: inline-block;">` + this.translate.instant('Notes') + `. / ` + this.translate.instant('ملحوظات') + `</span>` + ` : `;
          } else if (receipt_template == 0) {
            pricelookup_name_text = item.pricelookup_name;
            item_note_text = this.translate.instant('Notes');
          } else if (receipt_template == 1) {
            pricelookup_name_text = item.display_name_language2;
            item_note_text = this.translate.instant('ملحوظات');
          }

          let notes = '';
          if (item.note != "" && item.note != "undefined" && item.note != "null" && item.note != undefined && item.note != null) {
            notes = `<span style="display: block; font-size: 11px;"><font style="font-size: 13px; font-weight: normal;">` + item_note_text + ` :</font>&nbsp;` + item.note + `</span>`
          }

          let price = '';

          if (item.price_override_item_status == '0') {
            price = `<td style="vertical-align: top;" align="right" >
            <span style="width:100px; text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
            `+ item.cal_price + `</span>
          </td>`
          } else if (item.price_override_item_status == '1') {
            price = `<td style="vertical-align: top;" align="right">
          <span style="width:100px; text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
             `+ this._decimalPipe.transform(item.price_override_item_price, '1.2-2') + `</span></td>`
          }

          orderItems += `<tr>
              <td valign="vertical-align: top;">
                <span style="font-weight: normal; font-size:13px; line-height:16px;">`+ item.pricelookup_qty + `</span></td>
              <td style="vertical-align: top;">
                <span style="font-weight: normal; font-size:11px; line-height:16px;">`+ pricelookup_name_text + `</span> 
                <br>
              `+ add_addons_detail + remove_addons_detail + notes + `
              </td>
              `+ price + `
            </tr>`;

        });
        orderItems += ` </table></td></tr>`
      }

      let sub_total_text = '';
      let round_off_text = '';
      let delivery_charges_text = '';
      let coupon_text = '';
      let coupon_apply_text = '';
      let total_amount_text = '';
      let total_exluding_tax_text = '';
      let gross_amount_text = '';
      let change_text = '';
      let phone_text = '';
      let tax_text = '';

      if (receipt_template == 2) {
        sub_total_text = `<span style="display: inline-block;">` + this.translate.instant('Sub Total') + ` / ` + this.translate.instant('المجموع الفرعي') + `</span>`;
        round_off_text = `<span style="display: inline-block;">` + this.translate.instant('Round Off') + ` / ` + this.translate.instant('نهاية الجولة'); +`</span>`;
        delivery_charges_text = `<span style="display: inline-block;">` + this.translate.instant('Delivery Charge') + ` / ` + this.translate.instant('رسوم التوصيل'); +`</span>`;
        coupon_text = `<span style="display: inline-block;">` + this.translate.instant('Coupon') + ` / ` + this.translate.instant('قسيمة'); +`</span>`;
        coupon_apply_text = `<span style="display: inline-block;">` + this.translate.instant('Applied') + ` / ` + this.translate.instant('مُطبَّق'); +`</span>`;
        total_amount_text = `<span style="display: inline-block;">` + this.translate.instant('Total Amount') + ` / ` + this.translate.instant('المبلغ الإجمالي'); +`</span>`;
        total_exluding_tax_text = `<span style="display: inline-block;">` + this.translate.instant('Total Exluding TAX') + ` / ` + this.translate.instant('الإجمالي باستثناء الضريبة'); +`</span>`;
        gross_amount_text = `<span style="display: inline-block;">` + this.translate.instant('Gross Amount') + ` / ` + this.translate.instant('المبلغ الإجمالي'); +`</span>`;
        change_text = `<span style="display: inline-block;">` + this.translate.instant('Change') + ` / ` + this.translate.instant('التوازن'); +`</span>`;
        phone_text = `<span style="display: inline-block;">` + this.translate.instant('Phone No') + ` / ` + this.translate.instant('رقم الهاتف'); +`</span>`;
        tax_text = `<span style="display: inline-block;">` + this.translate.instant('Tax No') + ` / ` + this.translate.instant('لا تفرض ضرائب'); +`</span>`;
      } else if (receipt_template == 0) {
        sub_total_text = this.translate.instant('Sub Total');
        round_off_text = this.translate.instant('Round Off');
        delivery_charges_text = this.translate.instant('Delivery Charge');
        coupon_text = this.translate.instant('Coupon');
        coupon_apply_text = this.translate.instant('Applied');
        total_amount_text = this.translate.instant('Total Amount');
        total_exluding_tax_text = this.translate.instant('Total Exluding TAX');
        gross_amount_text = this.translate.instant('Gross Amount');
        change_text = this.translate.instant('Change / Balance');
        phone_text = this.translate.instant('Phone No');
        tax_text = this.translate.instant('Tax No');
      } else if (receipt_template == 1) {
        sub_total_text = this.translate.instant('المجموع الفرعي');
        round_off_text = this.translate.instant('نهاية الجولة');
        delivery_charges_text = this.translate.instant('رسوم التوصيل');
        coupon_text = this.translate.instant('قسيمة');
        coupon_apply_text = this.translate.instant('مُطبَّق');
        total_amount_text = this.translate.instant('المبلغ الإجمالي');
        total_exluding_tax_text = this.translate.instant('الإجمالي باستثناء الضريبة');
        gross_amount_text = this.translate.instant('المبلغ الإجمالي');
        change_text = this.translate.instant('لتوازن');
        phone_text = this.translate.instant('رقم الهاتف');
        tax_text = this.translate.instant('لا تفرض ضرائب');
      }

      let subTotal = `<tr>
    <td
      style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
      `+ sub_total_text + `
    </td>
    <td
      style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.orderData?.total_price, '1.2-2') + `</td>
  </tr>`
      let roundOff = `<tr>
              <td
                style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
                `+ round_off_text + `</td>
              <td
                style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(Number(this.orderData.round_off) ?? 0, '1.2-2') + `</td>
            </tr>`

      let couponDiscount = ''
      if (this.orderData?.coupon_id != 0) {
        couponDiscount = `<tr>
        <td
          style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
          `+ coupon_text + ` "` + this.getNameFromCoupon(this.orderData?.coupon) + `" ` + coupon_apply_text + `</td>
        <td
          style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.orderData?.coupon_discount, '1.2-2') + `</td>
      </tr>`
      }

      let grand_total = '';
      if (this.orderData?.delivery_charge > 0) {
        grand_total = `<tr>
      <td
        style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
        `+ delivery_charges_text + `</td>
      <td
        style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.orderData?.delivery_charge, '1.2-2') + `</td>
    </tr>
    <tr>
      <td
        style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;">
        `+ total_amount_text + `</td>
      <td
        style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;">
        `+ this._decimalPipe.transform(this.orderData?.grand_total + this.orderData?.delivery_charge, '1.2-2') + `
      </td>
    </tr>`
      } else {
        grand_total = `<tr>
      <td
        style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;">
        `+ total_amount_text + `</td>
      <td
        style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.orderData?.grand_total, '1.2-2') + `
      </td>
    </tr>`
      }


      let taxListtext = ''
      if (this.taxList.length != 0) {
        taxListtext += `<tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td align="left"
                style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
                `+ total_exluding_tax_text + `</td>
              <td align="right"
                style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.tax_excluded_grand_total, '1.2-2') + `</td>
            </tr>`

        this.taxList.forEach(tax => {
          taxListtext += `<tr>
              <td align="left"
                style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
                `+ tax.name + ` (` + tax.tax_value + `)</td>
              <td align="right"
                style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(tax.amt, '1.2-2') + `</td>
            </tr>`
        });
        taxListtext += `</table>
        </td>
      </tr>`

      }

      let cash_change_text = '';
      if (this.orderData?.cash_change != 0) {
        cash_change_text = `<tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td align="left"
              style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;">
                `+ change_text + `: (` + this.orderData?.cash_change + `)</td>
            </tr>
          </table>
        </td>
      </tr>`
      }

      let tax_and_phone_number = '';
      if (this.checkStoreTemplatePermission('invoice_additional_info_tax_id') == true || this.checkStoreTemplatePermission('invoice_store_phone') == true) {
        tax_and_phone_number += `<tr>
      <td align="left" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">`;
        if (this.checkStoreTemplatePermission('invoice_additional_info_tax_id') == true) {
          tax_and_phone_number += `<tr>
          <td align="left" style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
            `+ tax_text + ` : ` + this.store_detail.gst_number + `</td>
        </tr>`
        }
        if (this.checkStoreTemplatePermission('invoice_store_phone') == true) {
          tax_and_phone_number += `<tr>
              <td align="left" *ngIf="checkStoreTemplatePermission('invoice_store_phone') == true"
                style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
                `+ phone_text + ` : ` + this.store_detail.phone + `</td>
            </tr>`
        }
        tax_and_phone_number += `</table>
      </td>
    </tr>`
      }

      let footer_note = '';
      if (this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_note').value_ != '') {
        footer_note = `<tr>
      <td align="left" valign="top" style="padding: 10px 0px;">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td align="left"
              style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
              <div class="footer-receipt">
              `+ storeData.store_footer + `
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>`;
      }

      let footer_image = '';
      if (this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_image').images) {
        footer_image = `<tr>
      <td align="left" valign="top">
        <table class="table-qr-info" width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td align="center" style="text-align:center; padding:20px 0px 10px 0px; font-family:Arial, Helvetica, sans-serif; font-size:13px; font-weight:normal; color:#000000; line-height:22px;">
              Scan QR code to download mobile app
            </td>
          </tr>
          <tr>
            <td align="center" valign="top"><img src="`+ this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_image').images + `" style="display:block;margin:auto;width:auto; height:60px;"
                width="auto" height="60px"></td>
          </tr>
        </table>
      </td>
    </tr>`
      }

      let qr_image = '';
      if (this.store_detail.country_short_name == "SAU") {
        qr_image = `<tr>
        <td align="left" valign="top">
          <table class="table-qr-info" width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td align="center" style="text-align: center;">
                <img src="`+ await this.generteQrForOrderdetails() + `"
                  width="200" />
              </td>
            </tr>
          </table>
        </td>
      </tr>`
      }

      html += `<div class="invoice-pos">
    <table align="center" width="100%" border="0" cellspacing="0" cellpadding="0" style="box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box;">
      `+ logo + `
      `+ store + `
      `+ header + `
      `+ transaction_number + `
      `+ live_ref_number + `
      `+ printOrderDatatext + `  
      `+ orderItems + `    
      `+ line + `
      <tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
           `+ subTotal + `
            `+ roundOff + `
            `+ couponDiscount + `
            `+ grand_total + `
          </table>
        </td>
      </tr>
      `+ line + `
      `+ taxListtext + `
      `+ line + `
      `+ cash_change_text + `
      `+ tax_and_phone_number + `
      `+ line + `
      `+ footer_note + `
      `+ footer_image + `
      `+ qr_image + `
      `+ powered_by + `
    </table>
  </div>`
    } else {
      let line = `<tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td style="border: 0.5px solid #000;"></td>
            </tr>
          </table>
        </td>
      </tr>`

      let logo = ''
      if (this.checkStoreTemplatePermission('invoice_store_logo') == true) {
        logo = `<tr><td align="center" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td align="center" valign="top"><img src="`+ storeData.store_logo + `" style="display:block;margin:auto;width:auto; height:60px;"
                width="auto" height="60px"></td>
          </tr>
        </table>
      </td></tr>`
      }

      let store = ''
      let store_name = ''
      let store_address = ''
      if (this.checkStoreTemplatePermission('invoice_store_name') == true || this.checkStoreTemplatePermission('invoice_store_address') == true) {

        if (this.checkStoreTemplatePermission('invoice_store_name') == true) {
          store_name = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:14px; font-weight:bold; color:#000000; line-height:20px;">
                  `+ storeData.store_name + `</td></tr>`
        }


        if (this.checkStoreTemplatePermission('invoice_store_address') == true) {
          store_address = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:normal; color:#000000; line-height:16px;">
        `+ storeData.store_address + `</td></tr>`
        }
        store = `<tr>
        <td align="center" valign="top">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
              `+ store_name + `
              `+ store_address + `
          </table>
        </td>
      </tr>`
      }

      let header = ''
      if (this.checkStoreTemplatePermissionGetValue('invoice_additional_info_header_note').value_ != '') {
        header = `<tr><td align="left" valign="top" style="padding:10px 0px;">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
            <td align="center" valign="top"
            style="font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:normal; color:#000000; line-height:18px;">
            <div>
            `+ storeData.store_header + `
            </div>
          </td>
            </tr>
          </table>
        </td></tr>`
      }

      let profile = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:14px; font-weight:bold; color:#000000; line-height:20px;">
              `+ this.orderData.tender_profile_name + `</td></tr>`

      let transaction_number_text = ''
      let local_transaction_no_text = ''
      let created_at_formated_text = ''
      let created_by_text = ''
      let invoice_customer_name_text = ''
      let invoice_customer_phone_text = ''
      let store_id_text = ''
      let terminal_text = ''
      let transaction_number = ''
      let order_type_text = ''
      let channel_order_display_id = ''
      let address_text = ''
      let note_text = ''
      let delivery_note_text = ''
      let table_text = ''
      let qty_text = ''
      let item_text = ''
      let price_text = ''

      if (receipt_template == 2) {
        transaction_number_text = `<span style="display: inline-block;">` + this.translate.instant('Invoice No') + `. / ` + this.translate.instant('رقم الفاتورة') + `</span>` + ` : `;
        local_transaction_no_text = `<span style="display: inline-block;">` + this.translate.instant('Tran No') + ` / ` + this.translate.instant('تراننو') + `</span>` + `: #`;
        created_at_formated_text = `<span style="display: inline-block;">` + this.translate.instant('Dt') + ` / ` + this.translate.instant('د.ت') + `</span>` + `: `;
        created_by_text = `<span style="display: inline-block;">` + this.translate.instant('Clerk') + ` / ` + this.translate.instant('موظف') + `</span>` + `: #`;
        invoice_customer_name_text = `<span style="display: inline-block;">` + this.translate.instant('Name') + ` / ` + this.translate.instant('اسم') + `</span>`;
        invoice_customer_phone_text = `<span style="display: inline-block;">` + this.translate.instant('Phone') + ` / ` + this.translate.instant('هاتف') + `</span>`;
        store_id_text = `<span style="display: inline-block;">` + this.translate.instant('Store Id') + ` / ` + this.translate.instant('معرف المتجر') + `</span>`;
        terminal_text = `<span style="display: inline-block;">` + this.translate.instant('Terminal') + ` / ` + this.translate.instant('صالة') + `</span>`;
        order_type_text = `<span style="display: inline-block;">` + this.translate.instant('Order Type') + ` / ` + this.translate.instant('نوع الطلب') + `</span>`;
        channel_order_display_id = `<span style="display: inline-block;">` + this.translate.instant('Channel Order Display Id') + ` / ` + this.translate.instant('معرف عرض ترتيب القناة') + `</span>`;
        address_text = `<span style="display: inline-block;">` + this.translate.instant('Address') + ` / ` + this.translate.instant('عنوان') + `</span>`;
        note_text = `<span style="display: inline-block;">` + this.translate.instant('Note') + ` / ` + this.translate.instant('ملحوظة') + `</span>`;
        delivery_note_text = `<span style="display: inline-block;">` + this.translate.instant('Delivery Note') + ` / ` + this.translate.instant('مذكرة تسليم') + `</span>`;
        table_text = `<span style="display: inline-block;">` + this.translate.instant('Table') + ` / ` + this.translate.instant('طاولة') + `</span>`;
        qty_text = `<span style="display: inline-block;">` + this.translate.instant('Qty') + ` / ` + this.translate.instant('الكمية') + `</span>`;
        item_text = `<span style="display: inline-block;">` + this.translate.instant('Item') + ` / ` + this.translate.instant('غرض') + `</span>`;
        price_text = `<span style="display: inline-block;">` + this.translate.instant('Price') + ` / ` + this.translate.instant('سعر') + `</span>`;
      } else if (receipt_template == 0) {
        transaction_number_text = this.translate.instant('Invoice No') + `. : `;
        local_transaction_no_text = this.translate.instant('Tran No') + `: #`;
        created_at_formated_text = this.translate.instant('Dt') + `: `;
        created_by_text = this.translate.instant('Clerk') + `: #`;
        invoice_customer_name_text = this.translate.instant('Name');
        invoice_customer_phone_text = this.translate.instant('Phone');
        store_id_text = this.translate.instant('Store Id');
        terminal_text = this.translate.instant('Terminal');
        order_type_text = this.translate.instant('Order Type');
        channel_order_display_id = this.translate.instant('Channel Order Display Id');
        address_text = this.translate.instant('Address');
        note_text = this.translate.instant('Note');
        delivery_note_text = this.translate.instant('Delivery Note');
        table_text = this.translate.instant('Table');
        qty_text = this.translate.instant('Qty');
        item_text = this.translate.instant('Item');
        price_text = this.translate.instant('Price');
      } else if (receipt_template == 1) {
        transaction_number_text = this.translate.instant('رقم الفاتورة') + ` : `;
        local_transaction_no_text = this.translate.instant('تراننو') + `: #`;
        created_at_formated_text = this.translate.instant('د.ت') + `: `;
        created_by_text = this.translate.instant('موظف') + `: #`;
        invoice_customer_name_text = this.translate.instant('اسم');
        invoice_customer_phone_text = this.translate.instant('هاتف');
        store_id_text = this.translate.instant('معرف المتجر');
        terminal_text = this.translate.instant('صالة');
        order_type_text = this.translate.instant('نوع الطلب');
        channel_order_display_id = this.translate.instant('معرف عرض ترتيب القناة');
        address_text = this.translate.instant('عنوان');
        note_text = this.translate.instant('ملحوظة');
        delivery_note_text = this.translate.instant('مذكرة تسليم');
        table_text = this.translate.instant('طاولة');
        qty_text = this.translate.instant('الكمية');
        item_text = this.translate.instant('غرض');
        price_text = this.translate.instant('سعر');
      }

      transaction_number = `<tr>
        <td align="center" valign="top"
          style="font-family:Arial, Helvetica, sans-serif; font-size:10px;  text-transform:uppercase; font-weight:bold; color:#000000; line-height:20px;">`
        + transaction_number_text + this.orderData?.store_order_sequence_id + `</td>
        </tr>`

      let tax_number = ''
      if (this.checkStoreTemplatePermission('invoice_additional_info_tax_id') == true) {
        tax_number += `<tr>
        <td align="center" valign="top"
          style="font-family:Arial, Helvetica, sans-serif; font-size:10px;  text-transform:uppercase; font-weight:bold; color:#000000; line-height:20px;">
          `+ this.translate.instant('TAX No') + ` : ` + this.store_detail.gst_number + `</td>
      </tr>`
      }

      const printOrderData = [];

      printOrderData.push({ "priority": 0, "data": local_transaction_no_text + this.orderData?.local_transaction_no });
      printOrderData.push({ "priority": 0, "data": "  " });
      printOrderData.push({ "priority": 0, "data": created_at_formated_text + this.transformDate(new Date(this.orderData.created_at_formated)) });
      printOrderData.push({ "priority": 0, "data": "  " });
      printOrderData.push({ "priority": 0, "data": created_by_text + this.orderData?.created_by });
      if (this.checkStoreTemplatePermission('invoice_customer_name') == true) {
        if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
          printOrderData.push({ "priority": 0, "data": invoice_customer_name_text + `: N/A` });
        } else {
          printOrderData.push({ "priority": 0, "data": invoice_customer_name_text + `: ` + this.orderData.customer_name });
        }

      }
      if (this.checkStoreTemplatePermission('invoice_customer_phone') == true) {
        if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
          printOrderData.push({ "priority": 0, "data": invoice_customer_phone_text + `: N/A` });
        } else {
          printOrderData.push({ "priority": 0, "data": invoice_customer_phone_text + `: ` + this.orderData.customer_phone });
        }
      }
      printOrderData.push({ "priority": 0, "data": store_id_text + `: # ` + this.store_id });
      printOrderData.push({ "priority": 0, "data": terminal_text + `: # ` + this.terminal_id });

      if (this.orderData?.delivery_partner_id == 7 || this.orderData?.delivery_partner_id == 8) {
        //printOrderData.push({ "priority": 0, "data": this.translate.instant('Pickup Time') + `: ` + this._datePipe.transform(this.deliveryPartnerOrderDetails?.pickupTime, "M/d/yy, h:mm a") });
        if (this.orderData.delivery_type == 0) {
          printOrderData.push({ "priority": 0, "data": order_type_text + `: Eat-in` });
        } else if (this.orderData.delivery_type == 1) {
          printOrderData.push({ "priority": 0, "data": order_type_text + `: Pickup` });
        } else if (this.orderData.delivery_type == 2) {
          printOrderData.push({ "priority": 0, "data": order_type_text + `: Delivery` });
        }
        //printOrderData.push({ "priority": 0, "data": this.translate.instant('Channel') + `: ` + this.deliveryPartnerOrderDetails?.channelOrderId });
        printOrderData.push({ "priority": 0, "data": channel_order_display_id + `: ` + this.orderData.external_order_id });
      }


      if (this.orderData.customer_address_display && this.orderData?.delivery_type == 2) {
        printOrderData.push({ "priority": 0, "data": address_text + `: ` + this.orderData.customer_address_display });
      }

      if (this.orderData?.staff_note && this.orderData?.staff_note != 'null' && this.orderData?.staff_note != '0.00' && this.orderData?.staff_note != ' ') {
        printOrderData.push({ "priority": 0, "data": note_text + `: ` + this.orderData?.staff_note });
      }

      if ((this.orderData?.delivery_partner_id == 7 || this.orderData?.delivery_partner_id == 8) && this.deliveryPartnerOrderDetails?.customer?.note != '' && this.deliveryPartnerOrderDetails?.customer?.note != null) {
        printOrderData.push({ "priority": 0, "data": delivery_note_text + `: ` + this.deliveryPartnerOrderDetails?.customer?.note });
      }

      if (this.orderData?.table_id != 0) {
        printOrderData.push({ "priority": 1, "data": table_text + `: ` + this.orderData?.table_name });
      }

      let printOrderDatatext = '';
      if (printOrderData.length > 0) {
        let isLeft = 1;
        printOrderDatatext += `<tr>
      <td align="left" valign="top">
        <table class="table-transaction-info" width="100%" border="0" cellspacing="0" cellpadding="0">`;
        printOrderData.forEach(element => {
          let style = '';
          if (element.priority == 1) {
            style = 'font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;'
          } else {
            style = 'font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;'
          }
          if (isLeft == 1) {
            printOrderDatatext += `<tr>
          <td align="left"
            style="`+ style + `">
            `+ element.data + `</td>`
            isLeft = 0
          } else {
            printOrderDatatext += `<td align="right"
              style="`+ style + `">
              `+ element.data + `</td>
          </tr>`
            isLeft = 1
          }
        });
        if (isLeft == 0) {
          printOrderDatatext += `</tr>`;
        }
        printOrderDatatext += `</table>
      </td>
    </tr>`;
      }

      let orderItems = '';
      if (this.orderData?.order_detail.length > 0) {
        orderItems += `<tr>
      <td align="left" valign="top">
        <table class="table-items-list" width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <th valign="middle"
              style="width:30px; text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:10px; font-weight:bold; color:#000000; line-height:18px;  border-top:1px solid #222222; border-bottom:1px solid #000000;">
              `+ qty_text + `</th>
            <th valign="middle"
              style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:10px; font-weight:bold; color:#000000; line-height:18px;  border-top:1px solid #222222; border-bottom:1px solid #000000;">
              `+ item_text + `</th>
            <th valign="middle"
              style="text-align:right; width:100px; font-family:Arial, Helvetica, sans-serif; font-size:10px; font-weight:bold; color:#000000; line-height:18px;  border-top:1px solid #222222; border-bottom:1px solid #000000;">
              `+ price_text + `</th>
          </tr>`
        this.orderData?.order_detail.forEach(item => {
          let add_addons_detail = ''
          if (item?.order_detail_add_add_ons) {
            add_addons_detail += `<span>`
            item?.order_detail_add_add_ons.forEach(addon => {
              let addon_name_text = ''
              if (addon.other_lang_name != undefined && addon.other_lang_name != null && addon.other_lang_name != "") {
                addon.other_lang_name
              } else {
                addon.other_lang_name = "";
              }
              if (receipt_template == 2) {
                addon_name_text = addon.name + ` / ` + addon.other_lang_name
              } else if (receipt_template == 0) {
                addon_name_text = addon.name
              } else if (receipt_template == 1) {
                addon_name_text = addon.other_lang_name
              }

              add_addons_detail += `<span style="display: block; font-size: 11px;"> <font style="font-size:16px; font-weight: normal;">+</font>&nbsp;
            `+ addon_name_text + ` (` + addon.price + `)</span>`;
            });
            add_addons_detail += `</span>`
          }
          let remove_addons_detail = ''
          if (item?.order_detail_remove_add_ons) {
            remove_addons_detail += `<span>`
            item?.order_detail_remove_add_ons.forEach(remove => {

              if (remove.other_lang_name != undefined && remove.other_lang_name != null && remove.other_lang_name != "") {
                remove.other_lang_name
              } else {
                remove.other_lang_name = ""
              }
              let remove_addons_name_text = ''

              if (receipt_template == 2) {
                remove_addons_name_text = remove.name + ` / ` + remove.other_lang_name
              } else if (receipt_template == 0) {
                remove_addons_name_text = remove.name
              } else if (receipt_template == 1) {
                remove_addons_name_text = remove.other_lang_name
              }

              remove_addons_detail += `<span style="display: block; font-size: 11px;"> <font style="font-size: 16px; font-weight: normal;">-</font>&nbsp;
            `+ remove_addons_name_text + `</span>`
            });
            remove_addons_detail += `</span>`
          }

          let pricelookup_name_text = ''
          let item_note_text = ''
          if (receipt_template == 2) {
            pricelookup_name_text = item.pricelookup_name + ` / ` + item.display_name_language2
            item_note_text = `<span style="display: inline-block;">` + this.translate.instant('Notes') + `. / ` + this.translate.instant('ملحوظات') + `</span>` + ` : `;
          } else if (receipt_template == 0) {
            pricelookup_name_text = item.pricelookup_name;
            item_note_text = this.translate.instant('Notes');
          } else if (receipt_template == 1) {
            pricelookup_name_text = item.display_name_language2;
            item_note_text = this.translate.instant('ملحوظات');
          }

          let notes = '';
          if (item.note != "" && item.note != "undefined" && item.note != "null" && item.note != undefined && item.note != null) {
            notes = `<span style="display: block; font-size: 11px;"><font style="font-size: 13px; font-weight: normal;">` + item_note_text + ` :</font>&nbsp;` + item.note + `</span>`
          }

          let price = '';

          if (item.price_override_item_status == '0') {
            price = `<td style="vertical-align: top;" align="right" >
            <span style="width:100px; text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
            `+ item.cal_price + `</span>
          </td>`
          } else if (item.price_override_item_status == '1') {
            price = `<td style="vertical-align: top;" align="right">
          <span style="width:100px; text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
             `+ this._decimalPipe.transform(item.price_override_item_price, '1.2-2') + `</span></td>`
          }



          orderItems += `<tr>
              <td valign="vertical-align: top;">
                <span style="font-weight: normal; font-size:13px; line-height:16px;">`+ item.pricelookup_qty + `</span></td>
              <td style="vertical-align: top;">
                <span style="font-weight: normal; font-size:11px; line-height:16px;">`+ pricelookup_name_text + `</span> 
                <br>
              `+ add_addons_detail + remove_addons_detail + notes + `
              </td>
              `+ price + `
            </tr>`;

        });
        orderItems += ` </table></td></tr>`
      }

      let sub_total_text = '';
      let round_off_text = '';
      let delivery_charges_text = '';
      let coupon_text = '';
      let coupon_apply_text = '';
      let total_amount_text = '';
      let total_exluding_tax_text = '';
      let gross_amount_text = '';
      let change_text = '';
      let phone_text = '';
      if (receipt_template == 2) {
        sub_total_text = `<span style="display: inline-block;">` + this.translate.instant('Sub Total') + ` / ` + this.translate.instant('المجموع الفرعي') + `</span>`;
        round_off_text = `<span style="display: inline-block;">` + this.translate.instant('Round Off') + ` / ` + this.translate.instant('نهاية الجولة'); +`</span>`;
        delivery_charges_text = `<span style="display: inline-block;">` + this.translate.instant('Delivery Charge') + ` / ` + this.translate.instant('رسوم التوصيل'); +`</span>`;
        coupon_text = `<span style="display: inline-block;">` + this.translate.instant('Coupon') + ` / ` + this.translate.instant('قسيمة'); +`</span>`;
        coupon_apply_text = `<span style="display: inline-block;">` + this.translate.instant('Applied') + ` / ` + this.translate.instant('مُطبَّق'); +`</span>`;
        total_amount_text = `<span style="display: inline-block;">` + this.translate.instant('Total Amount') + ` / ` + this.translate.instant('المبلغ الإجمالي'); +`</span>`;
        total_exluding_tax_text = `<span style="display: inline-block;">` + this.translate.instant('Total Exluding TAX') + ` / ` + this.translate.instant('الإجمالي باستثناء الضريبة'); +`</span>`;
        gross_amount_text = `<span style="display: inline-block;">` + this.translate.instant('Gross Amount') + ` / ` + this.translate.instant('المبلغ الإجمالي'); +`</span>`;
        change_text = `<span style="display: inline-block;">` + this.translate.instant('Change / Balance') + ` / ` + this.translate.instant('التغيير / التوازن'); +`</span>`;
        phone_text = `<span style="display: inline-block;">` + this.translate.instant('Phone No') + ` / ` + this.translate.instant('رقم الهاتف'); +`</span>`;
      } else if (receipt_template == 0) {
        sub_total_text = this.translate.instant('Sub Total');
        round_off_text = this.translate.instant('Round Off');
        delivery_charges_text = this.translate.instant('Delivery Charge');
        coupon_text = this.translate.instant('Coupon');
        coupon_apply_text = this.translate.instant('Applied');
        total_amount_text = this.translate.instant('Total Amount');
        total_exluding_tax_text = this.translate.instant('Total Exluding TAX');
        gross_amount_text = this.translate.instant('Gross Amount');
        change_text = this.translate.instant('Change / Balance');
        phone_text = this.translate.instant('Phone No');
      } else if (receipt_template == 1) {
        sub_total_text = this.translate.instant('المجموع الفرعي');
        round_off_text = this.translate.instant('نهاية الجولة');
        delivery_charges_text = this.translate.instant('رسوم التوصيل');
        coupon_text = this.translate.instant('قسيمة');
        coupon_apply_text = this.translate.instant('مُطبَّق');
        total_amount_text = this.translate.instant('المبلغ الإجمالي');
        total_exluding_tax_text = this.translate.instant('الإجمالي باستثناء الضريبة');
        gross_amount_text = this.translate.instant('المبلغ الإجمالي');
        change_text = this.translate.instant('التغيير / التوازن');
        phone_text = this.translate.instant('رقم الهاتف');
      }

      let subTotal = `<tr>
        <td
          style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
          `+ sub_total_text + `
        </td>
        <td
          style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.orderData?.total_price, '1.2-2') + `</td>
      </tr>`
      let roundOff = `<tr>
      <td
        style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
        `+ round_off_text + `</td>
      <td
        style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(Number(this.orderData?.round_off) ?? 0, '1.2-2') + `</td>
    </tr>`

      let delivery_charges = ''
      if (this.orderData?.delivery_charge > 0) {
        delivery_charges = `<tr>
        <td
          style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
          `+ delivery_charges_text + `</td>
        <td
          style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.orderData?.delivery_charge, '1.2-2') + `</td>
      </tr>`
      }

      let couponDiscount = ''
      if (this.orderData?.coupon_id != 0) {
        couponDiscount = `<tr>
        <td
          style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
          `+ coupon_text + ` "` + this.getNameFromCoupon(this.orderData?.coupon) + `" ` + coupon_apply_text + `</td>
        <td
          style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.orderData?.coupon_discount, '1.2-2') + `</td>
      </tr>`
      }

      let grand_total = '';

      if (this.orderData?.delivery_charge > 0) {
        grand_total = `
    <tr>
      <td
        style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;">
        `+ total_amount_text + `</td>
      <td
        style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;">
        `+ this._decimalPipe.transform(this.orderData?.grand_total + this.orderData?.delivery_charge, '1.2-2') + `
      </td>
    </tr>`
      } else {
        grand_total = `<tr>
      <td
        style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;">
        `+ total_amount_text + `</td>
      <td
        style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.orderData?.grand_total, '1.2-2') + `
      </td>
    </tr>`
      }

      let taxListtext = ''
      if (this.taxList.length != 0) {
        taxListtext += `<tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td align="left"
                style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
                `+ total_exluding_tax_text + `</td>
              <td align="right"
                style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.tax_excluded_grand_total, '1.2-2') + `</td>
            </tr>`

        this.taxList.forEach(tax => {
          taxListtext += `<tr>
              <td align="left"
                style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
                `+ tax.name + ` (` + tax.tax_value + `)</td>
              <td align="right"
                style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(tax.amt, '1.2-2') + `</td>
            </tr>`
        });
        taxListtext += `</table>
        </td>
      </tr>`

      }

      let gross_total = '';

      if (this.orderData?.delivery_charge > 0) {

        gross_total = `<tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td align="left"
                style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
                `+ gross_amount_text + `</td>
              <td align="right"
                style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.orderData?.grand_total + this.orderData?.delivery_charge, '1.2-2') + `</td>
            </tr>
            </table>
        </td>
      </tr>`
      } else {
        gross_total = `<tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td align="left"
                style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
                `+ gross_amount_text + `</td>
              <td align="right"
                style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.orderData?.grand_total, '1.2-2') + `</td>
            </tr>
            </table>
        </td>
      </tr>`

      }
      let payment_type = ''
      if (this.orderData?.order_type.length > 0) {
        this.orderData?.order_type.forEach(paymentType => {
          if (parseInt(paymentType.order_price) > 0) {

            payment_type += `<tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td align="left"
                style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
                `+ paymentType.order_type_name + `</td>
              <td align="right"
                style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(paymentType.order_price, '1.2-2') + `</td>
            </tr>
            </table>
        </td>
      </tr>`


          }
        });
      }

      let cash_change_text = '';
      if (this.orderData?.cash_change != 0) {

        cash_change_text = `<tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td align="left"
                style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;">
                `+ change_text + `</td>
              <td align="right"
                style="text-align:right; font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:bold; color:#000000; line-height:16px;">`+ this._decimalPipe.transform(this.orderData?.cash_change, '1.2-2') + `</td>
            </tr>
            </table>
        </td>
      </tr>`
      }

      let store_phone_number = '';
      if (this.checkStoreTemplatePermission('invoice_store_phone') == true) {
        store_phone_number += `<tr>
      <td align="left" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">`;
        if (this.checkStoreTemplatePermission('invoice_store_phone') == true) {
          store_phone_number += `<tr>
              <td align="left" *ngIf="checkStoreTemplatePermission('invoice_store_phone') == true"
                style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
                `+ phone_text + ` : ` + this.store_detail.phone + `</td>
            </tr>`
        }
        store_phone_number += `</table>
      </td>
    </tr>`
      }

      let footer_note = '';
      if (this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_note').value_ != '') {
        footer_note = `<tr>
      <td align="left" valign="top" style="padding: 10px 0px;">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td align="left"
              style="text-align:left; font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
              <div class="footer-receipt">
              `+ storeData.store_footer + `
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>`;
      }

      let footer_image = '';
      if (this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_image').images) {
        footer_image = `<tr>
      <td align="left" valign="top">
        <table class="table-qr-info" width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td align="center" style="text-align:center; padding:20px 0px 10px 0px; font-family:Arial, Helvetica, sans-serif; font-size:13px; font-weight:normal; color:#000000; line-height:22px;">
              Scan QR code to download mobile app
            </td>
          </tr>
          <tr>
            <td align="center" valign="top"><img src="`+ this.checkStoreTemplatePermissionGetValue('invoice_additional_info_footer_image').images + `" style="display:block;margin:auto;width:auto; height:60px;"
                width="auto" height="60px"></td>
          </tr>
        </table>
      </td>
    </tr>`
      }

      let qr_image = '';
      if (this.store_detail.country_short_name == "SAU") {
        qr_image = `<tr>
        <td align="left" valign="top">
          <table class="table-qr-info" width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td align="center" style="text-align: center;">
                <img src="`+ await this.generteQrForOrderdetails() + `"
                  width="200" />
              </td>
            </tr>
          </table>
        </td>
      </tr>`
      }


      html += `<div class="invoice-pos" >
      <table align="left" width="100%" border="0" cellspacing="0" cellpadding="0" style="box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box;">
        `+ logo + `
        `+ store + `
        `+ header + `
        `+ profile + `
        `+ line + `
        `+ transaction_number + `
        `+ tax_number + `
        `+ line + `
        `+ printOrderDatatext + `  
        `+ line + `
        `+ orderItems + `
        <tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">    
        `+ subTotal + `    
        `+ roundOff + `
        `+ delivery_charges + `
        `+ couponDiscount + `
        </table>
        </td>
      </tr>
        `+ line + `
        <tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">    
        `+ grand_total + `
        </table>
        </td>
      </tr>
        `+ line + `
        `+ taxListtext + `
        `+ gross_total + `<tr><td>&nbsp</td></tr>
        `+ payment_type + `
        `+ cash_change_text + `
        `+ store_phone_number + `
        `+ footer_note + `
        `+ footer_image + `
        `+ qr_image + `
        `+ powered_by + `
      </table>
    </div>`

    }

    return html;
  }

  async kotPrint(order, order_details_with_print_kot = [], useGroupId = 0, fromPage = 0) {
    // console.log('in kot')
    try {
      await this.enqueue(async () => {
        this.orderData = order;
        var storeData = this.storeBrand(this.orderData.order_detail)
        var allstoreprinters = await this.storePrintersServiceDb.use('GA_DATA', { filter: { "type": "0", "status": 1 }, skip: 0, limit: 100 })
        allstoreprinters = allstoreprinters.filter(x => x.terminal_id == this.orderData.terminal_id)
        if (allstoreprinters?.length > 0) {
          const oldcartData = this.orderData?.order_detail;
          const deletedItemsArray = this.orderData?.deleted_items;
          const printerItemsArray = []
          const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
          // allstoreprinters.forEach(async element => {
          for (const element of allstoreprinters) {
            let printr_data = element
            if (printr_data.type == 0) {
              if (appConfig.buildCreateFor != 'web') {
                this.orderData.order_detail = oldcartData

                let needtoPrint = 0;
                const change_order_details = [];
                let IP = printr_data.ip_address;
                let Port = Number(printr_data.port);
                let printerType = printr_data.printer_type;

                if (printr_data.printer_type == 'Other Printer' || printr_data.printer_type == 0) {
                  printr_data.printer_type = 0
                } else if (printr_data.printer_type == 'Sunmi Printer' || printr_data.printer_type == 1) {
                  printr_data.printer_type = 1
                } else {
                  printr_data.printer_type = 2
                }

                this.kotNativeObject.receipt_template = "0"
                let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
                if (permissions?.length > 0) {
                  let check = permissions.find(x => x.key_ == 'receipt_template_layout');
                  if (check) {
                    if (check.status == 1 && check.value_ == '1') {
                      this.kotNativeObject.receipt_template = "1";
                    } else if (check.status == 1 && check.value_ == '2') {
                      this.kotNativeObject.receipt_template = "2";
                    }
                  }
                }
                const group_id = Math.max.apply(
                  Math,
                  oldcartData
                    .map(o => o?.get_k_d_s_detail?.group_id)
                    .filter(val => typeof val === 'number' && !isNaN(val))
                );
                oldcartData.forEach(item => {
                  // if (this.internetStatus != "Offline") {
                  //   if (item.kitchen_department_store_id != 0) {
                  //     if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                  //       if (useGroupId == 0 && order_details_with_print_kot.length == 0) {
                  //         needtoPrint = 1;
                  //         change_order_details.push(item);
                  //       } else {
                  //         if (group_id == item?.get_k_d_s_detail?.group_id && this.orderData.is_kds_item_updated == 1) {
                  //           item.pricelookup_qty = item?.get_k_d_s_detail?.pricelookup_qty;
                  //           needtoPrint = 1;
                  //           change_order_details.push(item);
                  //         }
                  //       }
                  //     }
                  //   } else {
                  //     if (useGroupId == 0 || (item.is_printed == 0 || item.is_printed == undefined)) {
                  //       printerItemsArray.push(item)
                  //     }
                  //   }
                  // } else {
                  //   if (item.is_printed != 1) {
                  //     if (item.kitchen_department_store_id != 0) {
                  //       if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                  //         needtoPrint = 1;
                  //         change_order_details.push(item);
                  //       }
                  //     } else {
                  //       printerItemsArray.push(item)
                  //     }
                  //   } else {
                  //     if (item.pricelookup_qty_updated && item.pricelookup_qty_updated > 0) {
                  //       if (item.kitchen_department_store_id != 0) {
                  //         if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                  //           if (order_details_with_print_kot.length == 0) {
                  //             needtoPrint = 1;
                  //             change_order_details.push(item);
                  //           }
                  //         }
                  //       } else {
                  //         printerItemsArray.push(item)
                  //       }
                  //     }
                  //   }
                  // }

                  if (item.is_printed != 1) {
                    if (item.kitchen_department_store_id != 0) {
                      if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                        if (order_details_with_print_kot.length == 0) {
                          needtoPrint = 1;
                          change_order_details.push(item);
                        }
                      }
                    } else {
                      printerItemsArray.push(item)
                    }
                  } else if (item.pricelookup_qty_updated && item.pricelookup_qty_updated > 0) {
                    if (item.kitchen_department_store_id != 0) {
                      if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                        if (order_details_with_print_kot.length == 0) {
                          needtoPrint = 1;
                          change_order_details.push(item);
                        }
                      }
                    } else {
                      printerItemsArray.push(item)
                    }
                  } else if (fromPage == 1) {
                    if (item.kitchen_department_store_id != 0) {
                      if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                        if (order_details_with_print_kot.length == 0) {
                          needtoPrint = 1;
                          change_order_details.push(item);
                        }
                      }
                    } else {
                      printerItemsArray.push(item)
                    }
                  }
                  //  else if (fromPage == 2) { // when hold order and after update & print
                  //   if (item.kitchen_department_store_id != 0) {
                  //     if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                  //       if (order_details_with_print_kot.length == 0) {
                  //         needtoPrint = 1;
                  //         change_order_details.push(item);
                  //       }
                  //     }
                  //   } else {
                  //     printerItemsArray.push(item)
                  //   }
                  // }

                });
                // if (deletedItemsArray && deletedItemsArray.length != 0) {
                //   deletedItemsArray.forEach(del_items => {
                //     if (this.internetStatus != "Offline") {
                //       if (del_items.kitchen_department_store_id != 0) {
                //         if (printr_data.kitchen_department_ids.includes(del_items.kitchen_department_store_id)) {
                //           del_items.pricelookup_qty = del_items?.get_k_d_s_detail?.pricelookup_qty;
                //           needtoPrint = 1;
                //           change_order_details.push(del_items);
                //         }
                //       } else {
                //         printerItemsArray.push(del_items)
                //       }
                //     } else {
                //       if (del_items.is_deleted != 1) {
                //         if (del_items.kitchen_department_store_id != 0) {
                //           if (printr_data.kitchen_department_ids.includes(del_items.kitchen_department_store_id)) {
                //             if (order_details_with_print_kot.length == 0) {
                //               needtoPrint = 1;
                //               del_items.is_deleted = 1;
                //               change_order_details.push(del_items);
                //             }
                //           }
                //         } else {
                //           del_items.is_deleted = 1;
                //           printerItemsArray.push(del_items)
                //         }
                //       }
                //     }
                //   });
                // }
                if (deletedItemsArray && deletedItemsArray.length != 0) {
                  deletedItemsArray.forEach(del_items => {
                    if (del_items.is_deleted != 1) {
                      if (del_items.kitchen_department_store_id != 0) {
                        if (printr_data.kitchen_department_ids.includes(del_items.kitchen_department_store_id)) {
                          if (order_details_with_print_kot.length == 0) {
                            needtoPrint = 1;
                            del_items.is_deleted = 1;
                            del_items.order_detail_add_add_ons = del_items.selectedAddOns;
                            del_items.order_detail_remove_add_ons = del_items.selectedRemoveAddOns
                            change_order_details.push(del_items);
                          }
                        }
                      } else {
                        del_items.order_detail_add_add_ons = del_items.selectedAddOns;
                        del_items.order_detail_remove_add_ons = del_items.selectedRemoveAddOns
                        del_items.is_deleted = 1;
                        printerItemsArray.push(del_items)
                      }
                    }
                  });
                }
                if (needtoPrint == 1) {
                  if (JSON.parse(localStorage.getItem('is_order_updated'))) {
                    this.kotNativeObject.is_order_updated = 1; //  ? item.pricelookup_qty_updated:item.pricelookup_qty
                  } else {
                    delete this.kotreceiptNativeObject.is_order_updated;
                  }
                  this.kotNativeObject.order_details = change_order_details;
                  this.kotNativeObject.kot_store_logo_permission = this.checkStoreTemplatePermission('kot_store_logo');
                  this.kotNativeObject.kot_store_logo_base_64 = storeData.store_logo;
                  this.kotNativeObject.kot_heading = 'KOT';
                  this.kotNativeObject.kot_store_name_permission = this.checkStoreTemplatePermission('kot_store_name');
                  this.kotNativeObject.kot_store_name = storeData.store_name;
                  this.kotNativeObject.kot_store_name_locale = storeData.store_name;
                  this.kotNativeObject.kot_store_address_permission = this.checkStoreTemplatePermission('kot_store_address');
                  this.kotNativeObject.kot_store_address = storeData.store_address;
                  this.kotNativeObject.kot_store_address_locale = storeData.store_address;
                  this.kotNativeObject.delivery_partner_order_id = this.orderData?.delivery_partner_order_id;
                  this.kotNativeObject.local_transaction_no = this.orderData.local_transaction_no;
                  this.kotNativeObject.store_order_sequence_id = this.orderData.store_order_sequence_id;
                  if (this.orderData?.delivery_partner_order_id == 0 || this.orderData?.delivery_partner_order_id == null || this.orderData?.delivery_partner_order_id == undefined) {
                    if (this.orderData?.store_order_id != "" && this.orderData?.store_order_id != null && this.orderData?.store_order_id != "null" && this.orderData?.store_order_id != undefined && this.orderData?.store_order_id != "undefined" && this.orderData?.store_order_id != 0) {
                      this.kotNativeObject.live_ref_number = this.orderData?.store_order_id;
                    } else {
                      delete this.kotNativeObject.live_ref_number;
                    }
                  } else {
                    this.kotNativeObject.live_ref_number = this.orderData?.external_order_id;
                  }
                  this.kotNativeObject.kot_store_phone_permission = this.checkStoreTemplatePermission('kot_store_phone');
                  this.kotNativeObject.kot_store_phone = this.store_detail.phone;
                  this.kotNativeObject.clerk_id = this.orderData?.created_by;
                  this.kotNativeObject.kot_customer_name_permission = this.checkStoreTemplatePermission('kot_customer_name');
                  if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
                    this.kotNativeObject.kot_customer_name = "N/A";
                  } else {
                    this.kotNativeObject.kot_customer_name = this.orderData?.customer_name;
                  }
                  this.kotNativeObject.kot_customer_phone_permission = this.checkStoreTemplatePermission('kot_customer_name');
                  if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
                    this.kotNativeObject.kot_customer_phone = "N/A";
                  } else {
                    this.kotNativeObject.kot_customer_phone = this.orderData?.customer_phone;
                  }
                  if (this.orderData.licence_plate != null && this.orderData.licence_plate != 'null' && this.orderData.licence_plate != ' ' && this.orderData.licence_plate != '') {
                    this.kotNativeObject.licence_plate = this.orderData?.licence_plate;
                  } else {
                    delete this.kotNativeObject.licence_plate;
                  }
                  this.kotNativeObject.tender_profile_name = this.orderData?.tender_profile_name;
                  this.kotNativeObject.dt = this.orderData.created_at_formated
                  this.kotNativeObject.table_id = this.orderData?.table_id;
                  if (this.orderData.table_id != 0) {
                    this.kotNativeObject.no_of_persons = this.orderData.numberofguest;
                    this.kotNativeObject.capacity = this.orderData.order_table?.capacity;
                  } else {
                    delete this.kotNativeObject.no_of_persons;
                    delete this.kotNativeObject.capacity;
                  }
                  this.kotNativeObject.kot_customer_table_no_permission = this.checkStoreTemplatePermission('kot_customer_table_no');
                  this.kotNativeObject.table = this.orderData?.table_name;
                  this.kotNativeObject.table_name = this.orderData?.table_name;
                  if (this.orderData.customer_address_display && this.orderData?.delivery_type == 2) {
                    this.kotNativeObject.customer_address = this.orderData.customer_address_display;
                  } else {
                    delete this.kotNativeObject.customer_address;
                  }
                  if (this.orderData.staff_note != undefined && this.orderData.staff_note != 'null' && this.orderData.staff_note != '0.00' && this.orderData.staff_note != ' ' && this.orderData.staff_note != '') {
                    this.kotNativeObject.staff_note = this.orderData?.staff_note;
                    this.kotNativeObject.staff_note_locale = this.orderData?.staff_note;
                  } else {
                    delete this.kotNativeObject.staff_note;
                    delete this.kotNativeObject.staff_note_locale;
                  }
                  const printerSettingsJsonObject = {
                    "printerType": printr_data.connectivity_type,
                    "ip_address": printr_data.ip_address,
                    "port": Number(printr_data.port),
                    "printer_name": printr_data.name,
                    "printer_brand": printr_data.printer_type
                  }
                  if (this.kotNativeObject.order_details.length > 0) {
                    // Epson.epson({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                    //     console.log('epson2', { templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                    if (appConfig.buildCreateFor == "ios") {
                      // const EscPosPrinter = registerPlugin<EscPosPlugin>('EscPos');
                      EscPos.escpos({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotNativeObject, order_details: this.kotNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                      console.log('escpos', { templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotNativeObject, order_details: this.kotNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                    } else {
                      if (printerType == 0) {
                        // const EscPosOtherPrinter = registerPlugin<EscPosPlugin>('EscPos');
                        EscPos.escpos({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotNativeObject, order_details: this.kotNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                        console.log('escpos', { templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotNativeObject, order_details: this.kotNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                        // console.log('escpos kot')
                      } else if (printerType == 1) {
                        // const EscPosSunmiPrinter = registerPlugin<SunmiPlugin>('Sunmi');
                        Sunmi.sunmi({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotNativeObject, order_details: this.kotNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                        console.log('sunmi', { templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotNativeObject, order_details: this.kotNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                      } else {
                        // const EpsonPrinter = registerPlugin<EpsonPlugin>('Epson');
                        //  EpsonPrinter.initializePrinter();
                        Epson.epson({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotNativeObject, order_details: this.kotNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                        console.log('epson', { templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotNativeObject, order_details: this.kotNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                        //  EpsonPrinter.getDataAndPrint();
                      }
                    }
                  }
                }
              } else {
                let needtoPrint = 0;
                const change_order_details = [];
                //const group_id = Math.max.apply(Math, oldcartData.map(o => o?.get_k_d_s_detail?.group_id))
                const group_id = Math.max.apply(
                  Math,
                  oldcartData
                    .map(o => o?.get_k_d_s_detail?.group_id)
                    .filter(val => typeof val === 'number' && !isNaN(val))
                );
                oldcartData.forEach(item => {
                  // if (this.internetStatus != "Offline") {
                  //   if (item.kitchen_department_store_id != 0) {
                  //     if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                  //       if (useGroupId == 0 && order_details_with_print_kot.length == 0) {
                  //         needtoPrint = 1;
                  //         change_order_details.push(item);
                  //       } else {
                  //         if (group_id == item?.get_k_d_s_detail?.group_id && this.orderData.is_kds_item_updated == 1) {
                  //           item.pricelookup_qty = item?.get_k_d_s_detail?.pricelookup_qty;
                  //           needtoPrint = 1;
                  //           change_order_details.push(item);
                  //         }
                  //       }
                  //     }
                  //   } else {
                  //     if (useGroupId == 0 || (item.is_printed == 0 || item.is_printed == undefined)) {
                  //       printerItemsArray.push(item)
                  //     }
                  //   }
                  // } 
                  // else {
                  if (item.is_printed != 1) {
                    if (item.kitchen_department_store_id != 0) {
                      if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                        if (order_details_with_print_kot.length == 0) {
                          needtoPrint = 1;
                          change_order_details.push(item);
                        }
                      }
                    } else {
                      printerItemsArray.push(item)
                    }
                  } else if (item.pricelookup_qty_updated && item.pricelookup_qty_updated > 0) {
                    if (item.kitchen_department_store_id != 0) {
                      if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                        if (order_details_with_print_kot.length == 0) {
                          needtoPrint = 1;
                          change_order_details.push(item);
                        }
                      }
                    } else {
                      printerItemsArray.push(item)
                    }
                    console.log('fromPage', fromPage);
                  } else if (fromPage == 1) {
                    console.log('innn+++++')
                    if (item.kitchen_department_store_id != 0) {
                      if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                        if (order_details_with_print_kot.length == 0) {
                          needtoPrint = 1;
                          change_order_details.push(item);
                        }
                      }
                    } else {
                      printerItemsArray.push(item)
                    }
                  }
                  // else if (fromPage == 2) { // when hold order and after update & print
                  //   console.log('innn+++++')
                  //   if (item.kitchen_department_store_id != 0) {
                  //     if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                  //       if (order_details_with_print_kot.length == 0) {
                  //         needtoPrint = 1;
                  //         change_order_details.push(item);
                  //       }
                  //     }
                  //   } else {
                  //     printerItemsArray.push(item)
                  //   }
                  // }
                  // else {
                  //   console.log('else----',);
                  //   console.log('this.orderData.is_kds_item_updated', this.orderData.is_kds_item_updated);
                  //   console.log('item', item);
                  //   if (item.kitchen_department_store_id != 0) {
                  //     item.pricelookup_qty = item?.get_k_d_s_detail?.pricelookup_qty;
                  //     needtoPrint = 1;
                  //     change_order_details.push(item);
                  //   } else {
                  //     printerItemsArray.push(item)
                  //   }

                  // }


                  // }
                });
                if (deletedItemsArray && deletedItemsArray.length != 0) {
                  deletedItemsArray.forEach(del_items => {
                    // if (this.internetStatus != "Offline") {
                    //   if (del_items.kitchen_department_store_id != 0) {
                    //     if (printr_data.kitchen_department_ids.includes(del_items.kitchen_department_store_id)) {
                    //       del_items.pricelookup_qty = del_items?.get_k_d_s_detail?.pricelookup_qty;
                    //       needtoPrint = 1;
                    //       change_order_details.push(del_items);
                    //     }
                    //   } else {
                    //     printerItemsArray.push(del_items)
                    //   }
                    // } else {
                    if (del_items.is_deleted != 1) {
                      if (del_items.kitchen_department_store_id != 0) {
                        if (printr_data.kitchen_department_ids.includes(del_items.kitchen_department_store_id)) {
                          if (order_details_with_print_kot.length == 0) {
                            needtoPrint = 1;
                            del_items.is_deleted = 1;
                            del_items.order_detail_add_add_ons = del_items.selectedAddOns;
                            del_items.order_detail_remove_add_ons = del_items.selectedRemoveAddOns
                            // console.log('del_items',del_items);
                            change_order_details.push(del_items);
                          }
                        }
                      } else {
                        console.log('del_items.order_detail_add_add_ons', del_items.order_detail_add_add_ons);
                        console.log('del_items.selectedAddOns;', del_items.selectedAddOns)
                        del_items.order_detail_add_add_ons = del_items.selectedAddOns;
                        del_items.order_detail_remove_add_ons = del_items.selectedRemoveAddOns
                        del_items.is_deleted = 1;
                        printerItemsArray.push(del_items)
                      }
                    }
                    // }
                  });
                }
                if (needtoPrint == 1) {
                  // console.log('change_order_details',change_order_details);
                  // console.log('kot print', needtoPrint);
                  //console.log('needtoPrint', needtoPrint);
                  let body = new FormData();
                  body.append('htmlBase64', encode(this.addExtraHtml(this.kotHtml(change_order_details), printr_data.width)));
                  body.append('printerName', printr_data.name);
                  this.http.post(printr_data.local_computer_host + 'printFromHTML', body).subscribe(res => {
                    let printData: any
                    printData = res
                    if (printData.status == true) {
                    } else {
                      this.toastr.warning(printData.message)
                    }
                  }, (error) => {
                    if (error.status === 404) {
                      this.toastr.warning(this.translate.instant('Please check printer configuration'))
                    } else {
                      this.toastr.warning(this.translate.instant('Please check printer configuration'))
                    }
                  });
                }
              }
            }
            if (appConfig.buildCreateFor != 'web') {
              await delay(1000); // Delay of 1 seconds for android and ios 
            }
          }
          // });
          var uniqueItems = printerItemsArray.filter((item, index, self) =>
            index === self.findIndex((t) => (
              t.id === item.id
            ))
          );
          if (uniqueItems.length != 0) {
            this.printKotInReceiptPrinter(uniqueItems)
          }
          if (this.internetStatus == "Offline" &&  uniqueItems?.length == 0) {
            console.log('innnn1111111111111111111++++++++++++++');
            this.orderData.order_detail = this.orderData.order_detail.map(item => {
              // if (item.pricelookup_qty_updated > 0) {
              //   item.pricelookup_qty_updated = 0;
              // }
              return { ...item, is_printed: 1 };  // Add is_printed: 1 to each item
            });
            if (this.orderData?.deleted_items?.length > 0) {
              this.orderData.deleted_items = this.orderData.deleted_items.map(item => {
                return { ...item, is_deleted: 1 };  // Add is_printed: 1 to each item
              });
            }
            await this.ordersServiceDb.use('IU_DATA', [this.orderData], 1)
          }
        } else {
          if (appConfig.buildCreateFor == 'web') {
            this.zone.runOutsideAngular(async () => {
              (window as any).process = process;
              (window as any)['global'] = window;
              let printContentsKot, popupWinKot;
              printContentsKot = await this.kotHtml(this.orderData.order_detail);
              popupWinKot = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
              popupWinKot.document.open();
              popupWinKot.document.write(`
                      <html>
                        <head>
                          <title>Print tab</title>
                          <link rel="stylesheet" type="text/css" href="assets/styles/print.css">
                          <style>
                          //........Customized style.......
                          </style>
                        </head>
                    <body onload="window.print();window.close()">${printContentsKot}</body>
                      </html>`
              );
              popupWinKot.document.close();
            });
          }
        }
      
      });
    } catch (error) {
      console.error(error); // Handle the error as needed
    }
    {
    }
  }

  async printKotInReceiptPrinter(item) {
    // const newitem = item
    // console.log('newitem',newitem);
    let printedOnce = false;
    let customPrinterData = await this.storePrintersServiceDb.use('GA_DATA', { filter: { "type": "1", "status": 1 }, skip: 0, limit: 1 })  
    customPrinterData = customPrinterData.filter(x => x.terminal_id == this.orderData.terminal_id)
    if (customPrinterData && customPrinterData.length > 0) {
      let customPrinterRowData = customPrinterData[0];
      if (appConfig.buildCreateFor == 'web') {
        let body = new FormData();
        body.append('htmlBase64', encode(this.addExtraHtml(this.kotHtml(item), customPrinterRowData.width)));
        body.append('printerName', customPrinterRowData.name);
        if (!printedOnce) { // Introduce a flag to check if printing has been done once
          await this.http.post(customPrinterRowData.local_computer_host + 'printFromHTML', body).subscribe(async res => {
            let printData: any
            printData = res
            if (printData.status == true) {
              printedOnce = true; // Set the flag to true after successful printing
            } else {
              this.toastr.warning(printData.message)
            }
          }, (error) => {
            if (error.status === 404) {
              this.toastr.warning(this.translate.instant('Please check printer configuration'))
            } else {
              this.toastr.warning(this.translate.instant('Please check printer configuration'))
            }
          });
        }
      } else {
        const storeData = this.storeBrand(this.orderData.order_detail)

        let port = customPrinterRowData.port;
        let IP = customPrinterRowData.ip_address;
        let printerType = customPrinterRowData.printer_type;

        if (customPrinterRowData.printer_type == 'Other Printer' || customPrinterRowData.printer_type == 0) {
          customPrinterRowData.printer_type = 0
        } else if (customPrinterRowData.printer_type == 'Sunmi Printer' || customPrinterRowData.printer_type == 1) {
          customPrinterRowData.printer_type = 1
        } else {
          customPrinterRowData.printer_type = 2
        }
        this.kotreceiptNativeObject.receipt_template = "0"
        let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
        if (permissions?.length > 0) {
          let check = permissions.find(x => x.key_ == 'receipt_template_layout');
          if (check) {
            if (check.status == 1 && check.value_ == '1') {
              this.kotreceiptNativeObject.receipt_template = "1";
            } else if (check.status == 1 && check.value_ == '2') {
              this.kotreceiptNativeObject.receipt_template = "2";
            }
          }
        }

        let templatePrinterType = "1"
        if (permissions?.length > 0) {
          let check = permissions.find(x => x.key_ == 'receipt_template_layout_format');
          if (check) {
            if (check.status == 1 && check.value_ == '2') {
              templatePrinterType = "2"
            }
          }
        }
        if (JSON.parse(localStorage.getItem('is_order_updated'))) {
          this.kotreceiptNativeObject.is_order_updated = 1; //  ? item.pricelookup_qty_updated:item.pricelookup_qty
        } else {
          delete this.kotreceiptNativeObject.is_order_updated
        }
        this.kotreceiptNativeObject.order_details = item;
        this.kotreceiptNativeObject.kot_store_logo_permission = this.checkStoreTemplatePermission('kot_store_logo');
        this.kotreceiptNativeObject.kot_store_logo_base_64 = storeData.store_logo;
        this.kotreceiptNativeObject.kot_heading = 'KOT';
        this.kotreceiptNativeObject.kot_store_name_permission = this.checkStoreTemplatePermission('kot_store_name');
        this.kotreceiptNativeObject.kot_store_name = storeData.store_name;
        this.kotreceiptNativeObject.kot_store_name_locale = storeData.store_name;
        this.kotreceiptNativeObject.kot_store_address_permission = this.checkStoreTemplatePermission('kot_store_address');
        this.kotreceiptNativeObject.kot_store_address = storeData.store_address;
        this.kotreceiptNativeObject.kot_store_address_locale = storeData.store_address;
        this.kotreceiptNativeObject.delivery_partner_order_id = this.orderData?.delivery_partner_order_id;
        this.kotreceiptNativeObject.local_transaction_no = this.orderData.local_transaction_no;
        this.kotreceiptNativeObject.store_order_sequence_id = this.orderData.store_order_sequence_id;
        if (this.orderData?.delivery_partner_order_id == 0 || this.orderData?.delivery_partner_order_id == null || this.orderData?.delivery_partner_order_id == undefined) {
          if (this.orderData?.store_order_id != "" && this.orderData?.store_order_id != null && this.orderData?.store_order_id != "null" && this.orderData?.store_order_id != undefined && this.orderData?.store_order_id != "undefined" && this.orderData?.store_order_id != 0) {
            this.kotreceiptNativeObject.live_ref_number = this.orderData?.store_order_id;
          } else {
            delete this.kotreceiptNativeObject.live_ref_number;
          }
        } else {
          this.kotreceiptNativeObject.live_ref_number = this.orderData?.external_order_id;
        }
        this.kotreceiptNativeObject.kot_store_phone_permission = this.checkStoreTemplatePermission('kot_store_phone');
        this.kotreceiptNativeObject.kot_store_phone = this.store_detail.phone;
        this.kotreceiptNativeObject.clerk_id = this.orderData?.created_by;
        this.kotreceiptNativeObject.kot_customer_name_permission = this.checkStoreTemplatePermission('kot_customer_name');
        if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
          this.kotreceiptNativeObject.kot_customer_name = "N/A";
        } else {
          this.kotreceiptNativeObject.kot_customer_name = this.orderData?.customer_name;
        }
        this.kotreceiptNativeObject.kot_customer_phone_permission = this.checkStoreTemplatePermission('kot_customer_name');
        if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
          this.kotreceiptNativeObject.kot_customer_phone = "N/A";
        } else {
          this.kotreceiptNativeObject.kot_customer_phone = this.orderData?.customer_phone;
        }
        if (this.orderData.licence_plate != null && this.orderData.licence_plate != 'null' && this.orderData.licence_plate != ' ' && this.orderData.licence_plate != '') {
          this.kotreceiptNativeObject.licence_plate = this.orderData?.licence_plate;
        } else {
          delete this.kotreceiptNativeObject.licence_plate;
        }
        this.kotreceiptNativeObject.tender_profile_name = this.orderData?.tender_profile_name;
        this.kotreceiptNativeObject.dt = this.orderData.created_at_formated
        this.kotreceiptNativeObject.table_id = this.orderData?.table_id;
        if (this.orderData.table_id != 0) {
          this.kotreceiptNativeObject.no_of_persons = this.orderData.numberofguest;
          this.kotreceiptNativeObject.capacity = this.orderData.order_table?.capacity;
        } else {
          delete this.kotreceiptNativeObject.no_of_persons;
          delete this.kotreceiptNativeObject.capacity;
        }
        this.kotreceiptNativeObject.kot_customer_table_no_permission = this.checkStoreTemplatePermission('kot_customer_table_no');
        this.kotreceiptNativeObject.table = this.orderData?.table_name;
        this.kotreceiptNativeObject.table_name = this.orderData?.table_name;
        if (this.orderData.customer_address_display && this.orderData?.delivery_type == 2) {
          this.kotreceiptNativeObject.customer_address = this.orderData.customer_address_display;
        } else {
          delete this.kotreceiptNativeObject.customer_address;
        }
        if (this.orderData.staff_note != undefined && this.orderData.staff_note != 'null' && this.orderData.staff_note != '0.00' && this.orderData.staff_note != ' ' && this.orderData.staff_note != '') {
          this.kotreceiptNativeObject.staff_note = this.orderData?.staff_note;
          this.kotreceiptNativeObject.staff_note_locale = this.orderData?.staff_note;
        } else {
          delete this.kotreceiptNativeObject.staff_note;
          delete this.kotreceiptNativeObject.staff_note_locale;
        }
        const printerSettingsJsonObject = {
          "printerType": customPrinterRowData.connectivity_type,
          "ip_address": customPrinterRowData.ip_address,
          "port": Number(customPrinterRowData.port),
          "printer_name": customPrinterRowData.name,
          "printer_brand": customPrinterRowData.printer_type
        }
        if (this.kotreceiptNativeObject.order_details.length > 0) {
          // Epson.epson({ templateType: this.kotreceiptNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotreceiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
          //     console.log('epson2', { templateType: this.kotreceiptNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotreceiptNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
          if (appConfig.buildCreateFor == "ios") {
            // const EscPosPrinter = registerPlugin<EscPosPlugin>('EscPos');
            EscPos.escpos({ templateType: this.kotreceiptNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotreceiptNativeObject, order_details: this.kotreceiptNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
            console.log('escpos', { templateType: this.kotreceiptNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotreceiptNativeObject, order_details: this.kotreceiptNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
          } else {
            if (printerType == 0) {
              // const EscPosOtherPrinter = registerPlugin<EscPosPlugin>('EscPos');
              EscPos.escpos({ templateType: this.kotreceiptNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotreceiptNativeObject, order_details: this.kotreceiptNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
              console.log('escpos---', { templateType: this.kotreceiptNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotreceiptNativeObject, order_details: this.kotreceiptNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
            } else if (printerType == 1) {
              // const SunmiPrinter = registerPlugin<SunmiPlugin>('Sunmi');
              Sunmi.sunmi({ templateType: this.kotreceiptNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotreceiptNativeObject, order_details: this.kotreceiptNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
              console.log('sunmi', { templateType: this.kotreceiptNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotreceiptNativeObject, order_details: this.kotreceiptNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
            } else {
              // const EpsonPrinter = registerPlugin<EpsonPlugin>('Epson');
              Epson.epson({ templateType: this.kotreceiptNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotreceiptNativeObject, order_details: this.kotreceiptNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
              console.log('epson---', { templateType: this.kotreceiptNativeObject.receipt_template, printType: "3", dataJsonObject: { ...this.kotreceiptNativeObject, order_details: this.kotreceiptNativeObject.order_details }, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
            }
          }
        }
      }
      if (this.internetStatus == "Offline") {
        // console.log('this.orderData.order_detail',this.orderData.order_detail);
        this.orderData.order_detail = this.orderData.order_detail.map(item => {
          // if (item.pricelookup_qty_updated > 0) {
          //   item.pricelookup_qty_updated = 0;
          // }
          return { ...item, is_printed: 1 };  // Add is_printed: 1 to each item
        });
        if (this.orderData?.deleted_items?.length > 0) {
          this.orderData.deleted_items = this.orderData.deleted_items.map(item => {
            return { ...item, is_deleted: 1 };  // Add is_printed: 1 to each item
          });
        }
        await this.ordersServiceDb.use('IU_DATA', [this.orderData], 1)
      }
    }
  }

  kotHtml(change_order_details) {
    console.log('change_order_details----------------111---', change_order_details);
    const storeData = this.storeBrand(change_order_details)
    // console.log('storeData', storeData);
    let kothtml = '';

    let kot_template = 0
    let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
    if (permissions?.length > 0) {
      let check = permissions.find(x => x.key_ == 'receipt_template_layout');
      if (check) {
        if (check.status == 1 && check.value_ == '1') {
          kot_template = 1;
        } else if (check.status == 1 && check.value_ == '2') {
          kot_template = 2;
        }
      }
    }


    let powered_by = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; padding-top:15px; color:#000000; line-height:20px;">
    `+ 'Powered by Limerr' + `</td></tr>`

    let logo = ''
    if (this.checkStoreTemplatePermission('kot_store_logo') == true) {
      logo = `<tr>
      <td align="center" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td align="center" valign="top"><img src="`+ storeData.store_logo + `" style="display:block;margin:auto;width:auto; height:60px;" width="auto" height="60px"></td>
          </tr>
        </table>
      </td>
    </tr>`
    }

    if (this.orderData?.table_id != 0 && this.checkStoreTemplatePermission('kot_customer_table_no') == true) {
      var tender_profile = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; padding-top:5px; font-size:18px; font-weight:bold; color:#000000; line-height:20px;">
      `+ this.convertToUpperCase(this.orderData?.tender_profile_name) + ` : ` + this.orderData?.table_name + ` </td></tr>`
    } else {
      var tender_profile = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; padding-top:5px; font-size:18px; font-weight:bold; color:#000000; line-height:20px;">
      `+ this.convertToUpperCase(this.orderData?.tender_profile_name) + ` </td></tr>`
    }

    let store = ''
    let store_name = ''
    let store_address = ''

    if (this.checkStoreTemplatePermission('kot_store_name') == true || this.checkStoreTemplatePermission('kot_store_address') == true) {

      if (this.checkStoreTemplatePermission('kot_store_name') == true) {
        store_name += `<tr>
              <td align="center" valign="top" 
                style="font-family:Arial, Helvetica, sans-serif; font-size:14px; text-align:center; font-weight:bold; color:#000000; line-height:20px; width:100%;">
                `+ storeData.store_name + `</td>
            </tr>`
      }

      if (this.checkStoreTemplatePermission('kot_store_address') == true) {
        store_address += `<tr>
        <td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; text-align:center; font-weight:normal; color:#000000; line-height:20px; width:100%; padding-bottom:10px">
          `+ storeData.store_address + `</td>
      </tr>`
      }

      store += `<tr>
      <td align="center" valign="top" width="100%">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center">
        `+ store_name + `
        `+ store_address + `
        </table>
        </td>
      </tr>`
    }

    let transaction_number_text = ''
    let live_ref_number_text = ''
    let local_transaction_no_text = ''
    let created_at_formated_text = ''
    let created_by_text = ''
    let invoice_customer_name_text = ''
    let kot_customer_phone_text = ''
    let store_phone_text = ''
    let licence_text = ''
    let note_text = ''
    let no_of_person_text = ''
    let table_text = ''
    let qty_text = ''
    let item_text = ''

    if (kot_template == 2) {
      transaction_number_text = `<span style="display: inline-block;">` + this.translate.instant('Invoice No') + `. / ` + this.translate.instant('رقم الفاتورة') + `</span>` + ` : `;
      live_ref_number_text = `<span style="display: inline-block;">` + this.translate.instant('Live Ref. No.') + ` / ` + this.translate.instant('المرجع المباشر. لا.') + `</span>` + ` : `;
      local_transaction_no_text = `<span style="display: inline-block;">` + this.translate.instant('Tran No') + ` / ` + this.translate.instant('تراننو') + `</span>` + `: #`;
      created_at_formated_text = `<span style="display: inline-block;">` + this.translate.instant('Dt') + ` / ` + this.translate.instant('د.ت') + `</span>` + `: `;
      created_by_text = `<span style="display: inline-block;">` + this.translate.instant('Clerk') + ` / ` + this.translate.instant('موظف') + `</span>`
      invoice_customer_name_text = `<span style="display: inline-block;">` + this.translate.instant('Name') + ` / ` + this.translate.instant('اسم') + `</span>`;
      no_of_person_text = `<span style="display: inline-block;">` + this.translate.instant('No. of Persons') + ` / ` + this.translate.instant('هاتف') + `</span>`;
      licence_text = `<span style="display: inline-block;">` + this.translate.instant('Licence Plate') + ` / ` + this.translate.instant('لوحة معدنية') + `</span>`;
      kot_customer_phone_text = `<span style="display: inline-block;">` + this.translate.instant('Cust Ph') + ` / ` + this.translate.instant('هاتف') + `</span>`;
      store_phone_text = `<span style="display: inline-block;">` + this.translate.instant('Store Ph') + ` / ` + this.translate.instant('هاتف') + `</span>`;
      note_text = `<span style="display: inline-block;">` + this.translate.instant('Note') + ` / ` + this.translate.instant('ملحوظة') + `</span>`;
      table_text = `<span style="display: inline-block;">` + this.translate.instant('Table') + ` / ` + this.translate.instant('طاولة') + `</span>`;
      qty_text = `<span style="display: inline-block;">` + this.translate.instant('Qty') + ` / ` + this.translate.instant('الكمية') + `</span>`;
      item_text = `<span style="display: inline-block;">` + this.translate.instant('Item') + ` / ` + this.translate.instant('غرض') + `</span>`;
    } else if (kot_template == 0) {
      transaction_number_text = this.translate.instant('Invoice No') + `. : `;
      live_ref_number_text = this.translate.instant('Live Ref. No.') + ` : `;
      local_transaction_no_text = this.translate.instant('Tran No') + `: #`;
      created_at_formated_text = this.translate.instant('Dt') + `: `;
      created_by_text = this.translate.instant('Clerk') + `: #`;
      licence_text = this.translate.instant('Licence Plate');
      no_of_person_text = this.translate.instant('No. of Persons');
      invoice_customer_name_text = this.translate.instant('Name');
      kot_customer_phone_text = this.translate.instant('Cust Ph');
      store_phone_text = this.translate.instant('Store Ph');
      note_text = this.translate.instant('Note');
      table_text = this.translate.instant('Table');
      qty_text = this.translate.instant('Qty');
      item_text = this.translate.instant('Item');
    } else if (kot_template == 1) {
      transaction_number_text = this.translate.instant('رقم الفاتورة') + ` : `;
      live_ref_number_text = this.translate.instant('المرجع المباشر. لا.') + ` : `;
      local_transaction_no_text = this.translate.instant('تراننو') + `: #`;
      created_at_formated_text = this.translate.instant('د.ت') + `: `;
      created_by_text = this.translate.instant('موظف') + `: #`;
      licence_text = this.translate.instant('لوحة معدنية');
      no_of_person_text = this.translate.instant('عدد الأشخاص');
      invoice_customer_name_text = this.translate.instant('اسم');
      kot_customer_phone_text = this.translate.instant('هاتف');
      store_phone_text = this.translate.instant('هاتف');
      note_text = this.translate.instant('ملحوظة');
      table_text = this.translate.instant('طاولة');
      qty_text = this.translate.instant('الكمية');
      item_text = this.translate.instant('غرض');
    }


    let live_ref_number = '';

    if (this.orderData?.delivery_partner_order_id == 0 || this.orderData?.delivery_partner_order_id == null) {
      if (this.orderData?.store_order_id != "" && this.orderData?.store_order_id != null && this.orderData?.store_order_id != "null" && this.orderData?.store_order_id != undefined && this.orderData?.store_order_id != "undefined") {
        live_ref_number = `<tr >
          <td align="center" valign="top"
            style="font-family:Arial, Helvetica, sans-serif; font-size:10px; padding-bottom:20px; text-transform:uppercase; font-weight:bold; color:#000000; line-height:20px;">
            `+ live_ref_number_text + ` ` + this.orderData?.store_order_id + `
          </td>
        </tr>`
      }
    }

    if (this.orderData?.delivery_partner_order_id > 0) {
      live_ref_number = `<tr>
      <td align="center" valign="top"
      style="font-family:Arial, Helvetica, sans-serif; font-size:10px; padding-bottom:20px; text-transform:uppercase; font-weight:bold; color:#000000; line-height:20px;">
          `+ live_ref_number_text + ` ` + this.orderData?.external_order_id + `
        </td>
      </tr>`
    }

    let transaction_number = ''
    if (live_ref_number != '') {
      transaction_number = `<tr>
      <td align="center" valign="top"
      style="font-family:Arial, Helvetica, sans-serif; font-size:10px; text-transform:uppercase; font-weight:bold; color:#000000; line-height:20px;">
      `+ local_transaction_no_text + `. #` + this.orderData?.local_transaction_no + ` | ` + transaction_number_text + `.` +
        this.orderData?.store_order_sequence_id + `</td></tr>`
    } else {
      transaction_number = `<tr>
      <td align="center" valign="top"
      style="font-family:Arial, Helvetica, sans-serif; font-size:10px;padding-bottom:20px; text-transform:uppercase; font-weight:bold; color:#000000; line-height:20px;">
      `+ local_transaction_no_text + `. #` + this.orderData?.local_transaction_no + ` | ` + transaction_number_text + `.` +
        this.orderData?.store_order_sequence_id + `</td></tr>`
    }
    console.log('this.orderData?.store_order_sequence_id', this.orderData?.store_order_sequence_id);

    const printOrderData = [];

    if (this.checkStoreTemplatePermission('kot_store_phone') == true) {
      printOrderData.push({ "priority": 0, "data": store_phone_text + `: ` + this.store_detail.phone });
    }
    printOrderData.push({ "priority": 0, "data": created_by_text + `: # ` + this.orderData?.created_by });

    if (this.checkStoreTemplatePermission('kot_customer_name') == true) {
      if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
        printOrderData.push({ "priority": 0, "data": invoice_customer_name_text + `: N/A` });
      } else {
        printOrderData.push({ "priority": 0, "data": invoice_customer_name_text + `: ` + this.orderData?.customer_name });
      }
    }

    if (this.checkStoreTemplatePermission('kot_customer_phone') == true) {
      if (this.store_detail.default_customer.phone == this.orderData.customer_phone) {
        printOrderData.push({ "priority": 0, "data": kot_customer_phone_text + `: N/A` });
      } else {
        printOrderData.push({ "priority": 0, "data": kot_customer_phone_text + `: ` + this.orderData?.customer_phone });
      }
    }

    // printOrderData.push({ "priority": 0, "data": this.translate.instant('Profile') + `: ` + this.orderData?.tender_profile_name });



    printOrderData.push({ "priority": 0, "data": created_at_formated_text + `: ` + this.transformDate(new Date(this.orderData.created_at_formated)) });

    // if (this.orderData?.table_id != 0 && this.checkStoreTemplatePermission('kot_customer_table_no') == true) {
    //   printOrderData.push({ "priority": 1, "data": this.translate.instant('Table') + `: ` + this.orderData?.table_name });
    // }
    if (this.orderData?.licence_plate != null && this.orderData?.licence_plate != 'null' && this.orderData?.licence_plate != '' && this.orderData?.licence_plate != ' ') {
      printOrderData.push({ "priority": 0, "data": licence_text + `: ` + this.orderData?.licence_plate });
    }

    if (this.orderData?.staff_note && this.orderData?.staff_note != 'null' && this.orderData?.staff_note != '0.00' && this.orderData?.staff_note != ' ') {
      printOrderData.push({ "priority": 0, "data": note_text + `: ` + this.orderData?.staff_note });
    }
    if (this.orderData.table_id != 0) {
      printOrderData.push({ "priority": 0, "data": no_of_person_text + `: ` + this.orderData.numberofguest });

    }

    let printOrderDatatext = '';
    if (printOrderData.length > 0) {
      let isLeft = 1;
      printOrderDatatext += `<tr>
      <td align="left" valign="top">
        <table class="table-transaction-info" width="100%" border="0" cellspacing="0" cellpadding="0">`;
      printOrderData.forEach(element => {
        let style = 'font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;';
        if (element.priority == 1) {
          style = 'font-family:Arial, Helvetica, sans-serif; font-size:14px; font-weight:bold; color:#000000; line-height:16px;'
        }
        if (isLeft == 1) {
          printOrderDatatext += `<tr>
          <td align="left"
            style="`+ style + `">
            `+ element.data + `</td>`
          isLeft = 0
        } else {
          printOrderDatatext += `<td align="right"
              style="`+ style + `">
              `+ element.data + `</td>
          </tr>`
          isLeft = 1
        }
      });
      if (isLeft == 0) {
        printOrderDatatext += `</tr>`;
      }
      printOrderDatatext += `</table>
      </td>
    </tr>`;
    }
    let orderItems = '';
    if (change_order_details.length > 0) {
      orderItems += `<tr>
      <th
        style="font-size:12px; line-height:14px; width:60px; text-align:left; border-top:1px solid #222222; border-bottom:1px solid #222222;">
        `+ qty_text + `</th>
      <th align="left"
        style="font-size:12px; line-height:14px; border-top:1px solid #222222; border-bottom:1px solid #222222;">
        `+ item_text + `
      </th>
    </tr>`

      change_order_details.forEach(item => {
        console.log('item', item);
        let add_addons_detail = ''
        const isDeleted = item.is_deleted == 1;
        // const strikeThroughStyle = isDeleted ? 'text-decoration: line-through;' : '';
        if (item?.order_detail_add_add_ons) {
          add_addons_detail += `<span>`
          item?.order_detail_add_add_ons.forEach(addon => {
            let addon_name_text = ''
            if (addon.other_lang_name != undefined && addon.other_lang_name != null && addon.other_lang_name != "") {
              addon.other_lang_name
            } else {
              addon.other_lang_name = "";
            }
            if (kot_template == 2) {
              addon_name_text = addon.name + ` / ` + addon.other_lang_name
            } else if (kot_template == 0) {
              addon_name_text = addon.name
            } else if (kot_template == 1) {
              addon_name_text = addon.other_lang_name
            }
            add_addons_detail += `<span style="display: block; font-size: 14px;" >
          <font style="font-size:10px; font-weight: normal;">+</font>&nbsp;
          `+ addon_name_text + ` </span>`;
          });
          add_addons_detail += `</span>`
        }
        let remove_addons_detail = ''
        if (item?.order_detail_remove_add_ons) {
          remove_addons_detail += `<span>`
          item?.order_detail_remove_add_ons.forEach(remove => {
            let remove_addons_name_text = ''
            if (remove.other_lang_name != undefined && remove.other_lang_name != null && remove.other_lang_name != "") {
              remove.other_lang_name
            } else {
              remove.other_lang_name = ""
            }
            if (kot_template == 2) {
              remove_addons_name_text = remove.name + ` / ` + remove.other_lang_name
            } else if (kot_template == 0) {
              remove_addons_name_text = remove.name
            } else if (kot_template == 1) {
              remove_addons_name_text = remove.other_lang_name
            }
            remove_addons_detail += `<span style="display: block; font-size: 14px;">
          <font style="font-size: 10px; font-weight: normal;">-</font>&nbsp;
          `+ remove_addons_name_text + `</span>`
          });
          remove_addons_detail += `</span>`
        }

        let pricelookup_name_text = ''
        let item_note_text = ''
        if (kot_template == 2) {
          pricelookup_name_text = item.pricelookup_name + ` / ` + item.display_name_language2
          item_note_text = `<span style="display: inline-block;">` + this.translate.instant('Notes') + `. / ` + this.translate.instant('ملحوظات') + `</span>` + ` : `;
        } else if (kot_template == 0) {
          pricelookup_name_text = item.pricelookup_name;
          item_note_text = this.translate.instant('Notes');
        } else if (kot_template == 1) {
          pricelookup_name_text = item.display_name_language2;
          item_note_text = this.translate.instant('ملحوظات');
        }


        let notes = '';
        if (item.note != "" && item.note != "undefined" && item.note != "null" && item.note != undefined && item.note != null) {
          notes = `<span style="display: block;font-weight: bold;">` + item_note_text + ` :</font>&nbsp;` + item.note + `</span>`
        }
        let is_order_updated = JSON.parse(localStorage.getItem('is_order_updated'))
        if (is_order_updated == 1 && item.pricelookup_qty_updated > 0) {
          const strikeThroughStyle = isDeleted ? 'text-decoration: line-through;' : '';
          console.log('is_order_updated',);
          orderItems += `<tr>
          <td style="vertical-align: top;">
            <span style="font-weight: normal; font-size:14px; line-height:15px; ${strikeThroughStyle}">
              ${item.pricelookup_qty_updated}
            </span>
          </td>
          <td style="vertical-align: top;">
            <span style="font-weight: normal; font-size:14px; line-height:15px; ${strikeThroughStyle}">
              ${pricelookup_name_text}
            </span>
            ${add_addons_detail}
            ${remove_addons_detail}
            ${notes}
          </td>
        </tr>`;
        } else {
          const strikeThroughStyle = isDeleted ? 'text-decoration: line-through;' : '';
          console.log('else part',);
          orderItems += `<tr>
          <td style="vertical-align: top;">
            <span style="font-weight: normal; font-size:14px; line-height:15px; ${strikeThroughStyle}">
              ${item.pricelookup_qty}
            </span>
          </td>
          <td style="vertical-align: top;">
            <span style="font-weight: normal; font-size:14px; line-height:15px; ${strikeThroughStyle}">
              ${pricelookup_name_text}
            </span>
            ${add_addons_detail}
            ${remove_addons_detail}
            ${notes}
          </td>
        </tr>`;
        }
      });
      orderItems += `<tr>
      <td colspan="4" style="border-bottom:1px solid #222222;"></td>
    </tr>`
    }

    kothtml += `<div class="invoice-pos">
    <h2>`+ this.translate.instant('KOT') + `</h2>
    <table class="table-transaction-info" align="center" width="100%" border="0" cellspacing="0" cellpadding="0"
      style="box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box;">
      `+ logo + `
      `+ tender_profile + `
      `+ store + `
      `+ transaction_number + `
      `+ live_ref_number + `      
    </table>
    <table class="table-transaction-info" width="100%" border="0" cellspacing="0" cellpadding="0">
      `+ printOrderDatatext + `
    </table>
    <table class="table-items-list" width="100%" border="0" cellspacing="0" cellpadding="0">
      `+ orderItems + `
    </table>
    <table class="table-transaction-info" width="100%" border="0" cellspacing="0" cellpadding="0">
    `+ powered_by + `
  </table>
  
  </div>`;
    return kothtml;
  }

  addExtraHtml(html, width = '') {
    let printerWidth = "72mm"
    if (width != '') {
      printerWidth = width;
    }

    return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
              <html xmlns="http://www.w3.org/1999/xhtml">
              <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <meta http-equiv="X-UA-Compatible" content="ie=edge">
              <title>Invoice</title>
              </head>
              <style type="text/css">
              @page {
                margin:0;
                padding:0;
             }
              html, body {
                margin: 0;
                padding: 0;
                font-family:Arial, Helvetica, sans-serif;
                font-size:10px;
                color:#000000;
                line-height:normal;
              }
              * {
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                -o-box-sizing: border-box;
              }
              .invoice-pos {
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
                width:`+ printerWidth + `;
                height: auto;
                display: block;
                font-size: 12px;
                line-height:22px;
                margin:0;	
                padding:2mm;
              }
              .invoice-pos h2 {
                font-size: 16px;
                text-transform: uppercase;
                text-align: center;
                margin: 10px 0;
                font-weight:normal;

              }
              .invoice-pos h4 {
                font-size: 12px;
                text-align: center;
                font-weight: 400;
                margin: 10px 0;
              }
              .invoice-pos-eod {
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
                width:`+ printerWidth + `;
                height: auto;
                display: block;
                font-size: 12px;
                line-height:22px;
                margin:0;	
                padding:2mm;
              }
              .invoice-pos-eod h2 {
                font-size: 16px;
                text-transform: uppercase;
                text-align: center;
                margin: 10px 0;
                font-weight: bold;
              }
              .invoice-pos-eod h4 {
                font-size: 12px;
                text-align: center;
                font-weight: 400;
                margin: 10px 0;
              }
              .table-items-list {
                margin-bottom: 10px;
              }
              .table-items-list tr th {
                padding:5px 0px;
                vertical-align: middle;
                line-height:24px;
              }
              ..table-items-list-exe tr td {
                padding:5px 0px;
                vertical-align: middle;
                line-height:16px;
              }
              .table-items-list tr th {
                border-bottom: 2px solid #dddddd;
                padding:5px 0px;
                border-top: 1px solid #dddddd;
                font-weight: bold;
              }
              .company-logo {
                  padding-bottom:30px;
              }
              .company-logo img {
                  height:150px;
                  width: auto;
                  display: block;
                  margin: 0 auto;
              }
              .table-transaction-info tr td,
              .items-amount-detail tr td,
              .gst-percentage tr td {
                line-height: 24px;
              }
              .table-detail tr td {
                  vertical-align: middle;
                  line-height: 24px;
              }
              .invoice-pos-one-min
              {
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
                width:`+ printerWidth + `;
                height: auto;
                display: block;
                font-size: 12px;
                line-height: 22px;
                margin:0;
                padding:2mm;
              }
              .invoice-pos-one-min h2 {
                font-size: 12px;
                text-transform: uppercase;
                text-align: center;
                margin: 10px 0;
                font-weight: bold;
              }
              .invoice-pos-one-min h4 {
                font-size: 12px;
                text-align: center;
                font-weight: 400;
                margin: 10px 
              }
              </style>
              <body>
              `+ html + `
              </body>
              </html>`
  }

  checkStoreTemplatePermission(item) {
    let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
    if (permissions?.length > 0) {
      let check = permissions.find(x => x.key_ == item);
      if (check) {
        if (check.status == 1 && check.value_ == '1') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false;
    }
  }

  checkStoreTemplatePermissionGetValue(item) {
    let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
    if (permissions?.length > 0) {
      let check = permissions.find(x => x.key_ == item);
      if (check) {
        if (check.status == 1 && check.value_ != '') {
          return check.value_
        } else {
          return check
        }
      } else {
        return ''
      }
    } else {
      return '';
    }
  }

  async generteQrForOrderdetails() {
    const storeData = this.storeBrand(this.orderData.order_detail)
    const date = this._datePipe.transform(new Date(), "yyyy-MM-dd hh:mm:ss")
    let grand_total: any = 0;
    if (this.orderData?.delivery_charge > 0) {
      grand_total = this._decimalPipe.transform(this.orderData?.grand_total + this.orderData?.delivery_charge, '1.2-2')
    } else {
      grand_total = this._decimalPipe.transform(this.orderData?.grand_total, '1.2-2')
    }
    let total_tax: any = 0
    this.taxList.forEach(tax => {
      total_tax = (parseFloat(total_tax) + parseFloat(tax.amt));
    });
    const invoice = new Invoice({
      sellerName: storeData.store_name,
      vatRegistrationNumber: this.store_detail.gst_number,
      invoiceTimestamp: date,
      invoiceTotal: grand_total,
      invoiceVatTotal: total_tax.toString(),
    });

    const imageData = await invoice.render();
    //const imageBase64Data = await invoice.toBase64();
    //console.log('imageBase64Data',imageBase64Data)
    return imageData
    //return btoa("##" + this.store_detail.name + "##" + this.store_detail.gst_number + "##" + date + "##" + grand_total + "##" + total_tax)
  }

  stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  htmlconverter(htmlstring) {
    return this.decodeHtmlEntities.transform(htmlstring, [])
  }

  truncateText(text, length) {
    if (typeof text != 'string') {
      text = text.toString();
    }
    if (text.length <= length) {
      return text;
    }
    return text.substr(0, length - 1) + '-'
  }

  transformDate(date) {
    return this._datePipe.transform(date, 'dd-MM-YYYY hh:mm a');
  }

  groupBy(taxList, keyGetter) {
    const map = new Map();
    taxList.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getNameFromCoupon(obj) {
    if (obj != null) {
      obj = JSON.parse(obj);
      return obj?.discount_code;
    } else {
      return "";
    }
  }

  async goToTestPrint(printer) {
    this.toastr.success(this.translate.instant('Print applied successfully'))
    if (appConfig.buildCreateFor != 'web') {
      let customPrinterRowData = printer;
      let port = customPrinterRowData.port;
      let IP = customPrinterRowData.ip_address;
      let Port = Number(port);
      let printerType = customPrinterRowData.printer_type;

      if (customPrinterRowData.printer_type == 'Other Printer' || customPrinterRowData.printer_type == 0) {
        customPrinterRowData.printer_type = 0
      } else if (customPrinterRowData.printer_type == 'Sunmi Printer' || customPrinterRowData.printer_type == 1) {
        customPrinterRowData.printer_type = 1
      } else {
        customPrinterRowData.printer_type = 2
      }

      const printerSettingsJsonObject = {
        "printerType": customPrinterRowData.connectivity_type,
        "ip_address": customPrinterRowData.ip_address,
        "port": Number(customPrinterRowData.port),
        "printer_name": customPrinterRowData.name,
        "printer_brand": customPrinterRowData.printer_type
      }

      const jsonObject = {
        "printer_name": printer.name
      }
      try {
        if (appConfig.buildCreateFor == "ios") {
          EscPos.escpos({ templateType: '', printType: "6", dataJsonObject: jsonObject, titlesJsonObject: {}, printerSettingsJsonObject: printerSettingsJsonObject });
        } else {
          if (printerType == 0) {
            // const EscPosPrinter = registerPlugin<EscPosPlugin>('EscPos');
            EscPos.escpos({ templateType: '', printType: "6", dataJsonObject: jsonObject, titlesJsonObject: {}, printerSettingsJsonObject: printerSettingsJsonObject });
            console.log('EscPos - test',);
          } else if (printerType == 1) {
            // const SunmiPrinter = registerPlugin<SunmiPlugin>('Sunmi');
            Sunmi.sunmi({ templateType: '', printType: "6", dataJsonObject: jsonObject, titlesJsonObject: {}, printerSettingsJsonObject: printerSettingsJsonObject });
          } else {
            // const EpsonPrinter = registerPlugin<EpsonPlugin>('Epson');
            Epson.epson({ templateType: '', printType: "6", dataJsonObject: jsonObject, titlesJsonObject: {}, printerSettingsJsonObject: printerSettingsJsonObject });
            console.log('Epson - test',);
          }
        }
      } catch (error) {
        console.log('Error while calling plugin:', error);
        this.toastr.error(this.translate.instant('Error while printing'));
      }

    } else {
      let customPrinterRowData = printer;
      let body = new FormData();
      body.append('htmlBase64', encode(this.addExtraHtml(
        `<div class="invoice-pos">
        <table align="center" width="100%" border="0" cellspacing="0" cellpadding="0" style="box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box;">
        <tr><td align="left" valign="top" style="padding:10px 0px;">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
            <td align="center" valign="top"
            style="font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:normal; color:#000000; line-height:18px;">
            <div>
            Successfully connected to - ` + printer.name + `
            </div>
          </td>
            </tr>
          </table>
        </td></tr></table></div>`,
        customPrinterRowData.width)));
      body.append('printerName', customPrinterRowData.name);
      this.http.post(customPrinterRowData.local_computer_host + 'printFromHTML', body).subscribe(res => {
        let printData: any
        printData = res
        if (printData.status == true) {
        } else {
          this.toastr.warning(printData.message)
        }
      }, (error) => {
        if (error.status === 404) {
          this.toastr.warning(this.translate.instant('Please check printer configuration'))
        } else {
          this.toastr.warning(this.translate.instant('Please check printer configuration'))
        }
      });
    }
  }

  async goToDrawerPrint(ejectData) {
    this.toastr.success(this.translate.instant('Print applied successfully'))
    let is_print_done = 0;
    let customPrinterData = await this.storePrintersServiceDb.use('GA_DATA', { filter: { "type": "1", "status": 1 }, skip: 0, limit: 1 })
    if (customPrinterData && customPrinterData.length > 0) {
      if (appConfig.buildCreateFor != 'web') {
        is_print_done = 1;
        let customPrinterRowData = customPrinterData[0];
        let port = customPrinterRowData.port;
        let IP = customPrinterRowData.ip_address;
        let Port = Number(port);
        let printerType = customPrinterRowData.printer_type;

        if (customPrinterRowData.printer_type == 'Other Printer' || customPrinterRowData.printer_type == 0) {
          customPrinterRowData.printer_type = 0
        } else if (customPrinterRowData.printer_type == 'Sunmi Printer' || customPrinterRowData.printer_type == 1) {
          customPrinterRowData.printer_type = 1
        } else {
          customPrinterRowData.printer_type = 2
        }
        const storeData = this.storeBrand(this.orderData.order_detail)

        this.drawerNativeObject.receipt_template = "0"
        let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
        if (permissions?.length > 0) {
          let check = permissions.find(x => x.key_ == 'receipt_template_layout');
          if (check) {
            if (check.status == 1 && check.value_ == '1') {
              this.drawerNativeObject.receipt_template = "1";
            } else if (check.status == 1 && check.value_ == '2') {
              this.drawerNativeObject.receipt_template = "2";
            }
          }
        }
        const currentUser = JSON.parse(localStorage.getItem('currentUser'))
        this.drawerNativeObject.invoice_store_name_permission = this.checkStoreTemplatePermission('invoice_store_name');
        this.drawerNativeObject.invoice_store_name = storeData.store_name;
        this.drawerNativeObject.invoice_store_address_permission = this.checkStoreTemplatePermission('invoice_store_address');
        this.drawerNativeObject.invoice_store_address = storeData.store_address;
        this.drawerNativeObject.store_id = this.store_id;
        this.drawerNativeObject.terminal_id = this.terminal_id;
        this.drawerNativeObject.clerk_id = currentUser.id;
        let current_date = new Date();
        let timestamp = current_date.getTime();
        this.drawerNativeObject.dt = timestamp;
        this.drawerNativeObject.ejectdata_title = ejectData.title;
        const printerSettingsJsonObject = {
          "printerType": customPrinterRowData.connectivity_type,
          "ip_address": customPrinterRowData.ip_address,
          "port": Number(customPrinterRowData.port),
          "printer_name": customPrinterRowData.name,
          "printer_brand": customPrinterRowData.printer_type
        }
        // Epson.epson({ templateType: this.drawerNativeObject.receipt_template, printType: "7", dataJsonObject: this.drawerNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
        // console.log('epson4', { templateType: this.drawerNativeObject.receipt_template, printType: "7", dataJsonObject: this.drawerNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject });
        if (appConfig.buildCreateFor == "ios") {
          EscPos.escpos({ templateType: this.drawerNativeObject.receipt_template, printType: "7", dataJsonObject: this.drawerNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
          console.log('escpos', { templateType: this.drawerNativeObject.receipt_template, printType: "7", dataJsonObject: this.drawerNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject });
        } else {
          if (printerType == 0) {
            EscPos.escpos({ templateType: this.drawerNativeObject.receipt_template, printType: "7", dataJsonObject: this.drawerNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
            console.log('escpos', { templateType: this.drawerNativeObject.receipt_template, printType: "7", dataJsonObject: this.drawerNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject });
          } else if (printerType == 1) {
            Sunmi.sunmi({ templateType: this.drawerNativeObject.receipt_template, printType: "7", dataJsonObject: this.drawerNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
            console.log('sunmi', { templateType: this.drawerNativeObject.receipt_template, printType: "7", dataJsonObject: this.drawerNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject });
          } else {
            Epson.epson({ templateType: this.drawerNativeObject.receipt_template, printType: "7", dataJsonObject: this.drawerNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
            console.log('epson', { templateType: this.drawerNativeObject.receipt_template, printType: "7", dataJsonObject: this.drawerNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject });
          }
        }
      } else {
        let customPrinterRowData = customPrinterData[0];
        let body = new FormData();
        body.append('htmlBase64', encode(this.addExtraHtml(await this.drawerHtml(ejectData), customPrinterRowData.width)));
        body.append('printerName', customPrinterRowData.name);
        this.http.post(customPrinterRowData.local_computer_host + 'printFromHTML', body).subscribe(res => {
          let printData: any
          printData = res
          if (printData.status == true) {
            is_print_done = 1;
          } else {
            this.toastr.warning(printData.message)
          }
        }, (error) => {
          if (error.status === 404) {
            this.toastr.warning(this.translate.instant('Please check printer configuration'))
          } else {
            this.toastr.warning(this.translate.instant('Please check printer configuration'))
          }
        });
      }
    } else {
      if (appConfig.buildCreateFor == 'web') {
        this.zone.runOutsideAngular(async () => {
          (window as any).process = process;
          (window as any)['global'] = window;
          let printContents, popupWin;
          printContents = await this.drawerHtml(ejectData);
          popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
          popupWin.document.open();
          popupWin.document.write(`
                <html>
                  <head>
                    <title>Print tab</title>
                    <link rel="stylesheet" type="text/css" href="assets/styles/print.css">
                    <style>
                    //........Customized style.......
                    </style>
                  </head>
              <body onload="window.print();window.close()">${printContents}</body>
                </html>`
          );
          popupWin.document.close();
        });
      }
    }
  }

  async drawerHtml(ejectData) {

    let drawer_template = 0
    let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
    if (permissions?.length > 0) {
      let check = permissions.find(x => x.key_ == 'receipt_template_layout');
      if (check) {
        if (check.status == 1 && check.value_ == '1') {
          drawer_template = 1;
        } else if (check.status == 1 && check.value_ == '2') {
          drawer_template = 2;
        }
      }
    }

    const storeData = this.storeBrand(this.orderData.order_details)
    let html = ''
    let store = ''
    let store_name = ''
    let store_address = ''
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
    const store_detail = JSON.parse(localStorage.getItem('store_detail'));
    let terminal_id = localStorage.getItem('terminal_id');
    let store_id = localStorage.getItem('store_id');


    let created_at_formated_text = ''
    let created_by_text = ''
    let store_id_text = ''
    let terminal_text = ''

    if (drawer_template == 2) {

      created_at_formated_text = `<span style="display: inline-block;">` + this.translate.instant('Dt') + ` / ` + this.translate.instant('د.ت') + `</span>` + `: `;
      created_by_text = `<span style="display: inline-block;">` + this.translate.instant('Clerk') + ` / ` + this.translate.instant('موظف') + `</span>` + `: #`;
      store_id_text = `<span style="display: inline-block;">` + this.translate.instant('Store Id') + ` / ` + this.translate.instant('معرف المتجر') + `</span>`;
      terminal_text = `<span style="display: inline-block;">` + this.translate.instant('Terminal') + ` / ` + this.translate.instant('صالة') + `</span>`;
    } else if (drawer_template == 0) {
      created_at_formated_text = this.translate.instant('Dt') + `: `;
      created_by_text = this.translate.instant('Clerk') + `: #`;
      store_id_text = this.translate.instant('Store Id');
      terminal_text = this.translate.instant('Terminal');
    } else if (drawer_template == 1) {
      created_at_formated_text = this.translate.instant('د.ت') + `: `;
      created_by_text = this.translate.instant('موظف') + `: #`;
      store_id_text = this.translate.instant('معرف المتجر');
      terminal_text = this.translate.instant('صالة');
    }


    if (this.checkStoreTemplatePermission('invoice_store_name') == true || this.checkStoreTemplatePermission('invoice_store_address') == true) {

      if (this.checkStoreTemplatePermission('invoice_store_name') == true) {
        store_name = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:14px; font-weight:bold; color:#000000; line-height:20px;">
                `+ storeData.store_name + `</td></tr>`
      }


      if (this.checkStoreTemplatePermission('invoice_store_address') == true) {
        store_address = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; font-weight:normal; color:#000000; line-height:16px;">
      `+ storeData.store_address + `</td></tr>`
      }
      store = `<tr>
      <td align="center" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
            `+ store_name + `
            `+ store_address + `
        </table>
      </td>
    </tr>`
    }
    const printOrderData = [];
    printOrderData.push(store_id_text + `: # ` + store_id);
    printOrderData.push(terminal_text + `: # ` + terminal_id);
    printOrderData.push(created_by_text + `: # ` + currentUser.id);
    printOrderData.push(created_at_formated_text + `: ` + this.transformDate(new Date()));
    let printOrderDatatext = '';
    if (printOrderData.length > 0) {
      let isLeft = 1;
      printOrderDatatext += `<tr>
      <td align="left" valign="top">
        <table class="table-transaction-info" width="100%" border="0" cellspacing="0" cellpadding="0">`;
      printOrderData.forEach(element => {

        if (isLeft == 1) {
          printOrderDatatext += `<tr>
          <td align="left"
            style="font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
            `+ element + `</td>`
          isLeft = 0
        } else {
          printOrderDatatext += `<td align="right"
              style="font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;">
              `+ element + `</td>
          </tr>`
          isLeft = 1
        }
      });
      if (isLeft == 0) {
        printOrderDatatext += `</tr>`;
      }
      printOrderDatatext += `</table>
      </td>
    </tr>`;
    }

    let ejectTitle = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; padding-top:10px; padding-bottom:10px; color:#000000; line-height:20px;">
    Reason for opening drawer - ` + ejectData.title + `</td></tr>`

    let line = `<tr>
        <td align="left" valign="top">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td style="border: 0.5px solid #000;"></td>
            </tr>
          </table>
        </td>
      </tr>`

    let powered_by = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; padding-top:15px; color:#000000; line-height:20px;">
    `+ 'Powered by Limerr' + `</td></tr>`

    html += `<div class="invoice-pos">
    <table align="center" width="100%" border="0" cellspacing="0" cellpadding="0" style="box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box;">
      `+ store + `
      `+ printOrderDatatext + `  
      `+ line + `
      `+ ejectTitle + `
      `+ line + `
      `+ powered_by + `
    </table>
  </div>`
    return html;
  }

  async printPosPageKot(customer, tenderProfile, cart) {
    try {
      await this.enqueue(async () => {
        this.store_detail = JSON.parse(localStorage.getItem('store_detail'));
        const allstoreprinters = await this.storePrintersServiceDb.use('GA_DATA', { filter: { "type": "0", "status": 1 }, skip: 0, limit: 100 })
        if (allstoreprinters?.length > 0) {
          const printerItemsArray = []
          allstoreprinters.forEach(async element => {
            const printr_data = element
            let needtoPrint = 0;
            const currentUser = JSON.parse(localStorage.getItem('currentUser'))

            const change_order_details = [];
            cart.forEach(async item => {
              if (item.kitchen_department_store_id != 0) {
                if (printr_data.kitchen_department_ids.includes(item.kitchen_department_store_id)) {
                  needtoPrint = 1;
                  item.order_detail_add_add_ons = item.selectedAddOns;
                  item.order_detail_remove_add_ons = item.selectedRemoveAddOns;
                  change_order_details.push(item);
                }
              } else {
                printerItemsArray.push(item)
              }
            });
            if (needtoPrint == 1 && printr_data.type == 0) {

              if (appConfig.buildCreateFor != 'web') {
                const storeData = this.storeBrand(cart)

                let IP = printr_data.ip_address;
                let Port = Number(printr_data.port);
                let printerType = printr_data.printer_type;

                if (printr_data.printer_type == 'Other Printer' || printr_data.printer_type == 0) {
                  printr_data.printer_type = 0
                } else if (printr_data.printer_type == 'Sunmi Printer' || printr_data.printer_type == 1) {
                  printr_data.printer_type = 1
                } else {
                  printr_data.printer_type = 2
                }



                this.kotNativeObject.receipt_template = "0"
                let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
                if (permissions?.length > 0) {
                  let check = permissions.find(x => x.key_ == 'receipt_template_layout');
                  if (check) {
                    if (check.status == 1 && check.value_ == '1') {
                      this.kotNativeObject.receipt_template = "1";
                    } else if (check.status == 1 && check.value_ == '2') {
                      this.kotNativeObject.receipt_template = "2";
                    }
                  }
                }

                this.kotNativeObject.kot_store_logo_permission = this.checkStoreTemplatePermission('kot_store_logo');
                this.kotNativeObject.kot_store_logo_base_64 = storeData.store_logo;
                this.kotNativeObject.kot_heading = 'KOT';
                this.kotNativeObject.kot_store_name_permission = this.checkStoreTemplatePermission('kot_store_name');
                this.kotNativeObject.kot_store_name = storeData.store_name;
                this.kotNativeObject.kot_store_address_permission = this.checkStoreTemplatePermission('kot_store_address');
                this.kotNativeObject.kot_store_address = storeData.store_address;
                this.kotNativeObject.kot_store_phone_permission = this.checkStoreTemplatePermission('kot_store_phone');
                this.kotNativeObject.kot_store_phone = this.store_detail.phone;
                this.kotNativeObject.clerk_id = currentUser.id;
                this.kotNativeObject.store_id = this.store_detail?.id
                this.kotNativeObject.terminal_id = localStorage.getItem('terminal_id');
                this.kotNativeObject.kot_customer_name_permission = this.checkStoreTemplatePermission('kot_customer_name');
                if (this.store_detail.default_customer.phone == customer.customer_phone) {
                  this.kotNativeObject.kot_customer_name = "N/A";
                } else {
                  this.kotNativeObject.kot_customer_name = this.truncateText(customer.display_name, 18)
                }
                this.kotNativeObject.kot_customer_phone_permission = this.checkStoreTemplatePermission('kot_customer_name');
                if (this.store_detail.default_customer.phone == customer.customer_phone) {
                  this.kotNativeObject.kot_customer_phone = "N/A";
                } else {
                  this.kotNativeObject.kot_customer_phone = customer.phone;
                }
                this.kotNativeObject.tender_profile_name = this.truncateText(tenderProfile.name, 14)
                this.kotNativeObject.dt = this.orderData.created_at_formated
                this.kotNativeObject.kot_customer_table_no_permission = this.checkStoreTemplatePermission('kot_customer_table_no');
                this.kotNativeObject.table_id = tenderProfile.table.id
                if (tenderProfile.table.id != 0) {
                  this.kotNativeObject.no_of_persons = tenderProfile.table.numberofguest;
                  this.kotNativeObject.capacity = tenderProfile.table.capacity;
                } else {
                  delete this.kotNativeObject.no_of_persons;
                  delete this.kotNativeObject.capacity;
                }
                this.kotNativeObject.table_name = tenderProfile.table.title;
                this.kotNativeObject.table = tenderProfile.table.title;
                this.kotNativeObject.order_details = change_order_details;
                const printerSettingsJsonObject = {
                  "printerType": printr_data.connectivity_type,
                  "ip_address": printr_data.ip_address,
                  "port": Number(printr_data.port),
                  "printer_name": printr_data.name,
                  "printer_brand": printr_data.printer_type
                }
                // Epson.epson({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                // console.log('epson5', ({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject }));
                if (appConfig.buildCreateFor == "ios") {
                  // const EscPosPrinter = registerPlugin<EscPosPlugin>('EscPos');
                  EscPos.escpos({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                  console.log('escpos', ({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject }));
                } else {
                  console.log('android')
                  if (printerType == 0) {
                    // const EscPosOtherPrinter = registerPlugin<EscPosPlugin>('EscPos');
                    EscPos.escpos({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                    console.log('escpos', ({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject }));
                  } else if (printerType == 1) {
                    // const SunmiPrinter = registerPlugin<SunmiPlugin>('Sunmi');
                    Sunmi.sunmi({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                    console.log('sunmi', ({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject }));
                  } else {
                    // const EpsonPrinter = registerPlugin<EpsonPlugin>('Epson');
                    Epson.epson({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject })
                    console.log('epson', ({ templateType: this.kotNativeObject.receipt_template, printType: "3", dataJsonObject: this.kotNativeObject, titlesJsonObject: this.nativetitleJson(), printerSettingsJsonObject: printerSettingsJsonObject }));
                  }
                }
              } else {
                let body = new FormData();
                body.append('htmlBase64', encode(this.addExtraHtml(this.printPosPageKotHtml(customer, tenderProfile, change_order_details), printr_data.width)));
                body.append('printerName', printr_data.name);
                this.http.post(printr_data.local_computer_host + 'printFromHTML', body).subscribe(res => {
                  let printData: any
                  printData = res
                  if (printData.status == true) {
                  } else {
                    this.toastr.warning(printData.message)
                  }
                }, (error) => {
                  if (error.status === 404) {
                    this.toastr.warning(this.translate.instant('Please check printer configuration'))
                  } else {
                    this.toastr.warning(this.translate.instant('Please check printer configuration'))
                  }
                });
              }
            }
          });
          const uniqueItems = printerItemsArray.filter((item, index, self) =>
            index === self.findIndex((t) => (
              t.id === item.id
            ))
          );
          if (uniqueItems.length != 0) {
            this.printKotInReceiptPrinter(uniqueItems)
          }
        } else {
          if (appConfig.buildCreateFor == 'web') {
            this.zone.runOutsideAngular(async () => {
              (window as any).process = process;
              (window as any)['global'] = window;
              let printContentsKot, popupWinKot;
              printContentsKot = await this.printPosPageKotHtml(customer, tenderProfile, cart);
              popupWinKot = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
              popupWinKot.document.open();
              popupWinKot.document.write(`
                      <html>
                        <head>
                          <title>Print tab</title>
                          <link rel="stylesheet" type="text/css" href="assets/styles/print.css">
                          <style>
                          //........Customized style.......
                          </style>
                        </head>
                    <body onload="window.print();window.close()">${printContentsKot}</body>
                      </html>`
              );
              popupWinKot.document.close();
            });
          }
        }

      });
    } catch (error) {
      console.error(error); // Handle the error as needed
    }
  }

  convertToUpperCase(value) {
    return value.toUpperCase();
  }

  printPosPageKotHtml(customer, tenderProfile, cart) {
    let kot_template = 0
    let permissions = JSON.parse(localStorage.getItem('storeReceiptData'));
    if (permissions?.length > 0) {
      let check = permissions.find(x => x.key_ == 'receipt_template_layout');
      if (check) {
        if (check.status == 1 && check.value_ == '1') {
          kot_template = 1;
        } else if (check.status == 1 && check.value_ == '2') {
          kot_template = 2;
        }
      }
    }
    this.store_detail = JSON.parse(localStorage.getItem('store_detail'));
    let kothtml = '';
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))



    let powered_by = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; padding-top:15px; color:#000000; line-height:20px;">
    `+ 'Powered by Limerr' + `</td></tr>`

    let logo = ''
    if (this.checkStoreTemplatePermission('kot_store_logo') == true) {
      logo = `<tr>
      <td align="center" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td align="center" valign="top"><img src="`+ this.store_detail.logo + `" style="display:block;margin:auto;width:auto; height:60px;" width="auto" height="60px"></td>
          </tr>
        </table>
      </td>
    </tr>`
    }

    if (tenderProfile.table_id != 0 && this.checkStoreTemplatePermission('kot_customer_table_no') == true) {
      var tender_profile = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; padding-top:5px; font-size:18px; font-weight:bold; color:#000000; line-height:20px;">
      `+ this.convertToUpperCase(tenderProfile.name) + ` : ` + tenderProfile.table.title + ` </td></tr>`
    } else {
      var tender_profile = `<tr><td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; padding-top:5px; font-size:18px; font-weight:bold; color:#000000; line-height:20px;">
      `+ this.convertToUpperCase(tenderProfile.name) + ` </td></tr>`
    }

    let store = ''
    let store_name = ''
    let store_address = ''

    if (this.checkStoreTemplatePermission('kot_store_name') == true || this.checkStoreTemplatePermission('kot_store_address') == true) {

      if (this.checkStoreTemplatePermission('kot_store_name') == true) {
        store_name += `<tr>
              <td align="center" valign="top" 
                style="font-family:Arial, Helvetica, sans-serif; font-size:14px; text-align:center; font-weight:bold; color:#000000; line-height:20px; width:100%;">
                `+ this.store_detail.name + `</td>
            </tr>`
      }

      if (this.checkStoreTemplatePermission('kot_store_address') == true) {
        store_address += `<tr>
        <td align="center" valign="top" style="font-family:Arial, Helvetica, sans-serif; font-size:12px; text-align:center; font-weight:normal; color:#000000; line-height:20px; width:100%; padding-bottom:10px">
          `+ this.store_detail.address + `</td>
      </tr>`
      }

      store += `<tr>
      <td align="center" valign="top" width="100%">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center">
        `+ store_name + `
        `+ store_address + `
        </table>
        </td>
      </tr>`
    }


    let created_at_formated_text = ''
    let created_by_text = ''
    let invoice_customer_name_text = ''
    let kot_customer_phone_text = ''
    let store_phone_text = ''
    let licence_text = ''
    let note_text = ''
    let no_of_person_text = ''
    let table_text = ''
    let qty_text = ''
    let item_text = ''

    if (kot_template == 2) {
      created_at_formated_text = `<span style="display: inline-block;">` + this.translate.instant('Dt') + ` / ` + this.translate.instant('د.ت') + `</span>` + `: `;
      created_by_text = `<span style="display: inline-block;">` + this.translate.instant('Clerk') + ` / ` + this.translate.instant('موظف') + `</span>`
      invoice_customer_name_text = `<span style="display: inline-block;">` + this.translate.instant('Name') + ` / ` + this.translate.instant('اسم') + `</span>`;
      no_of_person_text = `<span style="display: inline-block;">` + this.translate.instant('No. of Persons') + ` / ` + this.translate.instant('هاتف') + `</span>`;
      licence_text = `<span style="display: inline-block;">` + this.translate.instant('Licence Plate') + ` / ` + this.translate.instant('لوحة معدنية') + `</span>`;
      kot_customer_phone_text = `<span style="display: inline-block;">` + this.translate.instant('Cust Ph') + ` / ` + this.translate.instant('هاتف') + `</span>`;
      store_phone_text = `<span style="display: inline-block;">` + this.translate.instant('Store Ph') + ` / ` + this.translate.instant('هاتف') + `</span>`;
      note_text = `<span style="display: inline-block;">` + this.translate.instant('Note') + ` / ` + this.translate.instant('ملحوظة') + `</span>`;
      table_text = `<span style="display: inline-block;">` + this.translate.instant('Table') + ` / ` + this.translate.instant('طاولة') + `</span>`;
      qty_text = `<span style="display: inline-block;">` + this.translate.instant('Qty') + ` / ` + this.translate.instant('الكمية') + `</span>`;
      item_text = `<span style="display: inline-block;">` + this.translate.instant('Item') + ` / ` + this.translate.instant('غرض') + `</span>`;
    } else if (kot_template == 0) {
      created_at_formated_text = this.translate.instant('Dt') + `: `;
      created_by_text = this.translate.instant('Clerk') + `: #`;
      licence_text = this.translate.instant('Licence Plate');
      no_of_person_text = this.translate.instant('No. of Persons');
      invoice_customer_name_text = this.translate.instant('Name');
      kot_customer_phone_text = this.translate.instant('Cust Ph');
      store_phone_text = this.translate.instant('Store Ph');
      note_text = this.translate.instant('Note');
      table_text = this.translate.instant('Table');
      qty_text = this.translate.instant('Qty');
      item_text = this.translate.instant('Item');
    } else if (kot_template == 1) {
      created_at_formated_text = this.translate.instant('د.ت') + `: `;
      created_by_text = this.translate.instant('موظف') + `: #`;
      licence_text = this.translate.instant('لوحة معدنية');
      no_of_person_text = this.translate.instant('عدد الأشخاص');
      invoice_customer_name_text = this.translate.instant('اسم');
      kot_customer_phone_text = this.translate.instant('هاتف');
      store_phone_text = this.translate.instant('هاتف');
      note_text = this.translate.instant('ملحوظة');
      table_text = this.translate.instant('طاولة');
      qty_text = this.translate.instant('الكمية');
      item_text = this.translate.instant('غرض');
    }




    const printOrderData = [];

    if (this.checkStoreTemplatePermission('kot_store_phone') == true) {
      printOrderData.push({ "priority": 0, "data": store_phone_text + `: ` + this.store_detail.phone });
    }
    printOrderData.push({ "priority": 0, "data": created_by_text + `: # ` + currentUser.id });

    if (this.checkStoreTemplatePermission('kot_customer_name') == true) {
      printOrderData.push({ "priority": 0, "data": invoice_customer_name_text + `: ` + customer.display_name });
    }
    if (this.checkStoreTemplatePermission('kot_customer_phone') == true) {
      printOrderData.push({ "priority": 0, "data": kot_customer_phone_text + `: ` + customer.phone });
    }
    // printOrderData.push({ "priority": 0, "data": this.translate.instant('Profile') + `: ` + tenderProfile.name });

    printOrderData.push({ "priority": 0, "data": created_at_formated_text + `: ` + this.transformDate(new Date()) });



    let printOrderDatatext = '';
    if (printOrderData.length > 0) {
      let isLeft = 1;
      printOrderDatatext += `<tr>
      <td align="left" valign="top">
        <table class="table-transaction-info" width="100%" border="0" cellspacing="0" cellpadding="0">`;
      printOrderData.forEach(element => {
        let style = 'font-family:Arial, Helvetica, sans-serif; font-size:11px; font-weight:normal; color:#000000; line-height:16px;';
        if (element.priority == 1) {
          style = 'font-family:Arial, Helvetica, sans-serif; font-size:14px; font-weight:bold; color:#000000; line-height:16px;'
        }
        if (isLeft == 1) {
          printOrderDatatext += `<tr>
          <td align="left"
            style="`+ style + `">
            `+ element.data + `</td>`
          isLeft = 0
        } else {
          printOrderDatatext += `<td align="right"
              style="`+ style + `">
              `+ element.data + `</td>
          </tr>`
          isLeft = 1
        }
      });
      if (isLeft == 0) {
        printOrderDatatext += `</tr>`;
      }
      printOrderDatatext += `</table>
      </td>
    </tr>`;
    }
    let orderItems = '';
    if (cart.length > 0) {
      orderItems += `<tr>
      <th
        style="font-size:12px; line-height:14px; width:60px; text-align:left; border-top:1px solid #222222; border-bottom:1px solid #222222;">
        `+ qty_text + `</th>
      <th align="left"
        style="font-size:12px; line-height:14px; border-top:1px solid #222222; border-bottom:1px solid #222222;">
        `+ item_text + `
      </th>
    </tr>`

      cart.forEach(item => {
        let add_addons_detail = ''
        if (item?.order_detail_add_add_ons) {
          add_addons_detail += `<span>`
          item?.order_detail_add_add_ons.forEach(addon => {
            let addon_name_text = ''
            if (!(addon.other_lang_name != undefined && addon.other_lang_name != null && addon.other_lang_name != "")) {
              addon.other_lang_name
            } else {
              addon.other_lang_name = "";
            }
            if (kot_template == 2) {
              addon_name_text = addon.name + ` / ` + addon.other_lang_name
            } else if (kot_template == 0) {
              addon_name_text = addon.name
            } else if (kot_template == 1) {
              addon_name_text = addon.other_lang_name
            }

            add_addons_detail += `<span style="display: block; font-size: 14px;" >
          <font style="font-size:10px; font-weight: normal;">+</font>&nbsp;
          `+ addon_name_text + ` </span>`;
          });
          add_addons_detail += `</span>`
        }
        let remove_addons_detail = ''
        if (item?.order_detail_remove_add_ons) {
          remove_addons_detail += `<span>`
          item?.order_detail_remove_add_ons.forEach(remove => {
            let remove_addons_name_text = ''
            if (remove.other_lang_name != undefined && remove.other_lang_name != null && remove.other_lang_name != "") {
              remove.other_lang_name
            } else {
              remove.other_lang_name = ""
            }
            if (kot_template == 2) {
              remove_addons_name_text = remove.name + ` / ` + remove.other_lang_name
            } else if (kot_template == 0) {
              remove_addons_name_text = remove.name
            } else if (kot_template == 1) {
              remove_addons_name_text = remove.other_lang_name
            }

            remove_addons_detail += `<span style="display: block; font-size: 14px;">
          <font style="font-size: 10px; font-weight: normal;">-</font>&nbsp;
          `+ remove_addons_name_text + `</span>`
          });
          remove_addons_detail += `</span>`
        }

        let pricelookup_name_text = ''
        let item_note_text = ''
        if (kot_template == 2) {
          pricelookup_name_text = item.pricelookup_name + ` / ` + item.display_name_language2
          item_note_text = `<span style="display: inline-block;">` + this.translate.instant('Notes') + `. / ` + this.translate.instant('ملحوظات') + `</span>` + ` : `;
        } else if (kot_template == 0) {
          pricelookup_name_text = item.pricelookup_name;
          item_note_text = this.translate.instant('Notes');
        } else if (kot_template == 1) {
          pricelookup_name_text = item.display_name_language2;
          item_note_text = this.translate.instant('ملحوظات');
        }

        let notes = '';
        if (item.note != "" && item.note != "undefined" && item.note != "null" && item.note != undefined && item.note != null) {
          notes = `<span style="display: block;font-weight: bold;">` + item_note_text + ` :</font>&nbsp;` + item.note + `</span>`
        }

        orderItems += `<tr>
            <td style="vertical-align: top;"><span
            style="font-weight: normal; font-size:14px; line-height:15px;">`+ item.pricelookup_qty + `</span></td>
            <td style="vertical-align: top;"><span
            style="font-weight: normal; font-size:14px; line-height:15px;">`+ pricelookup_name_text + `</span> 
            `+ add_addons_detail + remove_addons_detail + notes + `
            </td>
          </tr>`;
      });
      orderItems += `<tr>
      <td colspan="4" style="border-bottom:1px solid #222222;"></td>
    </tr>`
    }

    kothtml += `<div class="invoice-pos">
    <h2>`+ this.translate.instant('KOT') + `</h2>
    <table class="table-transaction-info" align="center" width="100%" border="0" cellspacing="0" cellpadding="0"
      style="box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box;">
      `+ logo + `
      `+ tender_profile + `
      `+ store + `   
    </table>
    <table class="table-transaction-info" width="100%" border="0" cellspacing="0" cellpadding="0">
      `+ printOrderDatatext + `
    </table>
    <table class="table-items-list" width="100%" border="0" cellspacing="0" cellpadding="0">
      `+ orderItems + `
    </table>
    <table class="table-transaction-info" width="100%" border="0" cellspacing="0" cellpadding="0">
    `+ powered_by + `
  </table>
  
  </div>`;

    return kothtml;
  }

  nativetitleJson() {
    return {
      "trans_no": "Trans. No.",
      "trans_no_locale": "رقم المعاملة",
      "order_no": "Invoice No.",
      "order_no_locale": "رقم الطلب",
      "live_ref_no": "Live Ref. No.",
      "live_ref_no_locale": "رقم المرجع المباشر .",
      "store_ph_colon": "Store Ph:",
      "store_ph_colon_locale": "مخزن الرقم الهيدروجيني:",
      "store_id_colon": "Store Id:",
      "store_id_colon_locale": "معرف المتجر:",
      "terminal_colon": "Terminal:",
      "terminal_colon_locale": "صالة:",
      "clerk_colon": "Clerk:",
      "clerk_colon_locale": "موظف:",
      "dt_colon": "Dt:",
      "dt_colon_locale": "دت:",
      "no_of_persons_colon": "No. of Person:",
      "no_of_persons_colon_locale": "رقم الشخص:",
      "name_colon": "Name:",
      "name_colon_locale": "اسم:",
      "phone_colon": "Phone:",
      "phone_colon_locale": "هاتف:",
      "license_plate_colon": "License Plate:",
      "license_plate_colon_locale": "لوحة الترخيص:",
      "order_type_colon": "Order Type:",
      "order_type_colon_locale": "نوع الطلب:",
      "table_colon": "Table:",
      "table_colon_locale": "طاولة:",
      "profile_colon": "Profile:",
      "profile_colon_locale": "حساب تعريفي:",
      "payment_colon": "Payment:",
      "payment_colon_locale": "قسط:",
      "address_colon": "Address:",
      "address_colon_locale": "عنوان:",
      "note_colon": "Note:",
      "note_colon_locale": "ملحوظة:",
      "qty": "Qty",
      "qty_locale": "الكمية",
      "item": "Item",
      "item_locale": "غرض",
      "price": "Price",
      "price_locale": "سعر",
      "notes_colon": "Notes:",
      "notes_colon_locale": "ملحوظات:",
      "sub_total": "Sub Total",
      "sub_total_locale": "المجموع الفرعي",
      "round_off": "Round Off",
      "round_off_locale": "نهاية الجولة",
      "coupon": "Coupon",
      "coupon_locale": "قسيمة",
      "applied": "Applied",
      "applied_locale": "مُطبَّق",
      "delivery_charge": "Delivery Charge",
      "delivery_charge_locale": "رسوم التوصيل",
      "total_amount": "Total Amount",
      "total_amount_locale": "المبلغ الإجمالي",
      "total_excluding_tax": "Total Excluding Tax",
      "total_excluding_tax_locale": "الإجمالي باستثناء الضريبة",
      "applicable_tax": "Applicable Tax",
      "applicable_tax_locale": "الضريبة المطبقة",
      "gross_total": "Gross Total",
      "gross_total_locale": "المجموع الكلي",
      "change_colon": "Change:",
      "change_colon_locale": "التغير:",
      "tax_no_colon": "Tax No.:",
      "tax_no_colon_locale": "لا تفرض ضرائب.:",
      "phone_no_colon": "Phone No.:",
      "phone_no_colon_locale": "رقم الهاتف.:",
      "date_time_colon": "DateTime:",
      "date_time_colon_locale": "التاريخ والوقت:",
      "workstation": "Workstation",
      "workstation_locale": "محطة العمل",
      "trans_date": "Trans. Date",
      "trans_date_locale": " تاريخ المعاملة",
      "todays_event_colon": "Today's Event:",
      "todays_event_colon_locale": "حدث اليوم:",
      "total_proceeds": "Total Proceeds",
      "total_proceeds_locale": "إجمالي العائدات",
      "total_discounts": "Total Discounts",
      "total_discounts_locale": "إجمالي الخصومات",
      "total_adjustments": "Total Adjustments",
      "total_adjustments_locale": "إجمالي التعديلات",
      "adjustment_proceeds": "Adjustment Proceeds",
      "adjustment_proceeds_locale": "تعديل العائدات ",
      "opening_cash": "Opening Cash",
      "opening_cash_locale": "فتح النقدية",
      "total_transaction_flow": "Total Transaction Flow",
      "total_transaction_flow_locale": "إجمالي تدفق المعاملات",
      "paid": "PAID",
      "paid_locale": "مدفوع",
      "by": "by",
      "by_locale": "بواسطة",
      "total_paid_outs": "Total Paid-outs",
      "total_paid_outs_locale": "إجمالي المبالغ المدفوعة",
      "tender_type": "Tender Type",
      "tender_type_locale": "نوع العطاء",
      "closing_cash": "Closing Cash",
      "closing_cash_locale": "إغلاق النقدية",
      "closing_change_fund": "Closing Change Fund",
      "closing_change_fund_locale": "إغلاق صندوق التغيير",
      "closing_managers_fund": "Closing Managers Fund",
      "closing_managers_fund_locale": "إغلاق صندوق المديرين",
      "total_cash_drop": "Total Cash Drop",
      "total_cash_drop_locale": "إجمالي الانخفاض النقدي",
      "required_cash": "Required Cash",
      "required_cash_locale": "النقدية المطلوبة",
      "actual_drawer_cash": "Actual Drawer Cash",
      "actual_drawer_cash_locale": "درج النقدية الفعلية",
      "variance": "VARIANCE",
      "variance_locale": "التباين",
      "total_cash_order": "Total Cash Order",
      "total_cash_order_locale": "إجمالي الطلب النقدي",
      "service_charges": "Service Charges",
      "service_charges_locale": "رسوم الخدمات",
      "net_through": "Net Through",
      "net_through_locale": "صافي من خلال",
      "sales_including_tax": "Sales (Including tax)",
      "sales_including_tax_locale": "المبيعات (شاملة الضريبة)",
      "tax": "Tax",
      "tax_locale": "ضريبة",
      "total_orders_colon": "Total Orders:",
      "total_orders_colon_locale": "إجمالي الطلبات:",
      "total_discount": "Total Discount",
      "total_discount_locale": "إجمالي الخصم",
      "orders": "Orders",
      "orders_locale": "طلبات",
      "order_items": "Order Items",
      "order_items_locale": "طلب بضاعة",
      "system_amount": "System Amount",
      "system_amount_locale": "مبلغ النظام",
      "overridden_amount": "Overridden Amount",
      "overridden_amount_locale": "المبلغ المتجاوز",
      "clerk_name_colon": "Clerk name:",
      "clerk_name_colon_locale": "اسم الكاتب:",
      "time_colon": "Time:",
      "time_colon_locale": "وقت:",
      "clerk_id_colon": "Clerk Id:",
      "clerk_id_colon_locale": "معرف الكاتب:",
      "cash_drop": "Cash Drop",
      "cash_drop_locale": "إسقاط النقدية",
      "hash_orders": "#Orders",
      "hash_orders_locale": "#طلبات",
      "hash_order_items": "#Order Items",
      "hash_order_items_locale": "#طلب بضاعة",
      "reason_drawer": "Reason for opening drawer -",
      "reason_drawer_locale": "سبب فتح الدرج -"
    }
  }

}
