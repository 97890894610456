import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, catchError, of, timeout } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternetCheckService {
  private internetStatus$ = new BehaviorSubject<boolean>(false);
  private intervalId: any;

  constructor(private http: HttpClient, private zone: NgZone) {
    this.startMonitoring();
  }

  private startMonitoring(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId); // 🔄 Prevent multiple intervals
    }

    this.intervalId = setInterval(async () => {
      // console.log('🔄 Checking Internet...');
      await this.checkInternet();
      // console.log('✅ Internet check completed');
    }, 7000); // ✅ Runs every 7 seconds
  }

  private async checkInternet(): Promise<boolean> {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 4000); // Timeout after 4 seconds

    try {
      const response = await Promise.race([
        fetch('https://www.gstatic.com/generate_204', { mode: 'no-cors', signal: controller.signal }),
        new Promise<Response>((_, reject) => setTimeout(() => reject(new Error('Timeout')), 4000))
      ]);

      clearTimeout(timeoutId); // Clear timeout if request succeeds
      // console.log('response', response)
      const isOnline = response && 'ok' in response; // Ensure response is defined
      // console.log(isOnline ? '✅ Online' : '❌ Offline');

      this.zone.run(() => this.internetStatus$.next(isOnline));
      return isOnline;
    } catch (error) {
      console.log('⏳ Timeout or request failed → Setting status to ❌ Offline');
      this.zone.run(() => this.internetStatus$.next(false));
      return false;
    }
  }

  public getInternetStatus(): BehaviorSubject<boolean> {
    return this.internetStatus$;
  }
  
}

