import { Component, NgZone, TemplateRef, ViewChild, EventEmitter, Output, Injector, Inject } from '@angular/core';
import { IonContent, Platform } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token, } from '@capacitor/push-notifications';
// import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { appConfig } from './app.config';
import { DOCUMENT, Location } from '@angular/common';
// import { Network } from '@capacitor/network';
// import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
// import { environment } from 'src/environments/environment';
import { Keyboard } from '@capacitor/keyboard';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authservices/authentication.service';
import { WebSqlService } from './services/web-sql.service';
import { PassingDataService } from './services/passing-data.service';
import { PosService } from './services/pos.service';
import { StoreTablesService } from './database/store-tables.service';
import { SharedDataService } from './database/shared-data.service';
import { OrdersService } from './database/orders.service';
import { DataServicesService } from './services/data-services.service';
import { Network } from '@capacitor/network';

// import { StatusBar, Style } from '@capacitor/status-bar';
// import { SocketService } from './services/socket.service';
// import { registerPlugin } from '@capacitor/core';

// export interface JavaSocketPlugin {
//   getMessage(): Promise<{ value: string }>;
//   setIpAddress(options: {}): Promise<{}>;
//   startClientSocket(): Promise<{ value: string }>;
// }
// const JavaSocket = registerPlugin<JavaSocketPlugin>('JavaSocket');

// export default JavaSocket;

// export interface SocketIOPlugin {
//   getMessage(): Promise<{ value: string }>;
//   startClientServer(): Promise<{ value: string }>;
//   setIpAddress(options: {}): Promise<{}>;
// }
// const SocketIO = registerPlugin<SocketIOPlugin>('SocketIO');

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  // private initPlugin: boolean;
  modalRef: BsModalRef;
  private spinnerCount = 0;
  private syncspinnerCount = 0;
  // private endofdayspinnerCount = 0;
  // private endofdayspinnerafterCount = 0;
  currentPage: string;
  isOnline$: Observable<boolean>;
  isPlatform: boolean = false;
  currentNumber = '0';
  textboxname;
  user_id_to_change = 0;
  user: any = {}
  waitForSecondNumber = false;
  // private statusSubject = new BehaviorSubject<boolean>(false);
  is_login_with_pin: any = 0;
  permissionList = []
  from = "";
  checkoutList = [];
  userList = []
  private queue: (() => Promise<any>)[] = [];
  private isProcessing: boolean = false;
  checkDataLiveReloadCalled: any
  checkTableDataLiveReloadCalled: any
  statusBarHeight: any;
  internetStatus: any = "Online";
  @ViewChild(IonContent, { static: true }) content: IonContent;
  @ViewChild('clockModel') clockModel: TemplateRef<any>;
  @Output() modalResult: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private spinner: NgxSpinnerService,
    // private location: Location,
    // private router: Router,
    public _dataService: DataServicesService,
    // private modalService: BsModalService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private platform: Platform,
    // private authenticationService: AuthenticationService,
    private injector: Injector,
    private passData: PassingDataService,
    private _posService: PosService,
    public storeTablesServiceDb: StoreTablesService,
    public sharedDataService: SharedDataService,
    public ordersServiceDb: OrdersService,
    @Inject(DOCUMENT) public document: Document,
    private zone: NgZone
    // private socketService: SocketService
  ) {
    sharedDataService.setAllData()
    let browserLang = localStorage.getItem('language');
    let htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    this.translate.use(browserLang);
    if (browserLang == "arabic") {
      htmlTag.dir = 'rtl';
    } else {
      htmlTag.dir = 'ltr';
    }
    this.startDBChanges();
  }


  // safeArea() {
  //   SafeArea.getSafeAreaInsets().then(({ insets }) => {
  //     console.log('insets111',insets);
  //     let statusBarHeight = insets.top;
  //     if (statusBarHeight === 24) {
  //       statusBarHeight = 48;
  //     }
  //     insets.top = statusBarHeight
  //     console.log('insets222',insets);
  //   });
  // }

  stopDBChanges() {
    this.checkDataLiveReloadCalled = null
    this.checkTableDataLiveReloadCalled = null
  }

  startDBChanges() {
    if (!this.checkDataLiveReloadCalled) {
      this.checkDataLiveReloadCalled = this.ordersServiceDb.initChanges();
    }
    if (!this.checkTableDataLiveReloadCalled) {
      this.checkTableDataLiveReloadCalled = this.storeTablesServiceDb.initChanges();
    }
  }

  async spinnerActivate(checkspinner = 'spinner') {
    if (checkspinner == "spinner") {
      this.spinnerCount++;
    } else if (checkspinner == "syncspinner") {
      this.syncspinnerCount++;
    }
    this.spinner.show(checkspinner);
  }

  async spinnerDeactivate(checkspinner = 'spinner') {

    if (checkspinner == "spinner") {
      if (this.spinnerCount > 0) {
        this.spinnerCount--;
        if (this.spinnerCount == 0) {
          this.spinner.hide(checkspinner);
        }
      }
    }
    if (checkspinner == "syncspinner") {
      if (this.syncspinnerCount > 0) {
        this.syncspinnerCount--;
        if (this.syncspinnerCount == 0) {
          this.spinner.hide(checkspinner);
        }
      }
    }
    // if (checkspinner == "endofdayspinner") {
    //   if (this.endofdayspinnerCount > 0) {
    //     this.endofdayspinnerCount--;
    //     if (this.endofdayspinnerCount == 0) {
    //       this.spinner.hide(checkspinner);
    //     }
    //   }
    // }
    // if (checkspinner == "endofdayspinnerafter") {
    //   if (this.endofdayspinnerafterCount > 0) {
    //     this.endofdayspinnerafterCount--;
    //     if (this.endofdayspinnerafterCount == 0) {
    //       this.spinner.hide(checkspinner);
    //     }
    //   }
    // }
  }

  async ngOnInit() {
    this.notificationSyncBgOrder()
    this.platform.ready().then(async () => {
      if (appConfig.buildCreateFor === "ios") {
        const isConnected = await this.internetCheck(); // Check internet connectivity
        let token = JSON.parse(localStorage.getItem('token'));
        let store_detail = JSON.parse(localStorage.getItem('store_detail'));
        if (store_detail?.is_store_has_captain_user == 1) {
          console.log('App cold start detected on iOS');
          const internet = isConnected ? 'Online' : 'Offline';
          if (internet === 'Online' && token) {
            this.callBgOrder()
          }
        }
      }
      // if(appConfig.buildCreateFor == "ios") {
      //   const isConnected = await this.internetCheck(); // Get boolean result directly
      //   let token = JSON.parse(localStorage.getItem('token'));
      //   const sqlService = this.injector.get(WebSqlService);
      //   console.log('App cold start detected on iOS');
      //   if (isConnected && token) { // If the internet is online
      //       this.callBgOrder();
      //     // await sqlService.ordersServiceDb.use('DROP_ADD', [])
      //     // await sqlService.storeTablesServiceDb.use('DROP_ADD', [])
      //     // await sqlService.storeTables1(1);
      //     // await sqlService.syncfromMobileOrdersWhenAppKillOrMinimize(1);
      //     console.log("Websql data added..!");
      //   }
      // }
    });
  }

  ngAfterViewInit() {
    if (appConfig.buildCreateFor != "web") {
      Keyboard.addListener('keyboardWillShow', () => {
        this.content.scrollToPoint(0, 200, 300);
      });

      Keyboard.addListener('keyboardWillHide', () => {
        this.content.scrollToPoint(0, 0, 300);
      });
    }
  }

  getNotSyncOrder() {
    return new Promise(async resolve => {
      const orders = await this.ordersServiceDb.use('GA_DATA', { filter: { isSync: 0 }, skip: 0 })
      resolve(orders.length)
    });
  }
  
  callBgOrder() {
    PushNotifications.getDeliveredNotifications().then(async x => {
      console.log('x.notifications.length',x.notifications.length)
      // if (x.notifications.length > 0) {
        const sqlService = this.injector.get(WebSqlService);
        let notSyncOrders = await this.getNotSyncOrder(); // Check unsynced orders
        if (typeof notSyncOrders === 'number' && notSyncOrders > 0) {
          await sqlService.syncOrderFromAfterKill(); // Sync orders
        } else {
          sqlService.syncBGOrder()
          PushNotifications.removeAllDeliveredNotifications();
        }
      // }
    })
  }
  // callBgOrderForTapOnNotification() {
  //   PushNotifications.getDeliveredNotifications().then(async x => {
  //     if (x.notifications.length == 0) {
  //       const sqlService = this.injector.get(WebSqlService);
  //       await sqlService.syncBGOrder()
  //       PushNotifications.removeAllDeliveredNotifications();
  //     }
  //   })
  // }

  pushnotificationApp() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        localStorage.setItem('fcm_token', '0')
        // Show some error
      }
    });

    // PushNotifications.addListener('registration', (token: Token) => {
    //   alert('Push registration success, token: ' + token.value);
    //   console.error('Push registration success, token:', token.value);
    // });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error))
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification))
        await this.enqueue(async () => {
          await this.notificationget(notification);
        });
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification))
      }
    );

    PushNotifications.createChannel({
      id: 'background-channel',
      name: 'Background Notifications',
      description: 'Channel for background notifications',
      importance: 1, // Adjust importance level as needed
      visibility: 1, // Visibility options
    });

  }

  listenForMessages() {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.addEventListener('message', async (event) => {
        var notificationData = event.data
        await this.enqueue(async () => {
          await this.notificationget(notificationData);
        });
      })
    }
  }

  async notificationget(notificationData) {
    console.log('notificationData---', notificationData)

    var checkisStartofDay = localStorage.getItem('isStartofDay');

    if (notificationData.data && checkisStartofDay == 'true') {
      if (notificationData.data.order_status == 3) {
        let audio = new Audio("../assets/pending_orders_sound.mp3");
        audio.play()
        this.passData.defaultRingtone(audio)
      } else {
        let audio = new Audio("../assets/new.mp3");
        audio.play();
      }

      if (notificationData.data.click_action != "table_updated") {
        // console.log('!table_updated',);
        // if (appConfig.buildCreateFor == "web") {
        //   this.toastr.success(notificationData?.notification?.title);
        // } else {
          if(notificationData?.data?.notification_type && notificationData?.data?.notification_type == "order_number_update") {
        } else {
          //this.toastr.success(notificationData.data.title); // suhas bhai says do not show toast
          }
        // }
        const sqlService = this.injector.get(WebSqlService);
        return await this._posService.getOrderDetail({ "order_id": notificationData.data.order_id }).subscribe({
          next: async (res) => {
            if (res.status == 1) {
              await this.enqueue(async () => {
                /* await */ sqlService.insertUpdateOrder(res.data, 0, 0, 0, 1, 0, 1);
              });
            } else {
              console.log("error:", res.status);
            }
          },
          error: (error) => {
            console.log(error);
          }
        });
      } else if (notificationData.data.click_action == "table_updated") {
        var table = JSON.parse(notificationData.data.data);
        // table = this.cleanObject(table);
        var merge_tables = table.merge_tables ?? [];
        var unmerge_tables = table.unmerge_tables ?? [];
        // Handle main table update
        if (table) {
          var tables = await this.storeTablesServiceDb.use('GA_DATA', { filter: { id: table.id } });

          if (tables && tables.length > 0) {
            table.pid = tables[0].pid;
            table.isSync = 1;

            // console.log('Updating main table:', table);
            this.storeTablesServiceDb.use('IU_DATA', [table]);
          } else {
            // console.log('Main table not found. Inserting new table:', table);
            this.storeTablesServiceDb.use('IU_DATA', [table]);
          }
        }

        // Handle merge tables
        if (merge_tables.length > 0) {
          merge_tables = merge_tables.split(',');

          for (var i = 0; i < merge_tables.length; i++) {
            let merge_table_id = Number(merge_tables[i]);
            var mergeTable = await this.storeTablesServiceDb.use('GA_DATA', { filter: { id: merge_table_id } });

            if (mergeTable && mergeTable.length > 0) {
              let mergedTable = mergeTable[0];
              mergedTable.pid = table.pid; // Assuming you want to use the main table's pid
              mergedTable.isSync = 1;
              mergedTable.isMerge = table.id; // Assuming you want to reference the main table's id
              // console.log('Updating merged table:', mergedTable);
              this.storeTablesServiceDb.use('IU_DATA', [mergedTable]);
            }
          }
        }
        // Handle unmerge tables
        if (unmerge_tables.length > 0) {
          unmerge_tables = unmerge_tables.split(',');
          for (var i = 0; i < unmerge_tables.length; i++) {
            let unmerge_table_id = Number(unmerge_tables[i]);
            var unmergeTable = await this.storeTablesServiceDb.use('GA_DATA', { filter: { id: unmerge_table_id } });
            if (unmergeTable && unmergeTable.length > 0) {
              let unmergedTable = unmergeTable[0];
              unmergedTable.pid = table.pid; // Assuming you want to use the main table's pid
              unmergedTable.isSync = 1;
              unmergedTable.isMerge = 0; // Assuming you want to reference the main table's id
              unmergedTable.merge_tables = 0
              // console.log('Updating unmergedTable table:', unmergedTable);
              this.storeTablesServiceDb.use('IU_DATA', [unmergedTable]);
            }
          }
        }
      }
    }

    return 0;
  }

  cleanObject(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'string') {
            // Remove extra quotes only if they're at the start and end of the string
            obj[key] = obj[key].replace(/^"|"$/g, '');
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            this.cleanObject(obj[key]); // Recursively clean nested objects
        }
    }
    return obj;
  }

  // Handle the form submission

  onfocus(name) {
    var tempData = localStorage.getItem('platform')
    if (tempData == 'web') {
      this.isPlatform = false;
    } else {
      this.isPlatform = true;
    }
    this.currentNumber = "0"
    this.textboxname = name;
  }

  public getNumber(v: string) {

    if (this.textboxname == "newusernameclockin") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.user.newusernameclockin = this.currentNumber
      }
    }
    if (this.textboxname == "newuserpasswordclockin") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.user.newuserpasswordclockin = this.currentNumber
      }
    }

  }

  remove() {
    if (this.textboxname == "newusernameclockin") {
      this.currentNumber = this.user.newusernameclockin.toString().slice(0, -1)
      this.user.newusernameclockin = this.currentNumber
    }
    if (this.textboxname == "newuserpasswordclockin") {
      this.currentNumber = this.user.newuserpasswordclockin.toString().slice(0, -1)
      this.user.newuserpasswordclockin = this.currentNumber
    }
  }

  async enqueue(fn: () => Promise<any>) {
    this.queue.push(fn);
    if (!this.isProcessing) {
      await this.processQueue();
    }
  }

  private async processQueue() {
    this.isProcessing = true;
    while (this.queue.length > 0) {
      const fn = this.queue[0];
      try {
        const result = await fn();
      } catch (error) {
        console.error(error); // Handle the error as needed
      }
      this.queue.shift();
    }
    this.isProcessing = false;
  }

  async internetCheck(): Promise<boolean> {
    const status = await Network.getStatus();
    this.internetStatus = status.connected ? 'Online' : 'Offline';
  
    // Listen for network status changes to keep `internetStatus` updated
    Network.addListener('networkStatusChange', (status) => {
      this.internetStatus = status.connected ? 'Online' : 'Offline';
      console.log('Network status changed:', this.internetStatus);
    });
  
    return status.connected; // Return true if connected, false otherwise
  }

  async notificationSyncBgOrder() {
    // console.log('call syncBgOrder');
    if (appConfig.buildCreateFor == "web") {
      await this.listenForMessages();
    }

    if (appConfig.buildCreateFor != "web") {
      var checkisStartofDay = localStorage.getItem('isStartofDay');
      console.log('isStartofDay',checkisStartofDay);
      if (checkisStartofDay === 'true' && appConfig.buildCreateFor === "android") {
        this.platform.ready().then(async () => {
          const isConnected = await this.internetCheck(); // Check internet status
          const store_detail = JSON.parse(localStorage.getItem('store_detail'));
          let token = JSON.parse(localStorage.getItem('token'));
          // store_detail.is_store_has_captain_user = 1; // static add for condition check
          if (store_detail?.is_store_has_captain_user == 1) {
            console.log('App was closed and reopened.');
            this.zone.run(async () => {
              const internet = isConnected ? 'Online' : 'Offline';
              // console.log('Internet status after check:', internet);
              if (internet === 'Online' && token) {
                this.callBgOrder(); // 
              }
            });
          }
        });
      }
      // Code is closed because in minimized mode fcm notification is coming

      this.platform.resume.subscribe(async () => {
        // App was resumed from the background
        // let internet = 'Offline'; // Default value in case of no internet connection
        // const isConnected = await this.internetCheck(); // Get boolean result directly
        // let token = JSON.parse(localStorage.getItem('token'));
        // const store_detail = JSON.parse(localStorage.getItem('store_detail'));
        // if (store_detail?.is_store_has_captain_user == 1) {
          console.log('App was resumed from the background.'); // When App Minimized
        //   this.zone.run(() => {
        //     internet = isConnected ? 'Online' : 'Offline'; // Update the variable inside the zone
        //     if (token && internet == 'Online') {
        //       this.callBgOrder(); 
        //     }
        //   })
        // }
      });
      await this.pushnotificationApp();
    }
  }

  // socket

  // async ngOnInit() {
  //   setInterval(() => {
  //     this.callNativeMethod()
  //   }, 1000);
  //   this.notificationSyncBgOrder()
  // }

  // callNativeMethod() {
  //   JavaSocket.getMessage().then(async (message: any) => {
  //     // console.log("MESSSAGEEEEEEEEE++++>>>>>>>", message)
  //     if (message.message != "") {
  //       var msg = JSON.parse(message.message)
  //       // console.log('parse message-->', typeof msg)
  //       if (msg.from_message && msg.from_message == "pos") {
  //         var parseMessage = JSON.parse(message.message)
  //         // console.log("ORDER MESAAGE ", parseMessage)
  //         var stringyfyMessage = message.message
  //         // console.log('newMessage', parseMessage);
  //         if (parseMessage.click_action != "table_updated") {
  //           if (parseMessage.store_order_id == 0) {
  //             parseMessage.store_order_id = ""
  //           }
  //           await this.enqueue(async () => {
  //             var obj = {
  //               "notification": {
  //                 "title": "Order " + parseMessage.store_order_id + " update.",
  //                 "body": "Order " + parseMessage.store_order_id + " update.",
  //               },
  //               "data": {
  //                 "store_id": parseMessage.store_id,
  //                 "title": "Order " + parseMessage.store_order_id + " update.",
  //                 "click_action": parseMessage.click_action,
  //                 "order_id": parseMessage.id,
  //                 "business_id": parseMessage.business_id
  //               }
  //             }
  //             await this.notificationget(obj, parseMessage);
  //           });
  //         }
  //         if (parseMessage.click_action == "table_updated") {
  //           await this.enqueue(async () => {
  //             var obj = {
  //               "data": {
  //                 "store_id": parseMessage.store_id,
  //                 "data": stringyfyMessage,
  //                 "title": "Table " + parseMessage.title + " is reserved",
  //                 "click_action": "table_updated",
  //                 "order_id": "0",
  //                 "business_id": parseMessage.business_id
  //               },
  //               "notification": {
  //                 "title": "Table " + parseMessage.title + " is reserved",
  //                 "body": "Table " + parseMessage.title + " is reserved",
  //                 "click_action": "table_updated"
  //               }
  //             }
  //             await this.notificationget(obj, parseMessage);
  //           });
  //         }

  //       }
  //     }
  //   }).catch((error) => {
  //     console.error('Error getting message', error);
  //   });

  //   SocketIO.getMessage().then(async (message: any) => {
  //     // console.log("MESSSAGEEEEEEEEE--SocketIO++++>>>>>>>", message)
  //     if (message.message != "") {
  //       var msg = JSON.parse(message.message)
  //       // console.log('parse message-->', typeof msg)
  //       if (msg.from_message && msg.from_message == "pos") {

  //         var parseMessage = JSON.parse(message.message)
  //         // console.log("ORDER MESAAGE ", parseMessage)
  //         var stringyfyMessage = message.message
  //         // console.log('newMessage', parseMessage);
  //         if (parseMessage.click_action != "table_updated") {
  //           if (parseMessage.store_order_id == 0) {
  //             parseMessage.store_order_id = ""
  //           }
  //           await this.enqueue(async () => {
  //             var obj = {
  //               "notification": {
  //                 "title": "Order " + parseMessage.store_order_id + " update.",
  //                 "body": "Order " + parseMessage.store_order_id + " update.",
  //               },
  //               "data": {
  //                 "store_id": parseMessage.store_id,
  //                 "title": "Order " + parseMessage.store_order_id + " update.",
  //                 "click_action": parseMessage.click_action,
  //                 "order_id": parseMessage.id,
  //                 "business_id": parseMessage.business_id
  //               }
  //             }
  //             await this.notificationget(obj, parseMessage);
  //           });
  //         }
  //         if (parseMessage.click_action == "table_updated") {
  //           await this.enqueue(async () => {
  //             var obj = {
  //               "data": {
  //                 "store_id": parseMessage.store_id,
  //                 "data": stringyfyMessage,
  //                 "title": "Table " + parseMessage.title + " is reserved",
  //                 "click_action": "table_updated",
  //                 "order_id": "0",
  //                 "business_id": parseMessage.business_id
  //               },
  //               "notification": {
  //                 "title": "Table " + parseMessage.title + " is reserved",
  //                 "body": "Table " + parseMessage.title + " is reserved",
  //                 "click_action": "table_updated"
  //               }
  //             }
  //             await this.notificationget(obj, parseMessage);
  //           });
  //         }

  //       }
  //     }
  //   }).catch((error) => {
  //     console.error('Error getting message', error);
  //   });

  // }

  // async notificationget(notificationData, parseMessage) {
  //   // console.log('notificationData---', notificationData)

  //   var checkisStartofDay = localStorage.getItem('isStartofDay');

  //   if (notificationData.data && checkisStartofDay == 'true') {
  //     // if (notificationData.data.order_status == 3) {
  //     //   let audio = new Audio("../assets/pending_orders_sound.mp3");
  //     //   audio.play()
  //     //   this.passData.defaultRingtone(audio)
  //     // } else {
  //     //   let audio = new Audio("../assets/new.mp3");
  //     //   audio.play();
  //     // }
  //     // console.log('notificationData.data.click_action',notificationData.data.click_action);
  //     // console.log('notificationData.title',notificationData?.title);
  //     // console.log('notificationData?.notification?.title',notificationData?.notification?.title);

  //     if (notificationData.data.click_action != "table_updated") {
  //       console.log('!table_updated',);
  //       // if (appConfig.buildCreateFor == "web") {
  //         this.toastr.success(notificationData?.notification?.title);
  //       // } else {
  //       //   this.toastr.success(notificationData.title, '');
  //       // }
  //       const sqlService = this.injector.get(WebSqlService);
  //       if (this.internetStatus == "Offline") {
  //         console.log('call captain');
  //         sqlService.insertUpdateOrderForSocket(parseMessage, 0, 0, 0, 1); // for socket
  //       }
  //       if (this.internetStatus == "Online") {
  //         return await this._posService.getOrderDetail({ "order_id": notificationData.data.order_id }).subscribe({
  //           next: async (res) => {
  //             if (res.status == 1) {
  //               await this.enqueue(async () => {
  //                 /* await */ sqlService.insertUpdateOrder(res.data, 0, 0, 0, 1);
  //               });
  //             } else {
  //               console.log("error:", res.status);
  //             }
  //           },
  //           error: (error) => {
  //             console.log(error);
  //           }
  //         });
  //       }

  //     } else if (notificationData.data.click_action == "table_updated") {
  //       var table = JSON.parse(notificationData.data.data);
  //       var merge_tables = table.merge_tables ?? [];
  //       var unmerge_tables = table.unmerge_tables ?? [];
  //       // Handle main table update
  //       if (table) {
  //         console.log('table.id ', table.id);
  //         var tables = await this.storeTablesServiceDb.use('GA_DATA', { filter: { id: table.id } });

  //         if (tables && tables.length > 0) {
  //           table.pid = tables[0].pid;
  //           table.isSync = 1;

  //           console.log('Updating main table:', table);
  //           this.storeTablesServiceDb.use('IU_DATA', [table]);
  //         } else {
  //           console.log('Main table not found. Inserting new table:', table);
  //           this.storeTablesServiceDb.use('IU_DATA', [table]);
  //         }
  //       }

  //       // Handle merge tables
  //       if (merge_tables.length > 0) {
  //         merge_tables = merge_tables.split(',');

  //         for (var i = 0; i < merge_tables.length; i++) {
  //           let merge_table_id = Number(merge_tables[i]);
  //           var mergeTable = await this.storeTablesServiceDb.use('GA_DATA', { filter: { id: merge_table_id } });

  //           if (mergeTable && mergeTable.length > 0) {
  //             let mergedTable = mergeTable[0];
  //             mergedTable.pid = table.pid; // Assuming you want to use the main table's pid
  //             mergedTable.isSync = 1;
  //             mergedTable.isMerge = table.id; // Assuming you want to reference the main table's id
  //             console.log('Updating merged table:', mergedTable);
  //             this.storeTablesServiceDb.use('IU_DATA', [mergedTable]);
  //           }
  //         }
  //       }
  //       // Handle unmerge tables
  //       if (unmerge_tables.length > 0) {
  //         unmerge_tables = unmerge_tables.split(',');
  //         for (var i = 0; i < unmerge_tables.length; i++) {
  //           let unmerge_table_id = Number(unmerge_tables[i]);
  //           var unmergeTable = await this.storeTablesServiceDb.use('GA_DATA', { filter: { id: unmerge_table_id } });
  //           if (unmergeTable && unmergeTable.length > 0) {
  //             let unmergedTable = unmergeTable[0];
  //             unmergedTable.pid = table.pid; // Assuming you want to use the main table's pid
  //             unmergedTable.isSync = 1;
  //             unmergedTable.isMerge = 0; // Assuming you want to reference the main table's id
  //             unmergedTable.merge_tables = 0
  //             console.log('Updating unmergedTable table:', unmergedTable);
  //             this.storeTablesServiceDb.use('IU_DATA', [unmergedTable]);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return 0;
  // }

  // async notificationSyncBgOrder() {
  //   if (appConfig.buildCreateFor != "web") {
  //     this.platform.resume.subscribe(() => {
  //       // App was resumed from the background
  //       console.log('App was resumed from the background.');
  //       // Call your function or perform necessary actions here
  //       var checkisStartofDay = localStorage.getItem('isStartofDay');
  //       if (checkisStartofDay == 'true') {
  //         const sqlService = this.injector.get(WebSqlService);
  //         sqlService.syncBGOrder()
  //       }
  //     });
  //     var checkisStartofDay = localStorage.getItem('isStartofDay');
  //     if (checkisStartofDay == 'true') {
  //       this.platform.ready().then(() => {
  //         // Check if the app was just launched



  //         if (this.platform.ready()) {
  //           // App was closed and reopened
  //           console.log('App was closed and reopened.');
  //           let socketIp = localStorage.getItem('socketIp')
  //           if(socketIp) {
  //             const value = JavaSocket.setIpAddress({ server_ip: socketIp, server_port: "4000"})
  //             console.log('Response from native:', value);
  //             if(value) {
  //              console.log('innn--- JavaSocket');
  //              JavaSocket.startClientSocket()
  //             }
              
         
  //            //  Socket Io
         
  //            const socketValue = SocketIO.setIpAddress({ server_ip: socketIp, server_port: "4000" })
  //            console.log('Response from native:', socketValue);
  //            if (socketValue) {
  //              console.log('innn--- SocketIO');
  //              SocketIO.startClientServer()
  //            }
  //           }
  //           // Call your function or perform necessary actions here
  //           const sqlService = this.injector.get(WebSqlService);
  //           sqlService.syncBGOrder()
  //         }

  //         // Subscribe to the resume event for cases when the app is resumed from the background
  //         // this.platform.resume.subscribe(() => {
  //         //   // App was resumed from the background
  //         //   console.log('App was resumed from the background.');

  //         //   // Call your function or perform necessary actions here
  //         //   this.callBgOrder();
  //         // });
  //       });
  //     }
  //     // await this.pushnotificationApp();
  //   }
  // }

}
